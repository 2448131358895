import React from "react";
import styled from "styled-components";

export const Summary = styled.div`
  padding-top: 32px;
  padding-bottom: 56px;

  & > div {
    display: flex;

    &:not(:nth-of-type(1)) {
      padding-top: 24px;
    }
  }
`;

export const StyledText = styled.div`
  width: 100%;
  max-width: calc(50% - 1.375rem);
  & > label {
    color: rgba(35, 35, 35, 1);
    padding-left: 10px;
    padding-bottom: 12px;
    display: flex;
  }

  & > div {
    width: 100%;
    height: 56px;
    background: #f3f3f7;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgba(79, 79, 79, 1);
  }

  &:nth-of-type(1) {
    margin-right: 25px;
  }
`;
