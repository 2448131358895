import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { validEmail } from "utils/validation";

import Input from "components/Input";
import NextButton from "./NextButton";

import * as types from "reducers/find";

export default function Step1() {
  const { t } = useTranslation(["find", "join", "common"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    emailError: false,
    emailErrorMsg: "",
  });

  const onChange = (e) => {
    const { value, name } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
    setErrors({
      ...errors,
      [value + "Error"]: false,
      [value + "ErrorMsg"]: "",
    });
  };

  const clear = (target) => {
    setInputs({
      ...inputs,
      [target]: "",
    });

    setErrors({
      ...errors,
      [target + "Error"]: false,
      [target + "ErrorMsg"]: "",
    });
  };

  const handleNext = () => {
    const { email } = inputs;

    if (!validEmail(email)) {
      setErrors({
        ...errors,
        emailError: true,
        emailErrorMsg: t("join:emailPlaceholder"),
      });
      return;
    }

    console.log("email", email);

    dispatch({
      type: types.FIND_USERNAME,
      data: {
        email: email,
      },
      callback: () => {
        history.push("/find?step=2");
      },
    });
  };

  return (
    <Wrapper>
      <h2>{t("find:finduserName")}</h2>
      <p>{t("find:findIdDesc")}</p>
      <DivideLine />
      <InputContainer>
        <InputDiv>
          <Input
            label={t("common:email")}
            placeholder={t("join:emailPlaceholder")}
            name="email"
            type="text"
            value={inputs.email}
            onChange={(e) => onChange(e)}
            clear={() => clear("email")}
            errorMsg={errors.emailErrorMsg}
            error={errors.emailError}
          />
        </InputDiv>
      </InputContainer>
      <Container>
        <NextButton disabled={false} callback={() => handleNext()}>
          {t("common:next")}
        </NextButton>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 80px;
  width: 100%;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  & > p {
    font-weight: 500;
    color: rgba(130, 130, 130, 1);
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 89px;
    text-align: center;
    line-height: 23px;
  }

  @media (max-width: 900px) {
    padding-bottom: initial;
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);
    /* padding-left: calc(28 / 375 * 100vw);
    padding-right: calc(28 / 375 * 100vw); */

    & > h2 {
      font-size: calc(24 / 375 * 100vw);
      width: 100%;
      line-height: calc(34 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }

    & > p {
      width: 100%;
      font-weight: 500;
      color: rgba(130, 130, 130, 1);
      font-size: calc(12 / 375 * 100vw);
      padding-top: calc(8 / 375 * 100vw);
      padding-bottom: calc(44 / 375 * 100vw);
      text-align: center;
      line-height: calc(17 / 375 * 100vw);
      text-align: left;
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }
  }
`;

const DivideLine = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const InputContainer = styled.div`
  width: 540px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 900px) {
    padding-top: calc(89 / 375 * 100vw);
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
  }
`;

const InputDiv = styled.div`
  /* padding-bottom: 31px; */

  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-top: initial !important;
      width: 250px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
