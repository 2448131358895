import React from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const modalComponent = (props, t) => {
  const { title, children, padding, width, close, closeButton } = props;

  return (
    <Wrapper width={width} padding={padding}>
      {(title || closeButton) && (
        <Header title={title}>
          <Title>{title && title}</Title>
          {closeButton && (
            <CloseButtonWrapper>
              <CloseButton onClick={close}>{t("close")}</CloseButton>
            </CloseButtonWrapper>
          )}
        </Header>
      )}

      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default function Modal(props) {
  const { t } = useTranslation(["common"]);
  const { cover } = props;
  return cover ? (
    <CoverWrapper>
      {modalComponent(props, t)}
      <Cover onClick={cover} />
    </CoverWrapper>
  ) : (
    modalComponent(props, t)
  );
}

const CoverWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(107px + 34px);
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);

  ${(props) => {
    if (props.padding) {
      return css`
        padding: ${props.padding};
      `;
    } else {
      return css`
        padding: 30px 0;
      `;
    }
  }}

  background-color:#fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12), 0px 12px 50px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}px;
      `;
    } else {
      return css`
        width: 900px;
      `;
    }
  }}
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => {
    if (props.title !== undefined) {
      return css`
        padding: 14px 48px 44px 48px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      `;
    } else {
      return css`
        padding: 0 48px;
      `;
    }
  }}
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 35px;
  letter-spacing: -0.02em;

  color: #4f4f4f;
`;
const CloseButtonWrapper = styled.div`
  text-align: right;
`;
const CloseButton = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0047ab;
  background: url("/images/icon/ico-close-cobalt-blue.svg") no-repeat right center;

  padding-right: 24px;

  cursor: pointer;
`;

const ContentWrapper = styled.div``;
