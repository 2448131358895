import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ContentHeader } from "components/ContentHeader";
import Description from "components/Description";
import { Summary, StyledText } from "components/StyledText";

import { useTranslation } from "react-i18next";
import { formatDateTime } from "utils/formatter";

import * as types from "reducers/admin";

export default function EvaluationDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pk } = useParams();
  const { t } = useTranslation(["user", "common"]);
  const { evaluationDetail } = useSelector((state) => {
    return {
      evaluationDetail: state.common.evaluationDetail,
    };
  });

  useEffect(() => {
    dispatch({
      type: types.EVALUATION_DETAIL,
      data: { evaluationPk: pk },
    });
  }, []);

  return (
    <>
      <ContentHeader>
        <HeaderContainer>
          <div
            onClick={() => {
              history.push("/admin/evaluations");
            }}
          >
            <img src="/images/icon/arrow-left.png" />
            <span>{t("detail")}</span>
          </div>
        </HeaderContainer>
      </ContentHeader>

      <ContentHeaderContainer>
        <div className="font-xs-title">{t("eduBasic")}</div>
      </ContentHeaderContainer>
      <ContentBodyContainer>
        <Summary>
          <div>
            <StyledText>
              <label className="px-16-b">{t("common:date")}</label>
              <div className="px-16-r">{formatDateTime(evaluationDetail.date)}</div>
            </StyledText>
            <StyledText>
              <label className="px-16-b">{t("common:device")}</label>
              <OverflowedCharField className="px-16-r">
                {evaluationDetail.device_name}
              </OverflowedCharField>
            </StyledText>
          </div>
          <div>
            <StyledText>
              <label className="px-16-b">{t("common:fullName")}</label>
              <div className="px-16-r">{evaluationDetail.full_name}</div>
            </StyledText>
          </div>
        </Summary>
      </ContentBodyContainer>

      <ContentHeaderContainer style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
        <div>
          <span className="font-xs-title" style={{ fontSize: "24px" }}>
            {t("detailResult")}
          </span>
        </div>
      </ContentHeaderContainer>
      <ContentBodyContainer>
        <Description />
      </ContentBodyContainer>
    </>
  );
}

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      & > span {
        color: rgba(79, 79, 79, 1);
        font-weight: bold;
        letter-spacing: -2%;
        font-size: 1.5rem;
      }

      & > img {
        width: 30px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
    &:nth-of-type(2) {
    }
  }
`;

const ContentHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.75rem;

  & > div {
    &:nth-of-type(1) {
      color: rgba(79, 79, 79, 1);
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
    }
  }
`;

const ContentBodyContainer = styled.div`
  padding: 0 2.75rem;
`;

const OverflowedCharField = styled.div`
  overflow: auto;
  white-space: nowrap;
`;
