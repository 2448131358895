import React from "react";
import styled from "styled-components";
import classNames from "classnames/bind";
import { css } from "styled-components";

export default function InputComponents(props) {
  const {
    width,
    label,
    name,
    size,
    labelPosition,
    type,
    id,
    placeholder,
    value,
    defaultValue,
    onChange,
    icon,
    errorMsg,
    clear,
    checkStyle,
    margin,
    marginSize,
    fontWeight,
    includeButton,
    ...state
  } = props;

  return (
    <Wrapper w={width} margin={margin} marginSize={marginSize}>
      {type === "checkbox" ? (
        <CheckboxWrapper
          type={type}
          labelPosition={labelPosition}
          checkStyle={checkStyle}
          onChange={onChange}
        >
          <input type="checkbox" value={value} id={id} />
          <label htmlFor={id}>{label}</label>
        </CheckboxWrapper>
      ) : (
        <>
          {label && <Label className={state.error && "error"}>{label}</Label>}
          <InputWrapper
            className={classNames({ error: state.error }, { disabled: state.disabled })}
            typing={value && value.length >= 1}
            error={state.error}
            size={size}
          >
            {icon && <Icon className={icon} error={state.error} />}
            <Input
              className="px-16-r"
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              disabled={state.disabled}
              readOnly={state.readOnly}
              isIcon={icon && icon.length >= 1 ? true : false}
              fontWeight={fontWeight}
            />
            {clear && value && value.length >= 1 && (
              <ClearButton type="button" onClick={clear && clear} tabIndex="-1" />
            )}
            {includeButton ? <IncludeButton>중복확인</IncludeButton> : null}
          </InputWrapper>
          {state.error && <Desc className="error">{errorMsg}</Desc>}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${(props) => {
    if (props.w) {
      return `width:${props.w};`;
    } else {
      return `width:100%;`;
    }
  }}

  ${(props) => {
    if (props.marginSize && props.margin) {
      return `margin-${props.margin}:${props.marginSize}px;`;
    } else if (props.margin) {
      return `margin-${props.margin}:12px;`;
    }
  }}
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 23px;
  color: #232323;
  margin-bottom: 8px;

  &.error {
    color: #e54658;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;

  background: #fff;
  border: 0.8px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  padding: 0 20px;
  box-sizing: border-box;

  @media screen and (max-width: 900px) {
    height: 52px;
  }

  &.disabled {
    background: rgba(186, 186, 186, 0.08);
    background-blend-mode: darken;
  }

  &.error {
    & > input::placeholder {
      color: #e54658;
    }
  }

  ${(props) => {
    if (props.error) {
      if (props.typing) {
        return `
          border: 0.8px solid rgba(229, 70, 88, 0.12);
          background-color:#fff;
        `;
      } else {
        return `
          border: 0.8px solid rgba(229, 70, 88, 0.12);
          color: #e54658;
          background-color:rgba(229, 70, 88, 0.08);
        `;
      }
    }

    if (props.size === "sm") {
      return `
        height:44px;

        @media screen and (max-width:900px) {
          height:40px;
        }
      `;
    }
  }}
`;

const Icon = styled.span`
  flex: 0 0 26px;
  height: 26px;

  margin-right: 2px;

  &.lock {
    background: url("/images/icon/ico-lock.svg") no-repeat center / contain;
  }
  &.human {
    ${(props) => {
      if (props.error) {
        return `background:url('/images/icon/ico-human-red.svg') no-repeat center / contain;`;
      } else {
        return `background:url('/images/icon/ico-human.svg') no-repeat center / contain;`;
      }
    }}
  }
  &.search {
    flex: 0 0 28px;
    height: 28px;
    background: url("/images/icon/ico-search.png") no-repeat center / 28px;
    margin-right: 12px;
  }
`;

const Input = styled.input`
  flex: 1;
  max-width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
  color: inherit;
  background-color: transparent;
  border: 0;
  border-radius: 5px;
  outline: none;

  &::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #c9c9c9;
    font-weight: 500;
  }

  ${(props) => {
    if (props.isIcon) {
      return css`
        /* padding-left: 16px; */
      `;
    }
  }}

  ${(props) => {
    if (props.fontWeight) {
      return css`
        font-weight: ${props.fontWeight};
      `;
    }
  }}



  &:focus {
    outline: none;
  }
`;

const Desc = styled.label`
  display: block;
  width: 100%;

  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: inherit;

  padding-left: 8px;
  margin-top: 8px;

  &.error {
    color: #e54658;
  }
`;

const ClearButton = styled.button`
  flex: 0 0 26px;
  height: 26px;
  border: 0;
  background: url("/images/icon/ico-delete.png") no-repeat center / contain;

  cursor: pointer;
`;
const CheckboxWrapper = styled.div`
  & > input {
    display: none;
  }

  & > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #232323;

    user-select: none;
    background-image: url("/images/icon/check01-off.png");
    background-size: 16px;
    background-repeat: no-repeat;

    @media screen and (max-width: 900px) {
      font-size: 12px;

      background-size: 14px;
    }

    ${(props) => {
      if (props.labelPosition === "right") {
        return `
            background-position:right center;
            padding-right:26px;
          `;
      } else {
        return `
            background-position:left center;
            padding-left:22px;
          `;
      }
    }}
  }

  ${(props) => {
    if (props.checkStyle === "red") {
      return `
            & > input:checked + label {
              background-image:url('/images/icon/check-red-on.png');
              background-size:20px;

              @media screen and (max-width:900px) {
                background-size:18px;
              }
            }
          `;
    } else {
      return `
            & > input:checked + label {
              background-image:url('/images/icon/checkbox-purple.png');
              background-size:18px;

              @media screen and (max-width:900px) {
                background-size:16px;
              }
            }
          `;
    }
  }}
`;

const IncludeButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 61px;
  height: 26px;
  background: #0047ab;
  border: 1px solid rgba(79, 79, 79, 0.5);
  border-radius: 50px;
  font-family: "Noto Sans KR";
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
`;
