import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

export default function Results() {
  const { item_json } = useSelector((state) => state.common.evaluationDetail);

  const { t } = useTranslation(["user"]);

  const handelSuccessFailAtLeastOne = (itemOne, itemTwo) => {
    if (itemOne === 1 || itemTwo === 1) {
      return <span className="font-xs-title">{t("success")}</span>;
    } else {
      return <span className="font-xs-title fail">{t("failure")}</span>;
    }
  };

  const handleSuccessFail = (item) => {
    if (item === 1) {
      return <span className="font-xs-title">{t("success")}</span>;
    } else {
      return <span className="font-xs-title fail">{t("failure")}</span>;
    }
  };

  return (
    <Wrapper>
      <Title className="font-xs-title">
        01<span className="font-xs-title">{t("consChk")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("tapShoulderAndCallLoud")}</span>
        {handelSuccessFailAtLeastOne(item_json.chk_cons1, item_json.chk_cons2)}
      </Task>

      <Divided />

      <Title className="font-xs-title">
        02<span className="font-xs-title">{t("askForHelp")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("119Call")}</span>
        {handleSuccessFail(item_json.chk_call)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("requestAed")}</span>
        {handleSuccessFail(item_json.chk_aed)}
      </Task>

      <Divided />

      <Title className="font-xs-title">
        03<span className="font-xs-title">{t("chkBreathing")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("chkBreathing2")}</span>
        {handleSuccessFail(item_json.chk_breath)}
      </Task>

      <Divided />

      <Title className="font-xs-title">
        04<span className="font-xs-title">{t("topOff")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("undressing")}</span>
        {handleSuccessFail(item_json.chk_undressed)}
      </Task>

      <Divided />

      <Title className="font-xs-title">
        05<span className="font-xs-title">{t("compAr2")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("compLoc")}</span>
        {handleSuccessFail(item_json.chk_location)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("chestCompDepth")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_detph}
            {t("times")}
          </span>
          <span className="font-xs-title"> /150{t("times")}</span>
        </RatioDiv>
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("compSpeed")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_bpm}
            {t("times")}
          </span>
          <span className="font-xs-title"> /150{t("times")}</span>
        </RatioDiv>
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("relaxation")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_relax}
            {t("times")}
          </span>
          <span className="font-xs-title"> /150{t("times")}</span>
        </RatioDiv>
      </Task>

      <Divided />

      <Title className="font-xs-title">
        06<span className="font-xs-title">{t("ar")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("secureAirway")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_airway}
            {t("times")}
          </span>
          <span className="font-xs-title"> /5{t("times")}</span>
        </RatioDiv>
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("startAr")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_breath_start}
            {t("times")}
          </span>
          <span className="font-xs-title"> /5{t("times")}</span>
        </RatioDiv>
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("cntVentilation")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_breath_cnt}
            {t("times")}
          </span>
          <span className="font-xs-title"> /10{t("times")}</span>
        </RatioDiv>
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("ventilationVolume")}</span>
        <RatioDiv>
          <span className="font-xs-title">
            {item_json.chk_breath_amt}
            {t("times")}
          </span>
          <span className="font-xs-title"> /10{t("times")}</span>
        </RatioDiv>
      </Task>

      <Divided />

      <Title className="font-xs-title">
        07<span className="font-xs-title">{t("aed")}</span>
      </Title>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("compAlternate")}</span>
        {handleSuccessFail(item_json.chk_request_comp1)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("substitution")}</span>
        {handleSuccessFail(item_json.chk_poweron)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("padAttachment1")}</span>
        {handleSuccessFail(item_json.chk_pad1)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("padAttachment2")}</span>
        {handleSuccessFail(item_json.chk_pad2)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("connector")}</span>
        {handleSuccessFail(item_json.chk_connector)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("noContact1")}</span>
        {handleSuccessFail(item_json.chk_stayaway1)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("reqComps")}</span>
        {handleSuccessFail(item_json.chk_request_comp2)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("noContact2")}</span>
        {handleSuccessFail(item_json.chk_stayaway2)}
      </Task>
      <Task style={{ marginBottom: "12px" }}>
        <span className="font-xs-title">{t("heartShock")}</span>
        {handleSuccessFail(item_json.chk_shock)}
      </Task>
      <Task>
        <span className="font-xs-title">{t("intervalComp")}</span>
        {handleSuccessFail(item_json.chk_cpr_interval)}
      </Task>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 3rem 2.75rem;
`;

const Title = styled.h2`
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
  color: #4f4f4f;
  & > span {
    padding-left: 20px;
  }
`;

const Divided = styled.div`
  border-top: 1px solid rgba(0, 71, 171, 0.5);
  margin-top: 32px;
  margin-bottom: 32px;
`;

const Task = styled.div`
  padding-left: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4f4f4f;

  & > img {
    margin-right: 12px;
  }

  & > span {
    &:nth-of-type(2) {
      color: #0047ab;

      &.fail {
        color: #e54658;
      }
    }
  }
`;

const RatioDiv = styled.div`
  & > span {
    &:nth-of-type(1) {
      color: #0047ab;
    }
    &:nth-of-type(2) {
      color: #828282;
    }
  }
`;
