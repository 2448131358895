import React from "react";
import styled, { css } from "styled-components";

import { ContentHeader, Header, Badge } from "components/ContentHeader";
import TableContainer from "components/Table";
import Modal from "components/Modal";
import Button from "components/Button";
import ViewDetail from "components/ButtonDetail";

import { useTranslation } from "react-i18next";

import { formatDateTime } from "utils/formatter";

export default function ReservationPresenter(props) {
  const { t } = useTranslation(["user", "common"]);

  const { row, column, searchData, cntUserData, modal, ...fn } = props;

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <h2 className="title">{t("myReserv")}</h2>
            <Badge>
              {(column && column.length) || 0} {t("common:num")}
            </Badge>
          </div>
        </Header>
      </ContentHeader>
      <TableContainer
        index
        row={row}
        column={column}
        paging
        handlePaging={fn.handlePaging}
        handleSorting={fn.handleSorting}
        reqRowData
        totalSize={1}
        currentPage={1}
        customFn={(item) => {
          return (
            <ViewDetailContainer>
              <ViewDetail onClick={() => fn.handleButton(item)}>
                {t("common:viewDetail")}
              </ViewDetail>
            </ViewDetailContainer>
          );
        }}
      />

      {modal.isModal.info && (
        <Modal width="480">
          <ModalTItle>{modal.modalData.device_name}</ModalTItle>
          <ModalClose onClick={fn.closeModal} />
          <ModalContent>
            <ModalTextWrapper>
              <ModalIconText icon="calender-active">{t("common:eduDate")}</ModalIconText>
              <ModalText>{formatDateTime(modal.modalData.start_datetime)}</ModalText>
            </ModalTextWrapper>
          </ModalContent>
          <ModalFooter>
            <ModalFooterText>{t("wannaCancel")}</ModalFooterText>
            <div>
              <Button
                width="118"
                height="30"
                color="BDBDBD"
                fontSize="12"
                margin="right"
                marginSize="8"
                onClick={() => fn.handleWithdrawn(modal.modalData)}
              >
                {t("yesCancel")}
              </Button>
              <Button width="61" height="30" color="0047AB" fontSize="12" onClick={fn.closeModal}>
                {t("noCancel")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}

      {modal.isModal.success && (
        <Modal width="480">
          <ModalTItle>{t("cancelSuccess")}</ModalTItle>
          <ModalClose onClick={fn.closeModal}></ModalClose>
          <ModalFooter>
            <Button
              width="50%"
              height="30"
              color="0047AB"
              fontSize="12"
              margin="right"
              marginSize="8"
              onClick={fn.closeModal}
            >
              {t("common:ok")}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ModalTItle = styled.h4`
  font-weight: bold;
  font-size: 1rem;
  line-height: 23px;
  color: #4f4f4f;

  padding: 0 32px;
  margin-bottom: 10px;
`;

const ModalClose = styled.span`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 26px;
  height: 26px;
  background: url("/images/icon/ico-circle-delete.png") no-repeat;

  cursor: pointer;
`;

const ModalContent = styled.div`
  padding: 26px 32px;
  margin-bottom: 26px;
`;

const ModalTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const ModalIconText = styled.span`
  font-weight: bold;
  font-size: 1rem;
  line-height: 23px;
  color: #0047ab;
  margin-right: 16px;
  background: url("/images/icon/ico-calendar-event.svg") no-repeat left center;
  padding-left: 34px;
`;

const ModalText = styled.span`
  flex: 1;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  ${(props) => {
    if (props.color) {
      return css`
        color: ${props.color};
      `;
    } else {
      return css`
        color: #4f4f4f;
      `;
    }
  }}

  ${(props) => {
    if (props.underline) {
      return css`
        text-decoration-line: underline;
      `;
    }
  }}

    margin-right:12px;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 32px 32px 0 32px;
  box-sizing: border-box;
`;

const ModalFooterText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #4f4f4f;
`;

const ViewDetailContainer = styled.div``;
