import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { ContentHeader, Header } from "components/ContentHeader";
import TableContainer from "components/Table";

import { useTranslation } from "react-i18next";
import * as types from "reducers/admin";

export default function Device() {
  const { t } = useTranslation(["admin", "common"]);
  const dispatch = useDispatch();

  const row = [
    {
      name: t("deviceName"),
      size: "3",
      sorting: true,
      prop: "name",
    },
    {
      name: t("deviceSerialNum"),
      size: "3",
      sorting: true,
      prop: "serial_number",
    },
  ];

  const column = useSelector((state) => state.admin.deviceList);

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  useEffect(() => {
    dispatch({
      type: types.DEVICE_LIST,
    });
  }, [dispatch]);

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <h2 className="title">{t("deviceManage")}</h2>
          </div>
        </Header>
      </ContentHeader>

      <TableContainer
        row={row}
        column={column}
        paging
        handlePaging={(page) => handlePaging(page)}
        handleSorting={(param) => handleSorting(param)}
        totalSize={1}
        currentPage={1}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;
