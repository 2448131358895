import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

import { DEBUG, FALLBACK_LANG } from "./constants";

export const changeLang = (lng) => {
  window.localStorage.setItem("lng", lng);
  i18n.changeLanguage(lng);
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: window.localStorage.getItem("lng") || FALLBACK_LANG,
    debug: DEBUG,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
