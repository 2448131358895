import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute";
import { Wrapper, RightSide } from "components/Wrapper";
import AsideContainer from "./AsideContainer";

import Active from "containers/AdminPage/Trainee/Active";
import Pending from "containers/AdminPage/Trainee/Pending";
import Reservation from "containers/AdminPage/Reservation";
import ReservationAddTrainee from "containers/AdminPage/Reservation/AddTrainee";
import ReservationDetail from "containers/AdminPage/Reservation/Detail";
import EvaluationDetail from "containers/AdminPage/Evaluation/Detail";
import Evaluation from "containers/AdminPage/Evaluation";
import NotFound from "containers/NotFound";
import Device from "containers/AdminPage/Device";
import MyPage from "containers/AdminPage/MyPage";
import Leader from "containers/AdminPage/Leader";

export default function AdminAside(props) {
  return (
    <Wrapper>
      <AsideContainer {...props} />
      <RightSide>
        <Switch>
          <ProtectedRoute path={"/admin/active"} component={Active} />
          <ProtectedRoute path={"/admin/pending"} component={Pending} />
          <ProtectedRoute exact path={"/admin/reservations"} component={Reservation} />
          <ProtectedRoute exact path={"/admin/reservations/:pk"} component={ReservationDetail} />
          <ProtectedRoute exact path={"/admin/evaluations"} component={Evaluation} />
          <ProtectedRoute exact path={"/admin/evaluations/:pk"} component={EvaluationDetail} />
          <ProtectedRoute
            exact
            path={"/admin/reservations/:pk/addTrainee"}
            component={ReservationAddTrainee}
          />
          <ProtectedRoute exact path={"/admin/devices"} component={Device} />
          <ProtectedRoute exact path={"/admin/leaderboard/:filter"} component={Leader} />
          <ProtectedRoute path={"/admin/mypage"} component={MyPage} />
          <ProtectedRoute path={"*"} component={NotFound} status={404} />
        </Switch>
      </RightSide>
    </Wrapper>
  );
}
