import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import Moment from "react-moment";
import moment from "moment";
import Create from "../Create";
import { useTranslation } from "react-i18next";
import { monthYearFormat } from "utils/formatter";
import * as types from "reducers/admin";

export default function Calendar() {
  const { i18n, t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const history = useHistory();

  const { sessionList, sessionCreateState } = useSelector((state) => ({
    sessionList: state.admin.sessionList,
    sessionCreateState: state.admin.sessionCreate,
  }));

  const [now, setNow] = useState(new Date().setDate(1));
  const [today] = useState(new Date());
  const [focusDay, setFocusDay] = useState(null);

  const setMonth = (month) => {
    dispatch({
      type: types.SET_RESERVATION_MONTH,
      data: month,
    });
  };

  const setYear = (year) => {
    dispatch({
      type: types.SET_RESERVATION_YEAR,
      data: year,
    });
  };

  const updateDateRange = (date) => {
    setNow(date);
    setFocusDay(null);
    closeModal();
    setMonth(date.getMonth() + 1);
    setYear(date.getFullYear());
    dispatch({ type: types.RESERVATION_LIST });
  };

  const prevMonth = () => {
    const prevDate = new Date(now);
    prevDate.setMonth(prevDate.getMonth() - 1);
    updateDateRange(prevDate);
  };

  const nextMonth = () => {
    const nextDate = new Date(now);
    nextDate.setMonth(nextDate.getMonth() + 1);
    updateDateRange(nextDate);
  };

  const openModal = (day) => {
    dispatch({
      type: types.SET_SESSION_CREATE,
      data: {
        show: true,
        chosenDate: day,
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: types.RESET_SESSION_CREATE,
    });
  };

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const getCalendarDays = () => {
    const firstDayIndex = new Date(now).getDay();
    const daysInCurrentMonth = daysInMonth(new Date(now).getFullYear(), new Date(now).getMonth());
    const totalDays = firstDayIndex + daysInCurrentMonth > 35 ? 42 : 35;
    return Array.from({ length: totalDays }, (_, index) => {
      const isAnotherMonth = index < firstDayIndex || index >= firstDayIndex + daysInCurrentMonth;
      const dayDate = new Date(now);
      dayDate.setDate(index - firstDayIndex + 1);
      return {
        date: dayDate,
        isAnotherMonth,
        isToday: moment(dayDate).isSame(today, "day"),
      };
    });
  };

  const calendarDays = getCalendarDays();

  useEffect(() => {
    if (!sessionCreateState.show) {
      setFocusDay(null);
    }
  }, [sessionCreateState.show]);

  return (
    <Wrapper>
      <MenuContainer>
        <div>
          <div onClick={prevMonth}>
            <img src="/images/icon/left-arrow.png" alt="Previous Month" />
          </div>
          <div>
            <Moment format={monthYearFormat(i18n.language)}>{now}</Moment>
          </div>
          <div onClick={nextMonth}>
            <img src="/images/icon/right-arrow.png" alt="Next Month" />
          </div>
        </div>
      </MenuContainer>
      <CalendarContainer>
        <MonthContainer>
          <MonthHeader>
            {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day) => (
              <div key={day}>{t(day)}</div>
            ))}
          </MonthHeader>
          <MonthBody>
            {calendarDays.map(({ date, isAnotherMonth, isToday }, index) => (
              <DayOfMonth
                key={index}
                anotherMonth={isAnotherMonth}
                isToday={isToday}
                focus={focusDay === index}
                onClick={() => !isAnotherMonth && openModal(moment(date).format("YYYY-MM-DD"))}
              >
                <div>{date.getDate()}</div>
                <DaySchedules
                  schedules={sessionList[moment(date).format("YYYY-MM-DD")]}
                  focusDay={focusDay}
                  index={index}
                  history={history}
                />
              </DayOfMonth>
            ))}
          </MonthBody>
        </MonthContainer>
      </CalendarContainer>
      {sessionCreateState.show && (
        <ModalContainer onClick={(e) => e.target.dataset.id === "not-parent-2" && closeModal()}>
          <Create />
        </ModalContainer>
      )}
    </Wrapper>
  );
}

const DaySchedules = ({ schedules, focusDay, index, history }) => (
  <>
    {Array.isArray(schedules) &&
      schedules.map((item, _index) => (
        <Schedule
          key={_index}
          isAvailable={item.is_available}
          focus={focusDay === index && _index === 0}
          onClick={(e) => {
            history.push(`/admin/reservations/${item.pk}`);
            e.stopPropagation();
          }}
        >
          {`${item.start_time} - ${item.end_time}`}
        </Schedule>
      ))}
  </>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const MenuContainer = styled.div`
  height: 144px;
  position: relative;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
      padding: 20px;
      cursor: pointer;
      &:nth-of-type(2) {
        padding-left: 24px;
        padding-right: 24px;
        & > time,
        span {
          font-weight: bold;
          color: #4f4f4f;
          font-size: 32px;
        }
      }
    }
  }
`;
const CalendarContainer = styled.div`
  width: 100%;
`;
const MonthContainer = styled.div`
  width: 100%;
`;
const MonthHeader = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > div {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--mono-3);
  }
`;
const MonthBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const DayOfMonth = styled.div`
  cursor: pointer;
  user-select: none;
  width: calc(100% / 7);
  min-height: 164px;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  padding: 12px;
  background-color: ${(props) => (props.anotherMonth ? "rgba(186, 186, 186, 0.08)" : "#FFF")};
  ${(props) =>
    props.focus &&
    css`
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    `}
  & > div {
    &:nth-of-type(1) {
      width: 28px;
      height: 28px;
      margin: 7px auto 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      font-weight: bold;
      color: ${(props) => (props.anotherMonth ? "#E0E0E0" : "#4f4f4f")};
      ${(props) =>
        props.isToday &&
        css`
          background-color: #0047ab;
          color: white;
        `}
    }
  }
`;

const Schedule = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.isAvailable ? "#6fcf97" : "#828282")};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  margin-top: 4px;
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid #6fcf97;
      background-color: rgba(111, 207, 151, 0.08);
      color: #6fcf97;
    `}
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 9999;
`;
