import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styled, { css } from "styled-components";
import { ContentHeader } from "components/ContentHeader";
import { StyledText, Summary } from "components/StyledText";
import Modal from "components/Modal";
import Confirm from "components/Confirm";
import Alert from "components/Alert";

import { useTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "utils/formatter";

import * as types from "reducers/admin";

export default function ReservationDetail() {
  const { pk } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["admin", "common", "user"]);
  const reservationDetail = useSelector((state) => state.admin.reservationDetail);

  const [confirm, setConfirm] = useState({
    show: false,
    removeTrainee: false,
    deleteReservation: false,
  });

  const [successMsg, setSuccessMsg] = useState({
    show: false,
    removeTrainee: false,
    deleteReservation: false,
    error: false,
  });

  const closeAll = () => {
    setConfirm({
      ...confirm,
      show: false,
      removeTrainee: false,
    });
    setSuccessMsg({
      ...successMsg,
      show: false,
      removeTrainee: false,
      error: false,
    });
  };

  const showConfirm = (type) => {
    closeAll();
    setConfirm({
      ...confirm,
      show: true,
      [type]: true,
    });
  };

  const showSuccessMsg = (type) => {
    closeAll();
    setSuccessMsg({
      ...successMsg,
      show: true,
      [type]: true,
    });
  };

  const handleTime = () => {
    if (reservationDetail === undefined) return;

    const { start_time, end_time } = reservationDetail;

    let startHour = Number(start_time.split(":")[0]);
    let endHour = Number(end_time.split(":")[0]);

    let startMeridiem = startHour >= 12 ? t("common:am") : t("common:pm");
    let endMeridiem = endHour >= 12 ? t("common:am") : t("common:pm");

    const hour = t("common:hour");
    const duration = endHour - startHour;

    return `${start_time + startMeridiem} ~ ${end_time + endMeridiem} (${duration} ${hour})`;
  };

  const addTraineeToReservation = () => {
    history.push("/admin/reservations/" + pk + "/addTrainee");
  };

  // remove Trainee modal
  const removeTraineeFromReservation = () => {
    showConfirm("removeTrainee");
  };

  const doRemoveTraineeFromReservation = () => {
    dispatch({
      type: types.RESERVATION_REMOVE_TRAINEE,
      data: { pk: pk },
      callback: (res) => {
        if (res.status === 200) {
          showSuccessMsg("removeTrainee");
        } else {
          showSuccessMsg("error");
        }
      },
    });
  };

  // delete Reservation modal
  const deleteReservation = () => {
    showConfirm("deleteReservation");
  };

  const doDeleteReservation = () => {
    dispatch({
      type: types.RESERVATION_DELETE,
      data: { pk: pk },
      callback: (res) => {
        if (res.status === 204) {
          showSuccessMsg("deleteReservation");
        } else {
          showSuccessMsg("error");
        }
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: types.RESERVATION_DETAIL,
      data: { pk: pk },
    });
  }, [dispatch, pk]);
  return (
    <Wrapper>
      <ContentHeader>
        <HeaderContainer>
          <div>
            <img
              alt=""
              onClick={() => {
                history.push("/admin/reservations");
              }}
              src="/images/icon/arrow-left.png"
            />
            <span>{t("reservationDetail")}</span>
          </div>
        </HeaderContainer>
      </ContentHeader>

      <ContentContainer style={{ marginTop: "8px" }}>
        <ContentHeaderContainer>
          <div className="font-xs-title">{t("reservationInfo")}</div>
          <div></div>
        </ContentHeaderContainer>
        <ContentBodyContainer>
          <Summary>
            <div>
              <StyledText>
                <label className="px-16-b">{t("common:date")}</label>
                <div className="px-16-r">{formatDate(reservationDetail.date)}</div>
              </StyledText>
              <StyledText>
                <label className="px-16-b">{t("user:time")}</label>
                <div className="px-16-r">{handleTime()}</div>
              </StyledText>
            </div>
            <div>
              <StyledText>
                <label className="px-16-b">{t("common:device")}</label>
                <div className="px-16-r" style={{ overflow: "auto" }}>
                  {reservationDetail.device_name}
                </div>
              </StyledText>
            </div>
          </Summary>
        </ContentBodyContainer>
      </ContentContainer>

      {reservationDetail.trainee ? (
        <ContentContainer>
          <ContentHeaderContainer>
            <div className="font-xs-title">{t("traineeInformation")}</div>
          </ContentHeaderContainer>
          <ContentBodyContainer>
            <Summary>
              <div>
                <StyledText>
                  <label className="px-16-b">{t("fullName")}</label>
                  <div className="px-16-r">{reservationDetail.trainee.full_name}</div>
                </StyledText>
                <StyledText>
                  <label className="px-16-b">{t("traineeRegDate")}</label>
                  <div className="px-16-r">
                    {formatDateTime(reservationDetail.trainee.date_joined)}
                  </div>
                </StyledText>
              </div>
              <div>
                <StyledText>
                  <label className="px-16-b">{t("common:email")}</label>
                  <div className="px-16-r">{reservationDetail.trainee.email}</div>
                </StyledText>
                <StyledText>
                  <label className="px-16-b">{t("phoneNumber")}</label>
                  <div className="px-16-r">{reservationDetail.trainee.phone_number}</div>
                </StyledText>
              </div>
            </Summary>
          </ContentBodyContainer>
          <ContentHeaderContainer>
            <div className="font-xs-title">{t("removeTraineeFromReservation")}</div>
          </ContentHeaderContainer>
          <ContentBodyContainer>
            <StyledP className="px-14-r">
              *{t("removeTraineeFromReservationMsg1")}
              <br />*{t("removeTraineeFromReservationMsg2")}
            </StyledP>
            <DeleteButton
              className="font-s-title"
              style={{ userSelect: "none" }}
              onClick={removeTraineeFromReservation}
            >
              {t("removeTraineeFromReservation")}
            </DeleteButton>
          </ContentBodyContainer>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <ContentHeaderContainer>
            <div className="font-xs-title">{t("addTraineeToReservation")}</div>
          </ContentHeaderContainer>
          <ContentBodyContainer>
            <StyledP className="px-14-r">
              *{t("addTraineeToReservationMsg1")}
              <br />*{t("addTraineeToReservationMsg2")}
            </StyledP>
            <AddButton
              className="font-s-title"
              style={{ userSelect: "none" }}
              onClick={addTraineeToReservation}
            >
              {t("addTraineeToReservation")}
            </AddButton>
          </ContentBodyContainer>
        </ContentContainer>
      )}

      <ContentContainer>
        <ContentHeaderContainer style={{ paddingBottom: "19px" }}>
          <div>
            <span className="font-xs-title">{t("deleteReservation")}</span>
          </div>
        </ContentHeaderContainer>
        <ContentBodyContainer>
          <StyledP className="px-14-r">
            *{t("deleteReservationMsg1")}
            <br />*{t("deleteReservationMsg2")}
          </StyledP>
          <DeleteButton
            className="font-s-title"
            disabled={reservationDetail.trainee}
            style={{ userSelect: "none" }}
            onClick={deleteReservation}
          >
            {t("deleteReservation")}
          </DeleteButton>
        </ContentBodyContainer>
      </ContentContainer>

      <Modal
        isOpen={confirm.show || successMsg.show}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        padding="0"
      >
        {/** remove Trainee modal */}
        {confirm.show && confirm.removeTrainee ? (
          <Confirm
            content={t("wannaRemoveTraineeFromReservation")}
            actions={[
              {
                label: t("common:noUpd"),
                callback: closeAll,
              },
              {
                label: t("common:yesUpd"),
                callback: doRemoveTraineeFromReservation,
              },
            ]}
          />
        ) : null}
        {successMsg.show && successMsg.removeTrainee ? (
          <Alert content={t("common:applyChange")} callback={closeAll} />
        ) : null}

        {/** delete Reservation modal */}
        {confirm.show && confirm.deleteReservation ? (
          <Confirm
            content={t("wannaDeleteReservation")}
            actions={[
              {
                label: t("common:noUpd"),
                callback: closeAll,
              },
              {
                label: t("common:yesUpd"),
                callback: doDeleteReservation,
              },
            ]}
          />
        ) : null}
        {successMsg.show && successMsg.deleteReservation ? (
          <Alert
            content={t("common:applyChange")}
            callback={() => {
              history.push("/admin/reservations");
            }}
          />
        ) : null}

        {successMsg.show && successMsg.error ? (
          <Alert content={t("error")} callback={closeAll} />
        ) : null}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      & > span {
        color: rgba(79, 79, 79, 1);
        font-weight: bold;
        letter-spacing: -2%;
        font-size: 1.5rem;
      }

      & > img {
        width: 2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }
    }
    &:nth-of-type(2) {
    }
  }
`;

const ContentContainer = styled.div`
  padding-top: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ContentHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.75rem;

  & > div {
    &:nth-of-type(1) {
      color: rgba(79, 79, 79, 1);
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
    }
  }
`;
const ContentBodyContainer = styled.div`
  padding: 0 2.75rem;
`;

const StyledP = styled.p`
  color: rgba(79, 79, 79, 1);
`;

const AddButton = styled.button`
  cursor: pointer;
  background-color: #0047ab;
  outline: none;
  border: none;
  width: calc(50% - 2.75rem);
  height: 62px;
  color: #ffffff;
  border: 1px solid #0047ab;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 60px;
`;

const DeleteButton = styled.button`
  cursor: pointer;
  background-color: rgba(229, 70, 88, 1);
  outline: none;
  border: none;
  width: calc(50% - 2.75rem);
  height: 62px;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #d50000;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 60px;
  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
        pointer-events: none;
      `;
    }
  }}
`;
