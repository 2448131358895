import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoginPresenter from "./LoginPresenter";

import * as commonTypes from "reducers/common";
import * as types from "reducers/login";

export default function LoginContainer() {
  const { t } = useTranslation(["login", "common"]);
  const { isAgency, isLoading } = useSelector((state) => {
    return {
      isAgency: state.common.isAgency,
      isLoading: state.login.isLoading,
    };
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    usernameError: false,
    usernameErrorMsg: "",
    pwdError: false,
    pwdErrorMsg: "",
  });

  const [isKeepAccount, setIsKeepAccount] = useState(false);

  const loginSuccess = (data) => {
    dispatch({
      type: commonTypes.SET_AUTH_TOKEN,
      data: { token: data.token, isKeepAccount: isKeepAccount },
    });
    dispatch({
      type: commonTypes.SET_IS_AGENCY,
      data: isAgency,
    });

    history.push("/");
  };

  const loginFailed = (data) => {
    setErrors((data) => {
      return {
        ...data,
        usernameError: true,
        usernameErrorMsg: t("login:loginErrorMsg"),
      };
    });
    dispatch({ type: types.DEFAULT_ASSIGN, data: { isLoading: false } });
  };

  const callback = (data, status) => {
    if (status === 200) {
      loginSuccess(data);
    } else {
      loginFailed(data);
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name + "Error"]: false,
      [name + "ErrorMsg"]: "",
    });
  };

  const clear = (target) => {
    setInputs({
      ...inputs,
      [target]: "",
    });

    setErrors({
      ...errors,
      [target + "Error"]: false,
      [target + "ErrorMsg"]: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validation();
    getUsers();
  };

  const validation = () => {
    if (inputs.username.length < 1) {
      setErrors((data) => {
        return {
          ...data,
          usernameError: true,
          usernameErrorMsg: t("login:emailErrorMsg"),
        };
      });
    } else {
      setErrors((data) => {
        return {
          ...data,
          usernameError: false,
          usernameErrorMsg: "",
        };
      });
    }

    if (inputs.password.length < 1) {
      setErrors((data) => {
        return {
          ...data,
          pwdError: true,
          pwdErrorMsg: t("login:pwdErrorMsg"),
        };
      });

      return;
    } else {
      setErrors((data) => {
        return {
          ...data,
          pwdError: false,
          pwdErrorMsg: "",
        };
      });
    }
  };

  const getUsers = () => {
    if (!isLoading) {
      dispatch({ type: types.DEFAULT_ASSIGN, data: { isLoading: true } });

      const type = isAgency ? types.AGENCY_LOGIN : types.TRAINEE_LOGIN;

      dispatch({
        type: type,
        data: inputs,
        callback: callback,
      });
    }
  };

  const handleIsAgency = () => {
    dispatch({
      type: commonTypes.DEFAULT_ASSIGN,
      data: {
        isAgency: !isAgency,
      },
    });
  };

  const goJoin = (e) => {
    e.preventDefault();
    history.push("/join");
  };

  const goFindId = (e) => {
    e.preventDefault();
    history.push("/find?step=1");
  };

  const goResetPassword = (e) => {
    e.preventDefault();
    history.push("/find?step=3");
  };

  let fn = {
    onSubmit,
    onChange,
    goJoin,
    clear,
    handleIsAgency,
    goFindId,
    goResetPassword,
  };

  return (
    <LoginPresenter
      inputs={inputs}
      errors={errors}
      fn={fn}
      isAgency={isAgency}
      isKeepAccount={isKeepAccount}
      setIsKeepAccount={setIsKeepAccount}
    />
  );
}
