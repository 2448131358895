import isEmail from "validator/lib/isEmail";

// Business registration number validation
// only 10 digits
export const validBusinessRegistrationNumber = (value) => {
  const regex = /^[0-9]{10}$/;
  return regex.test(value);
};

// Password validation
// at least 8 characters
// at least 1 number
// at least 1 lowercase letter
// only alphanumeric characters
export const validPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{8,}$/;
  return regex.test(password);
};

export const validEmail = (email) => {
  return isEmail(email);
};

export const validPhoneNumber = (value) => {
  const regex = /^[0-9]{10,11}$/;
  return regex.test(value);
};
