import React, { useState, useEffect } from "react";
import RejectPresenter from "./Presenter";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import * as types from "reducers/admin";

export default function RejectContainer() {
  const { t } = useTranslation(["common", "join"]);
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState({
    details: false,
    restore: false,
  });

  const [selectionList, setSelectionList] = useState([]);

  const row = [
    {
      name: t("fullName"),
      size: "3",
      sorting: true,
      prop: "full_name",
    },
    {
      name: t("email"),
      size: "3",
      sorting: true,
      prop: "email",
    },
    {
      name: t("phoneNumber"),
      size: "3",
      sorting: true,
      prop: "phone_number",
    },
    {
      name: t("dateTime"),
      size: "3",
      sorting: true,
      prop: "date_joined",
      type: "datetime",
    },
  ];

  const column = useSelector((state) => state.admin.traineeListInactive);

  const getList = () => {
    dispatch({
      type: types.TRAINEE_LIST_INACTIVE,
    });
  };

  const handleModal = (target, state) => {
    if (state) {
      setIsModal({ [target]: true });
    } else {
      setIsModal({ [target]: false });
    }
  };

  const handleSelectList = (row) => {
    let options = [];

    options.push(
      Object.keys(row).map((index) => {
        return row[index].pk;
      })
    );

    setSelectionList(options);
  };

  const setActive = () => {
    dispatch({
      type: types.TRAINEE_UPDATE,
      data: { pkList: selectionList[0], status: true },
      callback: () => {
        // FIXME
        // this due to the selection list not being cleared
        // when the modal is closed
        window.location.reload();
      },
    });
  };

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  const handleClose = () => {
    setIsModal({
      detail: false,
      restore: false,
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <RejectPresenter
      row={row}
      column={column}
      selectionList={selectionList}
      isModal={isModal}
      handleModal={handleModal}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
      handleClose={handleClose}
      handleSelectList={handleSelectList}
      setActive={setActive}
    />
  );
}
