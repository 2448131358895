import React from "react";
import styled from "styled-components";

export default function Checkbox({ children, checked, callback }) {
  return (
    <Wrapper>
      <div
        onClick={() => {
          if (callback) callback();
        }}
      >
        <img
          src={checked ? "/images/icon/checkbox-checked.svg" : "/images/icon/checkbox-default.svg"}
          alt="check"
        />
      </div>

      <label className="px-12-r">
        <input
          type="checkbox"
          onChange={() => {
            if (callback) callback();
          }}
        />
        {children}
      </label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  /* align-items: center; */

  & > div {
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  & > label {
    cursor: pointer;
    padding-left: 10px;
    & > input {
      font-style: normal;
      vertical-align: middle;
      display: none;
    }
  }
`;
