import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropdown from "components/Dropdown";
import { changeLang } from "i18n";

export default function Header() {
  const { i18n } = useTranslation();

  const { commonState } = useSelector((state) => {
    return {
      commonState: state.common,
    };
  });

  if (commonState.platform === "PC") {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/join" ||
      window.location.pathname === "/find"
    ) {
      return (
        <Wrapper>
          <Logo>
            <Link to="/" />
          </Logo>
          <Language>
            <Dropdown
              noBorder
              value={i18n.language === "kr" ? "KOR" : "ENG"}
              options={["KOR", "ENG"]}
              onChange={(e) => {
                changeLang(e === "KOR" ? "kr" : "en");
              }}
              size="sm"
            />
            <Icon />
          </Language>
        </Wrapper>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  box-shadow: 0px 12px 50px 0px #00000008;
  box-sizing: border-box;

  padding: 0 30px;
`;

const Logo = styled.span`
  width: 320px;
  height: 80px;
  background: url("/images/logo.png") no-repeat center;

  & > a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Language = styled.span`
  display: flex;
  height: 40px;
  align-items: center;
  text-decoration: none;

  cursor: pointer;
`;

const Icon = styled.span`
  display: inline-block;
  width: 17px;
  height: 18px;
  background: url("/images/icon/ico-language.png") no-repeat center / contain;
`;
