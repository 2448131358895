import { DATE_FORMAT, TIME_FORMAT, DATETIME_FORMAT } from "../constants";
import moment from "moment";

export const formatDateTime = (date, format = DATETIME_FORMAT) => {
  return moment(date).format(format);
};

export const formatDate = (date, format = DATE_FORMAT) => {
  return formatDateTime(date, format);
};

export const formatTime = (date, format = TIME_FORMAT) => {
  return formatDateTime(date, format);
};

export const monthYearFormat = (lng) => {
  if (lng === "kr") return "YYYY년 MM월";
  return "MMMM YYYY";
};

export const isAfterOrEqual = (date1, date2) => {
  return moment(date1).isSameOrAfter(date2);
};

export const truncateString = (str, num = 20) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num - 3) + "...";
};
