import React from "react";
import styled, { css } from "styled-components";

const ViewDetail = styled.span`
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  letter-spacing: -0.5px;

  ${(props) => {
    if (props.color) {
      return css`
        background: #${props.color};
      `;
    } else {
      return css`
        background: rgba(0, 0, 0, 0.12);

        &:hover {
          background-color: var(--a-purple-3);
          border: 1px solid rgba(0, 0, 0, 0.12);
        }
      `;
    }
  }}
  opacity: 0.8;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3px 7px;
  cursor: pointer;
`;

export default ViewDetail;
