const descriptionInitState = {
  val_depth: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  val_bpm: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ],
  val_breath_amt: [0, 0],
};

const evaluationDetailInitState = {
  /*
  mode:                 평가모드 : 1, 실습모드 : 2	
  difficulty:           상 : 1, 중 : 2, 하 : 3	
  record_time	          String(8)	O	교육수행시간(전체 과정 종료시간 - 시작시간)	교육수행시간(전체 과정 종료시간 - 시작시간)	date time	
  avr_compression	      Number(3)	O	가슴압박 평균 깊이(cm)	가슴압박 평균 깊이	소수점 1자리	
  avr_bpm	              Number(5)	O	가슴압박 평균 속도(bpm)	가슴압박 평균 속도(bpm)	소수점 1자리	
  avr_breathing       	Number(5)	O	인공호흡 평균 호흡량(ml)	인공호흡 평균 호흡량	소수점 1자리	

  1
  chk_cons1           	Number(1)	O	의식확인 S/F 여부 - 어깨 두드리기	의식확인 S/F 여부	                성공 : 1, 실패 : 0	
  chk_cons2           	Number(1)	O	의식확인 S/F 여부 - 큰소리로 환자 불러보기	의식확인 S/F 여부	        성공 : 1, 실패 : 0	

  2
  chk_call            	Number(1)	O	도움요청(119) S/F 여부	도움요청(119) S/F 여부	                성공 : 1, 실패 : 0	
  chk_aed             	Number(1)	O	도움요청(AED) S/F 여부	도움요청(AED) S/F 여부	                성공 : 1, 실패 : 0	
  !!! chk_breath          	Number(1)	O	호흡상태 확인 S/F 여부	호흡상태 확인 S/F 여부	                  성공 : 1, 실패 : 0	

  3
  chk_undressed       	Number(1)	O	상의탈의 S/F 여부	상의탈의 S/F 여부	                           성공 : 1, 실패 : 0	

  4
  chk_location          Number(1)	O	가슴압박위치확인 S/F 여부	가슴압박위치확인 S/F 여부	                성공 : 1, 실패 : 0	

  5
  chk_detph           	Number(3)	O	150회(5셋트x30회)의 가슴압박 적정깊이 S/F 여부(Array 집계, SUM)	150회(5셋트x30회)의 가슴압박 적정깊이 S/F 여부(Array 집계, SUM)	성공횟수의 SUM	
  chk_bpm             	Number(3)	O	150회(5셋트x30회)의 가슴압박 적정속도 S/F 여부(Array 집계, SUM)	150회(5셋트x30회)의 가슴압박 적정속도 S/F 여부(Array 집계, SUM)	성공횟수의 SUM	
  chk_relax           	Number(3)	O	150회(5셋트x30회)의 가슴압박 완전이완 S/F 여부(Array 집계, SUM)	150회(5셋트x30회)의 가슴압박 완전이완 S/F 여부(Array 집계, SUM)	성공횟수의 SUM	

  6
  chk_airway          	Number(1)	O	5회의 기도확보 S/F 여부(Array 집계, SUM)	5회의 기도확보 S/F 여부(Array 집계, SUM)	성공횟수의 SUM	
  chk_breath_start    	Number(1)	O	5회의 N초 이내 인공호흡 시작 S/F 여부(Array 집계, SUM)	5회의 N초 이내 인공호흡 시작 S/F 여부(Array 집계, SUM)	성공횟수의 SUM	
  chk_breath_cnt      	Number(2)	O	10회, 1~5세트 별로 N초 이내 인공호흡횟수(Array 집계, SUM)	10회, 1~5세트 별로 N초 이내 인공호흡횟수(Array 집계, SUM)	성공횟수의 SUM	
  chk_breath_amt      	Number(2)	O	10회, 1~5세트 별로 N초 이내 회당 적정인공호흡 횟수, 호흡량 기준(Array 집계, SUM)	10회, 1~5세트 별로 N초 이내 회당 적정인공호흡 횟수, 호흡량 기준(Array 집계, SUM)	성공횟수의 SUM	

  7
  chk_request_comp1     Number(1)	O	가슴압박 교대 요청 S/F 여부	가슴압박 교대 요청 S/F 여부	              성공 : 1, 실패 : 0	
  chk_poweron         	Number(1)	O	전원 켜기 S/F 여부	전원 켜기 S/F 여부	                         성공 : 1, 실패 : 0	
  chk_pad1            	Number(1)	O	패드 부착 1 S/F 여부	패드 부착 1 S/F 여부	                     성공 : 1, 실패 : 0	
  chk_pad2            	Number(1)	O	패드 부착 2 S/F 여부	패드 부착 2 S/F 여부	                     성공 : 1, 실패 : 0	
  chk_connector       	Number(1)	O	커넥터 연결 S/F 여부	커넥터 연결 S/F 여부	                      성공 : 1, 실패 : 0	
  chk_stayaway1       	Number(1)	O	접촉금지 지시(리듬 분석) S/F 여부	접촉금지 지시(리듬 분석) S/F 여부	    성공 : 1, 실패 : 0	
  chk_request_comp2     Number(1)	O	가슴압박 요청 S/F 여부	가슴압박 요청 S/F 여부	                   성공 : 1, 실패 : 0	
  chk_stayaway2       	Number(1)	O	접촉금지 지시(심장 충격) S/F 여부	접촉금지 지시(심장 충격) S/F 여부 	  성공 : 1, 실패 : 0	
  chk_shock           	Number(1)	O	심장 충격 S/F 여부	심장 충격 S/F 여부	                         성공 : 1, 실패 : 0	
  chk_cpr_interval    	Number(1)	O			                                                      성공 : 1, 실패 : 0	
  */

  //created_at
  //difficulty
  //item_json
  // mode
  // pk
  // place
  // score
  created_at: "",
  score: "", // out of 100
  mode: 0, //평가모드 : 1, 실습모드 : 2
  difficulty: 0, //상 : 1, 중 : 2, 하 : 3

  is_passed: false,

  device: "",

  item_json: {
    record_time: "",

    avr_compression: 0,
    avr_bpm: 0,
    avr_breathing: 0,

    // 1
    chk_cons1: 0,
    chk_cons2: 0,

    // 2
    chk_call: 0,
    chk_aed: 0,

    // 3
    chk_undressed: 0,

    // 4
    chk_location: 0,

    // 5
    chk_breath: 0,
    chk_detph: 0,
    chk_bpm: 0,
    chk_relax: 0,

    // 6
    chk_airway: 0,
    chk_breath_start: 0,
    chk_breath_cnt: 0,
    chk_breath_amt: 0,

    // 7
    chk_request_comp1: 0,
    chk_poweron: 0,
    chk_pad1: 0,
    chk_pad2: 0,
    chk_connector: 0,
    chk_stayaway1: 0,
    chk_request_comp2: 0,
    chk_stayaway2: 0,
    chk_shock: 0,
    chk_cpr_interval: 0,
  },
  descriptions: [
    descriptionInitState,
    descriptionInitState,
    descriptionInitState,
    descriptionInitState,
    descriptionInitState,
  ],
};

const initialState = {
  isAgency: false,
  evaluationDetail: evaluationDetailInitState,
  platform: "PC",
};

export const DEFAULT_ASSIGN = "common/DEFAULT_ASSIGN";
export const SET_AUTH_TOKEN = "common/SET_AUTH_TOKEN";
export const GET_AUTH_TOKEN = "common/GET_AUTH_TOKEN";
export const SET_IS_AGENCY = "common/SET_IS_AGENCY";
export const GET_IS_AGENCY = "common/GET_IS_AGENCY";

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_ASSIGN:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
