import styled from "styled-components";

export default function StyledError({ children }) {
  return <Error>{children}</Error>;
}

const Error = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`;
