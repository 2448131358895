import moment from "moment";

const today = new Date();
let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

// Reservation Status Code
export const RESERVATION_STATUS = {
  APPROVED: 0,
  WITHDRAWN: 1,
};

// Trainee Status Code
export const PENDING = 0;
export const ACTIVE = 1;
export const REJECTED = 2;

const reservationDetailInitialState = {
  pk: "",
  date: "",
  start_time: "",
  end_time: "",
  device_name: "",
  trainee: null,
};

export const myInfoInitialState = {
  agency_name: "",
  code: "",
  admin_username: "",
  full_name: "",
  email: "",
  phone_number: "",
  created_at: "",
};

const initSessionCreate = {
  show: false,
  sessionType: "forDate", // forRange or forDate

  chosenDate: today.toISOString().split("T")[0],
};

const initialState = {
  // new
  sessionList: {},
  reservationDetail: reservationDetailInitialState,
  deviceList: [],
  evaluationList: [],
  traineeListActive: [],
  traineeListInactive: [],

  sessionPage: {
    device: false,
    month: parseInt(moment().format("M")),
    year: parseInt(moment().format("YYYY")),
  },

  sessionCreate: initSessionCreate,

  leaderboard: [],

  info: myInfoInitialState,
};

export const DEFAULT_ASSIGN = "admin/DEFAULT_ASSIGN";

// Sagas
export const AGENCY_INFO = "admin/AGENCY_INFO";

export const TRAINEE_LIST_ACTIVE = "admin/TRAINEE_LIST_ACTIVE";
export const TRAINEE_LIST_INACTIVE = "admin/TRAINEE_LIST_INACTIVE";
export const TRAINEE_UPDATE = "admin/TRAINEE_UPDATE";

export const RESERVATION_LIST = "admin/RESERVATION_LIST";
export const RESERVATION_DETAIL = "admin/RESERVATION_DETAIL";
export const RESERVATION_ADD_TRAINEE = "admin/RESERVATION_ADD_TRAINEE";
export const RESERVATION_REMOVE_TRAINEE = "admin/RESERVATION_REMOVE_TRAINEE";
export const RESERVATION_DELETE = "admin/RESERVATION_DELETE";
export const RESERVATION_CREATE = "admin/RESERVATION_CREATE";

export const DEVICE_LIST = "admin/DEVICE_LIST";

export const EVALUATION_LIST = "admin/EVALUATION_LIST";
export const EVALUATION_DETAIL = "admin/EVALUATION_DETAIL";
export const EVALUATION_EXCEL = "admin/EVALUATION_EXCEL";

export const LEADERBOARD = "admin/LEADERBOARD";

// Non Sagas
export const SET_RESERVATION_MONTH = "admin/SET_RESERVATION_MONTH";
export const SET_RESERVATION_YEAR = "admin/SET_RESERVATION_YEAR";
export const SET_SESSION_CREATE = "admin/SET_SESSION_CREATE";
export const RESET_SESSION_CREATE = "admin/RESET_SESSION_CREATE";

export const SEARCH = "admin/SEARCH";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ASSIGN:
      return {
        ...state,
        ...action.data,
      };
    case SET_RESERVATION_MONTH:
      return {
        ...state,
        sessionPage: {
          ...state.sessionPage,
          month: action.data,
        },
      };
    case SET_RESERVATION_YEAR:
      return {
        ...state,
        sessionPage: {
          ...state.sessionPage,
          year: action.data,
        },
      };
    case SET_SESSION_CREATE:
      return {
        ...state,
        sessionCreate: {
          ...state.sessionCreate,
          ...action.data,
        },
      };
    case RESET_SESSION_CREATE:
      return {
        ...state,
        sessionCreate: initSessionCreate,
      };
    default:
      return state;
  }
}
