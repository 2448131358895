import React, { useState } from "react";
import styled, { css } from "styled-components";

// active 처리는 여기에서
// options 만 받자
// div ul 조합
export default function Dropdown({
  labelNoPadding,
  size,
  placeholder = "",
  label,
  options = [],
  onChange,
  value = false,
  zIndex,
  error,
}) {
  const [show, setShow] = useState(false);

  const hideDropdown = () => {
    setShow(false);
    document.removeEventListener("click", hideDropdown);
  };

  return (
    <Wrapper zIndex={zIndex} labelNoPadding={labelNoPadding}>
      {label && <label className="px-16-b">{label}</label>}
      <Select
        chosen={!!value}
        error={error}
        show={show}
        size={size}
        onClick={(e) => {
          if (!show) {
            document.addEventListener("click", hideDropdown);
          }
          setShow(!show);

          e.preventDefault();

          if (e.stopPropagation()) {
            e.stopPropagation();
          } else {
            e.cancelBubble = true;
          }
        }}
      >
        {!value ? (
          <Placeholder error={error}>{placeholder}</Placeholder>
        ) : (
          <span className="px-16-r">{value.name}</span>
        )}
        <img
          src={show ? "/images/icon/dropdown-up.svg" : "/images/icon/dropdown-down.svg"}
          alt="dropdown"
        />
      </Select>
      {show ? (
        <OptionContainer>
          <ul>
            {options.map((item) => (
              <Option
                size={size}
                key={item.key}
                active={value.key === item.key}
                onClick={() => {
                  if (onChange) onChange(item);
                }}
              >
                {item.name}
              </Option>
            ))}
          </ul>
        </OptionContainer>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: "Noto Sans KR";
  position: relative;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 9)};
  & > label {
    z-index: 1;
    display: block;
    padding-bottom: 12px;
    letter-spacing: -2%;
  }
`;

const Select = styled.div`
  background-color: white;
  width: 100%;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 17px 20px;
  padding-right: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
  background-color: rgba(243, 243, 247, 1);
  & > img {
    user-select: none;
  }

  & > span {
    color: rgba(0, 71, 171, 1);
  }

  ${({ chosen }) => {
    if (chosen) {
      return css`
        background-color: #ffffff;
      `;
    }
  }}

  ${(props) => {
    if (props.show) {
      return css`
        border: 2px solid #0047ab;
        padding: 17px 19px;
        padding-right: 25px;
      `;
    }
  }}
  ${(props) => {
    if (props.size === "sm") {
      return css`
        height: 44px;
      `;
    } else {
      return css`
        height: 56px;
      `;
    }
  }}
  ${(props) => {
    if (props.error) {
      return css`
        background: rgba(229, 70, 88, 0.08);
        background-blend-mode: darken;
        border: 0.8px solid rgba(0, 0, 0, 0.12);
      `;
    }
  }}
`;

const OptionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  margin-top: 4px;
  overflow: auto;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  color: rgba(130, 130, 130, 1);
  font-weight: bolder;

  ${(props) => {
    if (props.active) {
      return css`
        background-color: rgba(0, 71, 171, 0.12);
        color: rgba(0, 71, 171, 1);
      `;
    }
  }}
  ${(props) => {
    if (props.size === "sm") {
      return css`
        height: 44px;
      `;
    } else {
      return css`
        height: 60px;
      `;
    }
  }}
`;

const Placeholder = styled.span`
  font-weight: bolder;
  font-size: 15px;
  color: rgba(201, 201, 201, 1) !important;
  user-select: none;
  ${(props) => {
    if (props.error) {
      return css`
        color: #e54658 !important;
      `;
    }
  }}
`;
