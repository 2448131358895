import React from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { ContentHeader, Header, Badge } from "components/ContentHeader";
import TableContainer from "components/Table";
import Button from "components/Button";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";

export default function AddPresenter(props) {
  const { pk } = useParams();
  const { t } = useTranslation(["admin"]);
  const {
    row,
    column,
    isModal,
    setIsModal,
    traineePk,
    handleSelect,
    addTrainee,
    handlePaging,
    handleSorting,
  } = props;

  const history = useHistory();
  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <img
              alt=""
              onClick={() => {
                history.push(`/admin/reservations/${pk}`);
              }}
              src="/images/icon/arrow-left.png"
            />
            <h2 className="title">{t("addTraineeToReservation")}</h2>
            <Badge>
              {(column && column.length) || 0} {t("common:numPeople")}
            </Badge>
          </div>
          <div>
            <Button
              onClick={() => {
                setIsModal(true);
              }}
              color="27DF74"
              height="48"
              margin="right"
              marginSize="16"
              icon="create-plus"
              disabled={!traineePk}
            >
              {t("addTraineeBtn")}
            </Button>
          </div>
        </Header>
      </ContentHeader>

      <TableContainer
        selection
        selectionRow={handleSelect}
        row={row}
        column={column}
        paging
        handlePaging={handlePaging}
        handleSorting={handleSorting}
        totalSize={1}
        currentPage={1}
        isRadio={true}
      />

      {isModal && (
        <Modal width="491">
          <ModalBody>
            <ModalIcon />
            <ModalText>
              {t("addTraineeToReservationMsg1")}
              <br />
              {t("addTraineeToReservationMsg2")}
              <br />
            </ModalText>

            <ModalButtonWrapper>
              <ModalButton onClick={() => setIsModal(false)}>{t("common:confirmNo")}</ModalButton>
              <ModalButton className="point" onClick={() => addTrainee()}>
                {t("common:confirmYes")}
              </ModalButton>
            </ModalButtonWrapper>
          </ModalBody>
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ModalBody = styled.div`
  padding: 32px 48px 2px 48px;
`;

const ModalIcon = styled.i`
  display: block;
  height: 32px;
  margin-bottom: 20px;
  background: url("/images/icon/ico-warning.png") no-repeat center;
`;

const ModalText = styled.span`
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #333333;
  letter-spacing: -0.02em;

  margin-bottom: 44px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const ModalButton = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 23px;
  color: #bdbdbd;

  border-radius: 5px;

  padding: 8px 15px;

  cursor: pointer;

  &.point {
    color: #0047ab;
    background: rgba(0, 71, 171, 0.12);
  }
`;
