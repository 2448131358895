import React from "react";
import styled from "styled-components";
import { ContentHeader, Header, Lead } from "components/ContentHeader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function NotFound() {
  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <h2 className="title">404 Not Found / 찾을 수 없음</h2>
        </Header>
        <Lead>
          Sorry, the page you are looking for does not exist. / 죄송합니다. 찾고 있는 페이지가
          존재하지 않습니다.
        </Lead>
        <Link to="/">Go to Home / 홈으로 이동</Link>
      </ContentHeader>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
