import React, { useEffect } from "react";
import styled from "styled-components";
import Input from "components/Input";
import StyledError from "components/StyledError";
import Dropdown from "components/Dropdown";
import StepStatus from "./StepStatus";
import Button from "./Button";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { validPassword, validEmail, validPhoneNumber } from "utils/validation";

import * as types from "reducers/join";

export default function UserInfo({ inputs, setInputs, handleNextStep, step, wrapperRef }) {
  const { t } = useTranslation(["join", "login", "common"]);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [unknownError, setUnknownError] = React.useState(false);

  const { commonStore } = useSelector((state) => {
    return {
      commonStore: state.common,
    };
  });

  const [errors, setErrors] = React.useState({
    usernameError: false,
    usernameErrorMsg: "",
    passwordError: false,
    passwordErrorMsg: "",
    password2Error: false,
    password2ErrorMsg: "",
    emailError: false,
    emailErrorMsg: "",
    firstNameError: false,
    firstNameErrorMsg: "",
    lastNameError: false,
    lastNameErrorMsg: "",
    phoneNumberError: false,
    phoneNumberErrorMsg: "",
    genderError: false,
    genderErrorMsg: "",
    birthYearError: false,
    birthYearErrorMsg: "",
  });

  const change = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    setErrors({
      ...errors,
      [`${name}Error`]: false,
      [`${name}ErrorMsg`]: "",
    });
  };

  const clear = (e) => {
    const { name } = e.target;
    setInputs({
      ...inputs,
      [name]: "",
    });
    setErrors({
      ...errors,
      [`${name}Error`]: false,
      [`${name}ErrorMsg`]: "",
    });
  };

  const setErrorsAndMsg = (name, err, msg) => {
    setErrors((data) => {
      return {
        ...data,
        [`${name}Error`]: err,
        [`${name}ErrorMsg`]: msg,
      };
    });
  };

  const getYearList = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear - 90;

    const yearList = [];
    for (let year = startYear; year >= endYear; year--) {
      yearList.push(year);
    }

    return yearList;
  };

  const getGender = (value) => {
    return value === 0 ? t("join:genderOpt1") : t("join:genderOpt2");
  };

  const getGenderCode = (gender) => {
    return gender === t("join:genderOpt1") ? 0 : 1;
  };

  let genderOptions = [t("join:genderOpt1"), t("join:genderOpt2")];

  const validate = () => {
    let isValid = true;

    if (inputs.username === "") {
      setErrorsAndMsg("username", true, t("join:usernameError"));
      isValid = false;
    } else {
      setErrorsAndMsg("username", false, "");
    }

    if (!validPassword(inputs.password)) {
      setErrorsAndMsg("password", true, t("join:passwordError"));
      isValid = false;
    } else {
      setErrorsAndMsg("password", false, "");
    }

    if (inputs.password2 !== inputs.password || inputs.password2 === "") {
      setErrorsAndMsg("password2", true, t("join:passwordConfirmError"));
      isValid = false;
    } else {
      setErrorsAndMsg("password2", false, "");
    }

    if (!validEmail(inputs.email)) {
      setErrorsAndMsg("email", true, t("join:emailError"));
      isValid = false;
    } else {
      setErrorsAndMsg("email", false, "");
    }

    if (inputs.firstName === "") {
      setErrorsAndMsg("firstName", true, t("join:firstNameError"));
      isValid = false;
    } else {
      setErrorsAndMsg("firstName", false, "");
    }

    if (inputs.lastName === "") {
      setErrorsAndMsg("lastName", true, t("join:lastNameError"));
      isValid = false;
    } else {
      setErrorsAndMsg("lastName", false, "");
    }

    if (!validPhoneNumber(inputs.phoneNumber)) {
      setErrorsAndMsg("phoneNumber", true, t("join:phoneNumberPlaceholder"));
      isValid = false;
    } else {
      setErrorsAndMsg("phoneNumber", false, "");
    }

    if (inputs.birthYear === "") {
      setErrorsAndMsg("birthYear", true, t("join:birthYearError"));
      isValid = false;
    } else {
      setErrorsAndMsg("birthYear", false, "");
    }

    return isValid;
  };

  const callback = (errors) => {
    setIsLoading(false);
    if (Object.keys(errors).length === 0) {
      handleNextStep();
    } else if (errors.unknownError) {
      setUnknownError(true);
    } else {
      Object.keys(errors).forEach((key) => {
        setErrorsAndMsg(key, true, errors[key]);
      });
    }
  };

  const agencyValidateAndSubmit = () => {
    if (validate()) {
      setIsLoading(true);
      dispatch({
        type: types.CREATE_TRAINEE,
        data: inputs,
        callback: (errors) => callback(errors),
      });
    }
  };

  useEffect(() => {
    wrapperRef.current && wrapperRef.current.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <h2>{t("login:join")}</h2>
      <StepStatueContainer>
        <StepStatus step={step} labels={[t("join:enterCode"), t("join:enterBasicInfo")]} />
      </StepStatueContainer>
      <FormContainer>
        <h2>{t("join:basicInformation")}</h2>
        <InputContainer>
          <InputDiv>
            <Input
              placeholder={t("join:usernamePlaceholder")}
              name="username"
              label={t("common:username")}
              type="username"
              value={inputs.username}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "username" } })}
              errorMsg={errors.usernameErrorMsg}
              error={errors.usernameError}
            />
          </InputDiv>
          <InputDiv>
            <Input
              label={t("common:password")}
              name="password"
              type="password"
              placeholder={t("join:passwordPlaceholder")}
              value={inputs.password}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "password" } })}
              errorMsg={errors.passwordErrorMsg}
              error={errors.passwordError}
            />
          </InputDiv>
          <InputDiv>
            <Input
              label={t("join:passwordConfirm")}
              name="password2"
              type="password"
              placeholder={t("join:passwordConfirmPlaceholder")}
              value={inputs.password2}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "password2" } })}
              errorMsg={errors.password2ErrorMsg}
              error={errors.password2Error}
            />
          </InputDiv>
          <InputDiv>
            <Input
              label={t("common:email")}
              name="email"
              type="text"
              placeholder={t("join:emailPlaceholder")}
              value={inputs.email}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "email" } })}
              errorMsg={errors.emailErrorMsg}
              error={errors.emailError}
            />
          </InputDiv>
          <InputDiv className={commonStore.platform === "PC" ? "flex-end" : ""}>
            <Input
              label={t("common:lastName")}
              name="lastName"
              type="text"
              placeholder={t("join:lastNamePlaceholder")}
              value={inputs.lastName}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "lastName" } })}
              errorMsg={errors.lastNameErrorMsg}
              error={errors.lastNameError}
            />
            <Input
              label={t("common:firstName")}
              name="firstName"
              type="text"
              placeholder={t("join:firstNamePlaceholder")}
              value={inputs.firstName}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "firstName" } })}
              errorMsg={errors.firstNameErrorMsg}
              error={errors.firstNameError}
            />
          </InputDiv>
          <InputDiv>
            <Input
              label={t("common:phoneNumber")}
              name="phoneNumber"
              type="text"
              placeholder={t("join:phoneNumberPlaceholder")}
              value={inputs.phoneNumber}
              onChange={(e) => change(e)}
              clear={() => clear({ target: { name: "phoneNumber" } })}
              errorMsg={errors.phoneNumberErrorMsg}
              error={errors.phoneNumberError}
            />
          </InputDiv>
          <InputDiv className={commonStore.platform === "PC" ? "flex-end" : ""}>
            <Dropdown
              label={t("join:gender")}
              inputLabel={true}
              placeholder={t("join:genderPlaceholder")}
              value={getGender(inputs.gender)}
              options={genderOptions}
              onChange={(e) => {
                console.log("e", e);
                change({
                  target: {
                    name: "gender",
                    value: getGenderCode(e),
                  },
                });
              }}
              clear={() => clear({ target: { name: "gender" } })}
            />
            <Dropdown
              label={t("join:birthYear")}
              inputLabel={true}
              placeholder={t("join:birthYearPlaceholder")}
              value={inputs.birthYear}
              options={getYearList()}
              onChange={(e) => {
                change({
                  target: {
                    name: "birthYear",
                    value: e,
                  },
                });
              }}
              clear={() => clear({ target: { name: "birthYear" } })}
              errorMsg={errors.birthYearErrorMsg}
              error={errors.birthYearError}
            />
          </InputDiv>
        </InputContainer>

        <StyledError>{unknownError && t("join:unknownError")}</StyledError>
        <ErrorMsg>{isLoading && <img src="/images/icon/loading.svg" alt="Loading" />}</ErrorMsg>
      </FormContainer>

      <ButtonContainer>
        <Button disabled={isLoading} callback={agencyValidateAndSubmit}>
          {t("common:next")}
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 124px;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);

    & > h2 {
      display: none;
    }
  }
`;

const StepStatueContainer = styled.div`
  padding-top: 74px;
  padding-bottom: 100px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 55px;
  padding-left: 180px;
  box-sizing: border-box;

  & > h2 {
    color: rgba(79, 79, 79, 1);
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 70px;
  }

  @media (max-width: 900px) {
    border: none;
    padding: initial;

    & > h2 {
      color: rgba(79, 79, 79, 1);
      font-size: calc(24 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      font-weight: 700;
      padding-bottom: 20px;
      letter-spacing: -2%;
    }
  }
`;

const InputContainer = styled.div`
  width: 540px;

  @media (max-width: 900px) {
    padding-top: calc(89 / 375 * 100vw);
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
  }
`;
const InputDiv = styled.div`
  padding-bottom: 31px;

  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-top: initial !important;
      width: 250px;
    }
  }
`;

const ButtonContainer = styled.div`
  padding-top: 20px;
`;

const NormalContainer = styled.div`
  & > div {
    display: flex;
    align-items: ${(props) => (props.error ? "center" : "end")};
    padding-top: 40px;

    & > div {
      &:nth-of-type(1) {
        width: 420px;
        margin-right: 31px;
      }
      &:nth-of-type(2) {
        height: 56px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
    & > div {
      display: flex;
      align-items: center;
      padding-top: 40px;
      width: 100%;

      & > div {
        &:nth-of-type(1) {
          width: 100%;
          margin-right: initial;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`;
