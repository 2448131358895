import React from "react";
import styled from "styled-components";
import NextButton from "./NextButton";
import Text from "./Text";
import Modal from "react-modal";
import Alert from "components/Alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Step4() {
  const { t } = useTranslation(["find"]);
  const history = useHistory();

  const { success, email } = useSelector((state) => {
    return {
      success: state.find.success,
      email: state.find.email,
    };
  });

  const handleNext = () => {
    history.push("/login");
  };

  return (
    <Wrapper>
      <h2>{t("emailSent")}</h2>
      <p>
        {t("emailSentDescP1")}
        <br />
        {t("emailSentDescP2")}
      </p>
      <DivideLine />
      <InputContainer>
        <Text className="px-16-b">{success ? email : t("emailError")}</Text>
      </InputContainer>
      <Container>
        <NextButton disabled={false} callback={() => handleNext()}>
          {t("join:backToLogin")}
        </NextButton>
      </Container>
      <Modal isOpen={!success} className="Modal" overlayClassName="Overlay" ariaHideApp={false}>
        <Alert
          content={[
            <ModalIcon />,
            t("emailError"),
            <br key={0}></br>,
            t("tryAgain"),
            <br key={1}></br>,
            t("contactAdmin"),
          ]}
          callback={() => handleNext()}
        />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 80px;
  width: 100%;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  & > p {
    font-weight: 500;
    color: rgba(130, 130, 130, 1);
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 89px;
    text-align: center;
    line-height: 23px;
  }

  @media (max-width: 900px) {
    padding-bottom: initial;
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);
    /* padding-left: calc(28 / 375 * 100vw);
    padding-right: calc(28 / 375 * 100vw); */

    & > h2 {
      font-size: calc(24 / 375 * 100vw);
      width: 100%;
      line-height: calc(34 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }

    & > p {
      width: 100%;
      font-weight: 500;
      color: rgba(130, 130, 130, 1);
      font-size: calc(12 / 375 * 100vw);
      padding-top: calc(8 / 375 * 100vw);
      padding-bottom: calc(44 / 375 * 100vw);
      text-align: center;
      line-height: calc(17 / 375 * 100vw);
      text-align: left;
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }
  }
`;

const DivideLine = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const InputContainer = styled.div`
  width: 540px;
  padding-top: 82px;
  padding-bottom: 100px;

  @media (max-width: 900px) {
    padding-top: calc(89 / 375 * 100vw);
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalIcon = styled.i`
  display: block;
  height: 32px;
  margin-bottom: 20px;
  background: url("/images/icon/ico-warning.png") no-repeat center;
`;
