import React from "react";
import styled from "styled-components";
import { ContentHeader, Header, Badge } from "components/ContentHeader";
import TableContainer from "components/Table";
import Button from "components/Button";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";

export default function ActivePresenter(props) {
  const { t } = useTranslation(["admin"]);
  const { row, column, isModal, selectionList, ...fn } = props;

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <h2 className="title">{t("activeUsersTitle")}</h2>
            <Badge>
              {(column && column.length) || 0} {t("common:numPeople")}
            </Badge>
          </div>
          <div>
            <Button
              onClick={fn.setIniquity}
              color="E54658"
              width="120"
              height="48"
              margin="right"
              marginSize="16"
              icon="cancel"
              disabled={selectionList.length < 1}
            >
              {t("userReject")}
            </Button>
          </div>
        </Header>
      </ContentHeader>

      <TableContainer
        selection
        selectionRow={fn.handleSelectList}
        row={row}
        column={column}
        paging
        handlePaging={fn.handlePaging}
        handleSorting={fn.handleSorting}
        totalSize={1}
        currentPage={1}
      />

      {isModal.iniquity && (
        <Modal width="491">
          <ModalBody>
            <ModalIcon />
            <ModalText>
              {t("userRejectP1")}
              <br />
              {t("userRejectP2")}
              <br />
            </ModalText>

            <ModalButtonWrapper>
              <ModalButton onClick={() => fn.setPending()}>{t("common:confirmYes")}</ModalButton>
              <ModalButton className="point" onClick={() => fn.handleClose()}>
                {t("common:confirmNo")}
              </ModalButton>
            </ModalButtonWrapper>
          </ModalBody>
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ModalBody = styled.div`
  padding: 32px 48px 2px 48px;
`;

const ModalIcon = styled.i`
  display: block;
  height: 32px;
  margin-bottom: 20px;
  background: url("/images/icon/ico-warning.png") no-repeat center;
`;

const ModalText = styled.span`
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #333333;
  letter-spacing: -0.02em;

  margin-bottom: 44px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const ModalButton = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 23px;
  color: #bdbdbd;

  border-radius: 5px;

  padding: 8px 15px;

  cursor: pointer;

  &.point {
    color: #0047ab;
    background: rgba(0, 71, 171, 0.12);
  }
`;
