import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ContentHeader, Header } from "components/ContentHeader";
import Device from "components/ButtonDevice";
import Calendar from "../Calendar";

import { useTranslation } from "react-i18next";

import * as types from "reducers/trainee";

import { truncateString, formatDate } from "utils/formatter";

export default function AgencyReservationPage() {
  const { t } = useTranslation(["user", "common"]);
  const dispatch = useDispatch();

  const { sessionPage, sessionJoin, deviceList } = useSelector((state) => {
    return {
      sessionJoin: state.trainee.sessionJoin,
      sessionPage: state.trainee.sessionPage,
      deviceList: state.trainee.deviceList,
    };
  });
  const closeModal = () => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionJoin: {
          ...sessionJoin,
          type: "alert",
          show: false,
        },
      },
    });
  };

  const showErrorModal = () => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionJoin: {
          ...sessionJoin,
          type: "impossible",
          show: true,
        },
      },
    });
  };

  const sessionJoinCallback = (res) => {
    if (res.status === 200) {
      closeModal();
    } else {
      showErrorModal();
    }
    dispatch({
      type: types.MY_AGENCY_RESERVATION_LIST,
    });
  };

  const joinSession = () => {
    dispatch({
      type: types.MY_AGENCY_RESERVATION_JOIN,
      data: {
        reservationPk: sessionJoin.info.pk,
      },
      callback: (res) => {
        sessionJoinCallback(res);
      },
    });
  };

  const setDevice = (device) => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionPage: {
          ...sessionPage,
          device: device,
        },
      },
    });
    dispatch({
      type: types.MY_AGENCY_RESERVATION_LIST,
    });
  };

  useEffect(() => {
    dispatch({
      type: types.MY_AGENCY_RESERVATION_LIST,
    });
  }, [dispatch]);

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <h2>{t("bookReserv")}</h2>
        </Header>
      </ContentHeader>

      <DeviceContainer onClick={closeModal}>
        <h2>{t("common:chooseDevice")}</h2>
        <div>
          {deviceList.map((device) => {
            return (
              <Device
                key={device.pk}
                active={sessionPage.device.pk === device.pk}
                onClick={() => {
                  setDevice(device);
                }}
              >
                <div>{truncateString(device.name)}</div>
              </Device>
            );
          })}
        </div>
      </DeviceContainer>

      <SpeechBubbleTooltip
        direction={sessionJoin.point.x < 503 ? "left" : "right"}
        show={sessionJoin.show && sessionJoin.type === "reservation"}
        type="reservation"
        x={`${
          sessionJoin.point.x < 503
            ? sessionJoin.point.x + sessionJoin.point.w + 8
            : sessionJoin.point.x - 310 - 8
        }px`}
        y={`${sessionJoin.point.y - 148}px`}
      >
        <Close onClick={() => closeModal()} />

        <ReservationContainer>
          <ReservationBody>
            <h2>{sessionJoin.info.device_name}</h2>
            <div>
              <img src="/images/icon/ico-calendar-active.svg" alt="" />
              <span>{formatDate(sessionJoin.info.date)}</span>
            </div>
            <div>
              <img src="/images/icon/ico-clock-cobalt-blue.svg" alt="" />
              <span>{`${sessionJoin.info.start_time} - ${sessionJoin.info.end_time}`}</span>
            </div>
          </ReservationBody>
          <ReservationFooter>
            <p>{t("wannaJoin")}</p>
            <div>
              <Button cancel onClick={() => closeModal()}>
                {t("noJoin")}
              </Button>
              <Button normal onClick={() => joinSession()}>
                {t("yesJoin")}
              </Button>
            </div>
          </ReservationFooter>
        </ReservationContainer>
      </SpeechBubbleTooltip>

      <SpeechBubbleTooltip
        direction={sessionJoin.point.x < 503 ? "left" : "right"}
        show={sessionJoin.show && sessionJoin.type === "impossible"}
        // x={`${sessionJoin.point.x - 478 - 8}px`}
        x={`${
          sessionJoin.point.x < 503
            ? sessionJoin.point.x + sessionJoin.point.w + 8
            : sessionJoin.point.x - 478 - 8
        }px`}
        y={`${sessionJoin.point.y - 106}px`}
      >
        <Close onClick={() => closeModal()} />

        <AlertContainer>
          <AlertBody>
            <h2>{t("joinNotPossible")}</h2>
            <p>
              <span>{t("joinNotPossibleReason")} </span>
              {t("joinNotPossibleDesc")}
            </p>
          </AlertBody>
          <AlertFooter>
            <Button cancel onClick={() => closeModal()}>
              {t("common:ok")}
            </Button>
          </AlertFooter>
        </AlertContainer>
      </SpeechBubbleTooltip>

      <Calendar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SpeechBubbleTooltip = styled.div`
  display: ${(props) => (props.show ? "auto" : "none")};
  z-index: 999999;
  position: absolute;
  top: ${(props) => (props.y ? props.y : "")};
  left: ${(props) => (props.x ? props.x : "")};
  width: ${(props) => (props.type === "reservation" ? "312px" : "480px")};
  /* height: 200px; */
  min-height: 217px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 8px 12px 40px rgba(125, 125, 125, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #333;

  ${(props) => {
    switch (props.direction) {
      case "right":
        return css`
          &::after,
          &::before {
            border-top: 8px solid transparent;
            border-left: 14px solid #ffffff;
            border-bottom: 8px solid transparent;
            right: -14px;
            content: "";
            top: 50%;
            position: absolute;
          }

          &::before {
            top: calc(50% - 0px);
            border-top: 8px solid transparent;
            border-left: 14px solid rgba(0, 0, 0, 0.12);
            border-bottom: 8px solid transparent;
            right: -15px;
          }
        `;
      case "left":
        return css`
          &::after,
          &::before {
            border-top: 8px solid transparent;
            border-right: 14px solid #ffffff;
            border-bottom: 8px solid transparent;
            left: -14px;
            content: "";
            top: 50%;
            position: absolute;
          }

          &::before {
            top: calc(50% - 0px);
            border-top: 8px solid transparent;
            border-right: 14px solid rgba(0, 0, 0, 0.12);
            border-bottom: 8px solid transparent;
            left: -15px;
          }
        `;
      default:
        return css`
          &::after,
          &::before {
            border-top: 8px solid transparent;
            border-left: 14px solid #ffffff;
            border-bottom: 8px solid transparent;
            right: -14px;
            content: "";
            top: 50%;
            position: absolute;
          }

          &::before {
            top: calc(50% - 0px);
            border-top: 8px solid transparent;
            border-left: 14px solid rgba(0, 0, 0, 0.12);
            border-bottom: 8px solid transparent;
            right: -15px;
          }
        `;
    }
  }}
`;

const ReservationContainer = styled.div`
  position: relative;
`;

const ReservationBody = styled.div`
  padding-left: 28px;
  padding-top: 28px;
  padding-right: 28px;
  padding-bottom: 30px;
  box-sizing: border-box;
  & > h2 {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: -2%;
    padding-bottom: 36px;
    color: rrgba(79, 79, 79, 1);
  }

  & > div {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 15px;
    }

    & > span {
      font-size: 1rem;
      font-weight: bolder;
      color: rrgba(79, 79, 79, 1);
    }

    & .code {
      font-weight: bold;
      color: rgba(0, 71, 171, 1);
      margin-right: 16px;
    }

    &:not(:nth-of-type(1)) {
      margin-top: 17px;
    }
  }
`;

const DeviceContainer = styled.div`
  padding: 36px 45px 0 45px;
  width: 100%;

  & > h2 {
    padding-bottom: 24px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -2%;
    color: #4f4f4f;
  }
`;

const ReservationFooter = styled.div`
  height: 126px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding-left: 28px;

  & > p {
    color: rgba(79, 79, 79, 1);
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: -2%;
    padding-top: 24px;
    padding-bottom: 12px;
    line-height: 23px;
  }

  & > div {
    & > button {
      &:nth-of-type(1) {
        margin-right: 8px;
      }
    }
  }
`;

const AlertContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const AlertBody = styled.div`
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
  font-size: 1rem;

  & > h2 {
    padding-bottom: 20px;
    font-weight: bold;
    color: rgba(79, 79, 79, 1);
    letter-spacing: -2%;
  }

  & > p {
    letter-spacing: -2%;
    color: rgba(0, 71, 171, 1);
    font-weight: bolder;
    & > span {
      font-weight: bold;
    }
  }
`;

const AlertFooter = styled.div`
  min-height: 89px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 28px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;

  & > button {
    &:nth-of-type(1) {
      margin-right: 8px;
    }
  }
`;

const Close = styled.div`
  z-index: 9999999;
  position: absolute;
  width: 26px;
  height: 26px;
  background-image: url("/images/icon/ico-delete.png");
  background-size: 100%;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: ${(props) =>
    props.normal ? "rgba(0, 71, 171, .9)" : "rgba(189, 189, 189, .9)"};
  color: rgba(242, 242, 242, 1);
  font-size: 12px;
  font-weight: 700;
  height: 30px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 9px;
  line-height: 18px;
  cursor: pointer;
`;
