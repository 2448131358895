import { all } from "redux-saga/effects";

import join from "./join";
import login from "./login";
import admin from "./admin";
import find from "./find";
import common from "./common";
import trainee from "./trainee";

export default function* rootSaga() {
  yield all([login(), join(), admin(), find(), common(), trainee()]);
}
