import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

export default function Certificate({ pk, date, name, handleClose }) {
  const { t } = useTranslation(["common"]);
  const generateAndDownloadImage = () => {
    const componentNode = document.getElementById("certificateToPrint");

    html2canvas(componentNode)
      .then((canvas) => {
        const link = document.createElement("a");
        link.download = "CPR MASS Certificate.jpeg";
        link.href = canvas.toDataURL("image/jpeg");
        link.click();
      })
      .catch((err) => {
        console.log(err);
        alert(
          `Not able to generate certificate.\nPlease, try again later or use another browser.\nError:
      ` + err.message
        );
      });
  };
  return (
    <CertificationModal>
      <CloseButtonWrapper>
        <CloseButton onClick={() => handleClose()}>{t("common:close")}</CloseButton>
      </CloseButtonWrapper>
      <PaddingAroundCertification id="certificateToPrint">
        <CertificationWrapper>
          <CertificationHeader />
          <CertificationContainer>
            <CertiInfo>
              <div>
                {t("user:certNum")}: {pk}
              </div>
              <div>{t("validPeriod")}</div>
            </CertiInfo>
            <CertiTitle>
              <div className="font-l-title">{t("user:certTitle")}</div>
              <p className="px-14-r">{t("user:certSubTitle")}</p>
            </CertiTitle>
            <CertiContent className="font-xs-title">
              <p>
                {t("common:fullName")} : {name}
              </p>
              <p>{t("user:certText")}</p>
            </CertiContent>
            <CertiFooter>
              <p className="px-16-b">{date}</p>
              <div className="font-xs-title">
                {t("user:certIssuer")}
                <img src="/images/icon/seal.png" />
              </div>
            </CertiFooter>
          </CertificationContainer>
          <CertificationFooter />
        </CertificationWrapper>
      </PaddingAroundCertification>

      <CertiSubmitContainer>
        <div
          className="px-16-b"
          onClick={() => {
            generateAndDownloadImage();
          }}
        >
          {t("user:certPrint")}
        </div>
      </CertiSubmitContainer>
    </CertificationModal>
  );
}

const CertificationModal = styled.div`
  width: 640px;
  height: 980px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12), 0px 12px 50px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: relative;
  padding-top: 79px;
`;

const CloseButtonWrapper = styled.div`
  text-align: right;
  position: absolute;
  top: 35px;
  right: 35px;
`;
const CloseButton = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #0047ab;
  background: url("/images/icon/ico-close-cobalt-blue.svg") no-repeat right center;
  padding-right: 24px;
  cursor: pointer;
`;

const CertificationWrapper = styled.div`
  width: 575px;
  height: 773px;
  background-image: url("/images/icon/certi-border-1.png");
  background-size: 100% 100%;
  margin: 0 auto;
  padding-top: 6px;

  position: relative;
`;
const CertificationContainer = styled.div`
  padding-top: 6px;
  width: 561px;
  height: 760px;
  background-image: url("/images/icon/certi-border-2.png");
  background-size: 100% 100%;
  margin: 0 auto;
`;

const CertificationHeader = styled.div`
  position: absolute;
  background-image: url("/images/icon/certi-header.svg");
  background-size: 100% 100%;
  width: 273px;
  height: 25px;
  left: 50%;
  transform: translateX(-49%);
  top: -12px;
`;

const CertificationFooter = styled.div`
  position: absolute;
  background-image: url("/images/icon/certi-footer.svg");
  background-size: 100% 100%;
  width: 195px;
  height: 18px;
  left: 50%;
  transform: translateX(-49%);
  bottom: -8px;
`;

const CertiInfo = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 21px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.02em;
`;

const CertiTitle = styled.div`
  text-align: center;
  padding-top: 88px;
  padding-bottom: 110px;
  box-sizing: border-box;
  & > div {
    box-sizing: border-box;
    margin-bottom: 8px;
  }
`;

const CertiContent = styled.div`
  padding-left: 55px;
  padding-right: 55px;

  & > p {
    &:nth-of-type(1) {
      padding-bottom: 26px;
    }
  }
`;

const CertiFooter = styled.div`
  padding-top: 144px;
  text-align: center;
  & > p {
  }
  & > div {
    position: relative;
    padding-top: 15px;
    & > img {
      position: absolute;
      top: -5px;
    }
  }
`;

const CertiSubmitContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 420px;
    height: 56px;
    background: #0047ab;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const PaddingAroundCertification = styled.div`
  padding-top: 12px;
  padding-bottom: 13px;
`;
