import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddPresenter from "./Presenter";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import * as types from "reducers/admin";

export default function AddContainer() {
  const { pk } = useParams();
  const history = useHistory();
  const { t } = useTranslation(["common", "admin"]);
  const dispatch = useDispatch();

  const [traineePk, setTraineePk] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const row = [
    {
      name: t("fullName"),
      size: "3",
      sorting: true,
      prop: "full_name",
    },
    {
      name: t("email"),
      size: "3",
      sorting: true,
      prop: "email",
    },
    {
      name: t("phoneNumber"),
      size: "3",
      sorting: true,
      prop: "phone_number",
    },
    {
      name: t("dateTime"),
      size: "3",
      sorting: true,
      prop: "date_joined",
      type: "datetime",
    },
  ];

  const column = useSelector((state) => state.admin.traineeListActive);

  const getList = () => {
    dispatch({
      type: types.TRAINEE_LIST_ACTIVE,
    });
  };

  const addTrainee = () => {
    dispatch({
      type: types.RESERVATION_ADD_TRAINEE,
      data: { pk: pk, trainee: traineePk },
      callback: () => {
        history.push(`/admin/reservations/${pk}`);
      },
    });
  };

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  const handleSelect = (item) => {
    setTraineePk(Object.values(item)[0].pk);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <AddPresenter
      row={row}
      column={column}
      isModal={isModal}
      setIsModal={setIsModal}
      traineePk={traineePk}
      handleSelect={handleSelect}
      addTrainee={addTrainee}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
    />
  );
}
