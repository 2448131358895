import { BACKEND_URL } from "../constants";
import { getAuth, removeAuth } from "../utils/auth";

const log = console.log.bind(console);

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const PATCH = "PATCH";
export const DELETE = "DELETE";

export const client = async (method, path, data) => {
  let url = BACKEND_URL + path;

  if (method === GET && data) {
    url += "?" + new URLSearchParams(data);
  } else if (data) {
    data = JSON.stringify(data);
  }

  const headers = (token) => {
    if (!token) {
      return {
        "Content-Type": "application/json",
      };
    }
    return {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
  };

  const request = () => {
    if (method === GET) {
      return {
        method: method,
        headers: headers(getAuth("authToken")),
      };
    } else {
      return {
        method: method,
        headers: headers(getAuth("authToken")),
        body: data,
      };
    }
  };

  const logOut = (statusCode) => {
    if (statusCode === 401) {
      removeAuth();
      window.location.reload();
    } else if (statusCode === 403) {
      removeAuth();
      window.location.reload();
    }
  };

  const safeData = (res) => {
    if (res.status === 204) {
      return null;
    } else {
      return res.json();
    }
  };

  return await fetch(url, request())
    .then(async (res) => {
      logOut(res.status);
      return { status: res.status, data: await safeData(res) };
    })
    .catch((error) => {
      return { error };
    });
};

export const fileDownload = async (path) => {
  let url = BACKEND_URL + path;

  const request = () => {
    return {
      method: POST,
      headers: {
        Authorization: "Token " + getAuth("authToken"),
      },
    };
  };

  const logOut = (statusCode) => {
    if (statusCode === 401) {
      removeAuth();
      window.location.reload();
    } else if (statusCode === 403) {
      removeAuth();
      window.location.reload();
    }
  };

  return await fetch(url, request())
    .then((res) => {
      logOut(res.status);
      return res;
    })
    .catch((error) => {
      return { error };
    });
};

export const fileUpload = async (path, data) => {
  log("api.fileUpload: ", path, data);

  let url = BACKEND_URL + path;

  const request = () => {
    return {
      method: POST,
      body: data,
    };
  };

  const logOut = (statusCode) => {
    if (statusCode === 401) {
      removeAuth();
      window.location.reload();
    } else if (statusCode === 403) {
      removeAuth();
      window.location.reload();
    }
  };

  const safeData = (res) => {
    if (res.status === 204) {
      return null;
    } else {
      return res.json();
    }
  };

  return await fetch(url, request())
    .then(async (res) => {
      logOut(res.status);
      return { status: res.status, data: await safeData(res) };
    })
    .catch((error) => {
      return { error };
    });
};
