import React from "react";
import styled from "styled-components";

export default function Button({ children, disabled, callback, backgroundColor, color }) {
  return (
    <Wrapper
      disabled={disabled}
      onClick={() => {
        if (disabled) return false;
        if (callback) callback();
      }}
      backgroundColor={backgroundColor}
      color={color}
    >
      <div>
        <span>{children}</span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  border-radius: 50px;
  background-color: ${(props) =>
    props.disabled
      ? "rgba(224, 224, 224, 1)"
      : props.backgroundColor
      ? props.backgroundColor
      : "rgba(0, 71, 171, 1)"};
  height: 45px;
  padding-left: 36px;
  padding-right: 36px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  /* color: ${(props) => (props.isToday ? "#FFFFFF" : "#828282")}; */
  color: ${(props) => (props.color ? props.color : "white")};

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      font-weight: bold;
    }
  }

  &:not(:nth-of-type(1)) {
    margin-left: 12px;
  }
`;
