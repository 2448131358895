import React from "react";
import styled from "styled-components";
import Button from "./Button";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Success() {
  const { t } = useTranslation(["join", "common"]);
  const history = useHistory();
  return (
    <Wrapper>
      <p>
        {t("join:success1")}
        <br />
        {t("join:success2")}
      </p>
      <Button
        callback={() => {
          history.push("/login");
        }}
      >
        {t("join:backToLogin")}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 432px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  & > p {
    color: rgba(79, 79, 79, 1);
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -2%;
    font-weight: bold;
    padding-bottom: 60px;
  }

  @media (max-width: 900px) {
    padding-left: calc(36 / 375 * 100vw);
    align-items: baseline;
    justify-content: initial;
    padding-top: calc(64 / 375 * 100vw);
    & > p {
      text-align: left;
      font-size: calc(24 / 375 * 100vw);
      line-height: calc(34 / 375 * 100vw);
      padding-bottom: calc(52 / 375 * 100vw);
    }
  }
`;
