export const initialState = {
  isLoading: false,
};

export const TRAINEE_LOGIN = "TRAINEE_LOGIN";
export const AGENCY_LOGIN = "AGENCY_LOGIN";
export const DEFAULT_ASSIGN = "login/DEFAULT_ASSIGN";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_ASSIGN:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;
