import styled from "styled-components";
import Button from "./Button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Step2() {
  const { t } = useTranslation(["find", "common"]);
  const history = useHistory();

  const { success, username } = useSelector((state) => {
    return {
      success: state.find.success,
      username: state.find.username,
    };
  });

  const handleNext = (success) => {
    if (success) {
      history.push("/find?step=3");
    } else {
      history.push("/find?step=1");
    }
  };

  return (
    <Wrapper>
      <h2>{success ? t("checkId") : t("failFindId")}</h2>
      <p>{success ? t("checkIdDesc") : t("failFindIdDesc")}</p>
      <DivideLine />
      <InputContainer>
        <InputDiv>
          <Text className={"px-16-b"} error={!success}>
            {success ? username : t("notFoundId")}
          </Text>
        </InputDiv>
      </InputContainer>
      <Container>
        <Button
          backgroundColor={success ? "var(--a-purple-4)" : "#E0E0E0"}
          callback={() => handleNext(success)}
        >
          {success ? t("resetPswd") : t("common:backToPrevPage")}
        </Button>
        <Button
          callback={() => {
            history.push("/login");
          }}
        >
          {t("join:backToLogin")}
        </Button>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 80px;
  width: 100%;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  & > p {
    font-weight: 500;
    color: rgba(130, 130, 130, 1);
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 89px;
    text-align: center;
    line-height: 23px;
  }

  @media (max-width: 900px) {
    padding-bottom: initial;
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);

    & > h2 {
      font-size: calc(24 / 375 * 100vw);
      width: 100%;
      line-height: calc(34 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }

    & > p {
      width: 100%;
      font-weight: 500;
      color: rgba(130, 130, 130, 1);
      font-size: calc(12 / 375 * 100vw);
      padding-top: calc(8 / 375 * 100vw);
      padding-bottom: calc(44 / 375 * 100vw);
      text-align: center;
      line-height: calc(17 / 375 * 100vw);
      text-align: left;
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }
  }
`;

const DivideLine = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const InputContainer = styled.div`
  width: 540px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 900px) {
    padding-top: calc(89 / 375 * 100vw);
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
  }
`;

const InputDiv = styled.div`
  /* padding-bottom: 31px; */

  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-top: initial !important;
      width: 250px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  width: 100%;
  height: 56px;
  background: rgba(186, 186, 186, 0.08);
  background-blend-mode: darken;
  border: 0.8px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
    if (props.error) {
      return `
          border: 0.8px solid rgba(229, 70, 88, 0.12);
          color: #E54658;
          background-color: rgba(229, 70, 88, 0.08);
        `;
    }
  }}
`;
