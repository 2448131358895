// delete this
const initialState = {};
//

export const DEFAULT_ASSIGN = "join/DEFAULT_ASSIGN";
export const VALIDATE_TRAINEE_AGENCY_CODE = "join/VALIDATE_TRAINEE_AGENCY_CODE";
export const CREATE_TRAINEE = "join/CREATE_TRAINEE";

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_ASSIGN:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
