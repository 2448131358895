import styled from "styled-components";

export const ContentHeader = styled.div`
  background-color: white;
  width: 100%;
  height: 108px;
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  @media (max-width: 900px) {
    max-width: 796px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  & > div {
    display: flex;
    align-items: center;
  }

  & h2,
  & .title {
    color: #4f4f4f;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 15px;
  }
`;

export const Lead = styled.div`
  height: 100%;
  display: flex;
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const Badge = styled.div`
  padding: 3px 7px;
  background: #0047ab;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: "Noto Sans KR";
  line-height: 18px;
`;
