import React from "react";
import styled, { css } from "styled-components";
// import classNames from 'classnames/bind';

/**
 * 색상
 * 레드 : E54658
 * 퍼플 : 0047AB
 * 그린 : 27DF74
 */

export default function ButtonComponents(props) {
  const {
    fontSize,
    fontWeight,
    color,
    line,
    icon,
    onClick,
    children,
    width,
    height,
    margin,
    marginSize,
    disabled,
    hover,
    borderColor,
    opacity,
  } = props;

  const hoverEvent = (e) => {
    const { target } = e;
    if (hover) {
      if (e.type === "mouseover") {
        target.classList.add("hover");

        if (target.classList.contains("hover")) {
          // target.innerText(hover.text);
          target.innerText = hover.text;
        }
      } else if (e.type === "mouseleave") {
        target.classList.remove("hover");
        target.innerText = children;
      }
    } else {
      return;
    }
  };

  return (
    <Wrapper margin={margin} marginSize={marginSize} width={width} height={height}>
      <Button
        color={color}
        line={line}
        borderColor={borderColor}
        opacity={opacity}
        onClick={onClick}
        icon={icon}
        fontSize={fontSize}
        fontWeight={fontWeight}
        disabled={disabled}
        hover={hover}
        onMouseOver={(e) => hoverEvent(e)}
        onMouseLeave={(e) => hoverEvent(e)}
      >
        {children}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;

  ${(props) => {
    if (props.marginSize && props.margin) {
      return css`margin-${props.margin}:${props.marginSize}px;`;
    } else if (props.margin) {
      return css`margin-${props.margin}:12px;`;
    }
  }}

  ${(props) => {
    if (props.height) {
      return css`
        height: ${props.height}px;
      `;
    } else {
      return css`
        height: 55px;
      `;
    }
  }}

    ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}px;
      `;
    } else {
      return css`
        width: 100%;
      `;
    }
  }}
`;

const Button = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  white-space: pre;

  border-radius: 3px;

  padding: 0 10px;

  cursor: pointer;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }

  ${(props) => {
    if (props.fontSize) {
      return css`
        font-size: ${props.fontSize}px;
      `;
    } else {
      return css`
        font-size: 1rem;
      `;
    }
  }}

  ${(props) => {
    if (props.fontWeight) {
      return css`
        font-weight: ${props.fontWeight};
      `;
    } else {
      return css`
        font-weight: 700;
      `;
    }
  }}

    ${(props) => {
    if (props.color) {
      if (props.line) {
        return css`
          color: #${props.color ? props.color : "0047AB"};
          border: 1px solid ${props.borderColor ? props.borderColor : "rgba(0, 0, 0, 0.12)"};
          background-color: #fff;
        `;
      } else {
        return css`
          color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.12);
          background-color: #${props.color};
        `;
      }
    } else {
      if (props.line) {
        return css`
          color: #0047ab;
          border: 1.5px solid #0047ab;
          background-color: #fff;
        `;
      } else {
        return css`
          color: #fff;
          border: 0;
          background-color: #0047ab;
        `;
      }
    }
  }}

    ${(props) => {
    if (props.icon) {
      return css`
        padding-left: 40px;
        padding-right: 18px;
        background-image: url("/images/icon/ico-${props.icon}.svg");
        background-repeat: no-repeat;
        background-position: 10px center;
      `;
    }
  }}

    ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 0.2;
      `;
    }
  }}

  ${(props) => {
    if (props.hover) {
      return css`
        &:hover {
          border: 1px solid #${props.hover.backgroundColor};
          background-color: #${props.hover.backgroundColor};
          width: ${props.hover.width}px;
          height: ${props.hover.height}px;
          font-size: ${props.hover.fontSize}px;
          font-weight: ${props.hover.fontWeight};
        }
      `;
    }
  }}

  ${(props) => {
    if (props.opacity) {
      return css`
        opacity: ${props.opacity};
      `;
    }
  }}
`;
