import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Input from "components/Input";
import NextButton from "./NextButton";
import Modal from "react-modal";
import Confirm from "components/Confirm";
import Alert from "components/Alert";
import queryString from "query-string";
import { validPassword } from "utils/validation";
import { useTranslation } from "react-i18next";

import * as types from "reducers/find";

import { useHistory, useLocation } from "react-router-dom";

export default function Step5() {
  const { t } = useTranslation(["find", "common"]);
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();

  const searchParams = queryString.parse(location.search);

  const token = searchParams.t;

  const [inputs, setInputs] = useState({
    newPassword1: "",
    newPassword2: "",
  });

  const [errors, setErrors] = useState({
    newPassword1Error: false,
    newPassword1ErrorMsg: "",
    newPassword2Error: false,
    newPassword2ErrorMsg: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState({
    confirm: false,
    success: false,
    error: false,
  });

  const onChange = (e) => {
    const { value, name } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name + "Error"]: false,
      [name + "ErrorMsg"]: "",
    });
  };

  const clear = (target) => {
    setInputs({
      ...inputs,
      [target]: "",
    });
  };

  const validation = () => {
    const { newPassword1, newPassword2 } = inputs;

    if (!validPassword(newPassword1)) {
      setErrors({
        ...errors,
        newPassword1Error: true,
        newPassword1ErrorMsg: t("join:passwordError"),
      });
    } else if (newPassword2 === "") {
      setErrors({
        ...errors,
        newPassword2Error: true,
        newPassword2ErrorMsg: t("join:passwordError"),
      });
    } else if (newPassword1 !== newPassword2) {
      setErrors({
        ...errors,
        newPassword2Error: true,
        newPassword2ErrorMsg: t("join:passwordConfirmError"),
      });
    } else {
      return true;
    }
  };

  const changePassword = () => {
    const { newPassword2 } = inputs;

    dispatch({
      type: types.CHANGE_PASSWORD,
      data: {
        token: token,
        password: newPassword2,
      },
      callback: (success) => {
        if (success) {
          handleModal(true, "success");
        } else {
          handleModal(true, "error");
        }
      },
    });
  };

  const handleModal = (show, type) => {
    setShowModal(show);
    if (type === "confirm") {
      setMessage({
        ...message,
        success: false,
        confirm: true,
        error: false,
      });
    } else if (type === "success") {
      setMessage({
        ...message,
        success: true,
        confirm: false,
        error: false,
      });
    } else if (type === "error") {
      setMessage({
        ...message,
        success: false,
        confirm: false,
        error: true,
      });
    } else {
      setMessage({
        ...message,
        success: false,
        confirm: false,
        error: false,
      });
    }
  };

  return (
    <Wrapper>
      <h2>{t("find:resetPswd")}</h2>
      <p>{t("find:newPasswordDesc")}</p>
      <DivideLine />
      <InputContainer>
        <InputDiv>
          <Input
            type="password"
            name="newPassword1"
            label={t("find:newPswdLabel")}
            placeholder={t("join:passwordPlaceholder")}
            value={inputs.newPassword1}
            onChange={(e) => onChange(e)}
            clear={() => clear("newPassword1")}
            error={errors.newPassword1Error}
            errorMsg={errors.newPassword1ErrorMsg}
          />
        </InputDiv>
        <InputDiv>
          <Input
            type="password"
            name="newPassword2"
            label={t("find:confirmPswdLabel")}
            placeholder={t("join:passwordConfirmPlaceholder")}
            value={inputs.newPassword2}
            onChange={(e) => onChange(e)}
            clear={() => clear("newPassword2")}
            error={errors.newPassword2Error}
            errorMsg={errors.newPassword2ErrorMsg}
          />
        </InputDiv>
      </InputContainer>
      <Container>
        <NextButton
          disabled={false}
          callback={() => {
            let passwordIsValid = validation();
            if (passwordIsValid) {
              handleModal(true, "confirm");
            }
          }}
        >
          {t("find:saveNewPassword")}
        </NextButton>
      </Container>
      <Modal isOpen={showModal} className="Modal" overlayClassName="Overlay" ariaHideApp={false}>
        {message.error ? (
          <Alert
            content={[t("resetError"), <br key={0}></br>, t("resetTryAgain")]}
            callback={() => {
              handleModal(false);
              history.push("/login");
            }}
          />
        ) : null}

        {message.confirm ? (
          <Confirm
            content={[t("resetConfirm")]}
            actions={[
              {
                // Changed my mind, cancel
                label: t("common:confirmNo"),
                callback: () => {
                  history.push("/login");
                },
              },
              {
                // Yes, change password
                label: t("common:confirmYes"),
                callback: () => {
                  changePassword();
                },
              },
            ]}
          />
        ) : null}

        {message.success ? (
          <Alert
            content={[t("resetSuccessP1"), <br key={0}></br>, t("resetSuccessP2")]}
            callback={() => {
              // Password changed successfully
              handleModal(false);
              history.push("/login");
            }}
          />
        ) : null}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 80px;
  width: 100%;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  & > p {
    font-weight: 500;
    color: rgba(130, 130, 130, 1);
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 89px;
    text-align: center;
    line-height: 23px;
  }

  @media (max-width: 900px) {
    padding-bottom: initial;
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);
    /* padding-left: calc(28 / 375 * 100vw);
    padding-right: calc(28 / 375 * 100vw); */

    & > h2 {
      font-size: calc(24 / 375 * 100vw);
      width: 100%;
      line-height: calc(34 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }

    & > p {
      width: 100%;
      font-weight: 500;
      color: rgba(130, 130, 130, 1);
      font-size: calc(12 / 375 * 100vw);
      padding-top: calc(8 / 375 * 100vw);
      padding-bottom: calc(44 / 375 * 100vw);
      text-align: center;
      line-height: calc(17 / 375 * 100vw);
      text-align: left;
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }
  }
`;

const DivideLine = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const InputContainer = styled.div`
  width: 540px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 900px) {
    padding-top: calc(89 / 375 * 100vw);
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
  }
`;

const InputDiv = styled.div`
  /* padding-bottom: 31px; */

  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-top: initial !important;
      width: 250px;
    }
  }

  &:not(:nth-of-type(1)) {
    margin-top: 31px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
