import { call, put, takeLatest, select } from "@redux-saga/core/effects";
import { trainee } from "services";
import * as types from "reducers/trainee";
import * as commonTypes from "reducers/common";

function* reservationListFlow() {
  try {
    const res = yield call(trainee.reservationList);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          reservationList: res.data,
        },
      });
    }
  } catch (e) {}
}

function* deviceListFlow() {
  try {
    const sessionPage = yield select((state) => state.trainee.sessionPage);
    const res = yield call(trainee.deviceList);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          deviceList: res.data,
        },
      });
      if (!sessionPage.device) {
        yield put({
          type: types.DEFAULT_ASSIGN,
          data: {
            sessionPage: {
              ...sessionPage,
              device: res.data[0],
            },
          },
        });
      }
    }
  } catch (e) {}
}

function* agencyReservationListFlow(payload) {
  try {
    const sessionPage = yield select((state) => state.trainee.sessionPage);
    const { device, year, month } = payload.data || {};
    let data = {
      pk: device ? device : sessionPage.device.pk,
      month: parseInt(month ? month : sessionPage.month),
      year: parseInt(year ? year : sessionPage.year),
    };

    if (!sessionPage.device) {
      yield call(deviceListFlow);
      let device = yield select((state) => state.trainee.sessionPage.device);
      data.pk = device.pk;
    }

    const res = yield call(trainee.agencyReservationList, data);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          sessionList: res.data,
        },
      });
    }
  } catch (e) {}
}

function* reservationsJoinFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(trainee.reservationsJoinWithdraw, {
      pk: data.reservationPk,
    });

    if (res.status === 200) {
      yield call(agencyReservationListFlow);
    }
    if (callback) callback(res);
  } catch (e) {
    if (callback) callback({ status: 500, data: e });
  }
}

function* reservationsWithdrawFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(trainee.reservationsJoinWithdraw, {
      pk: data.reservationPk,
    });

    if (res.status === 200) {
      yield call(reservationListFlow);
    }
    if (callback) callback(res);
  } catch (e) {
    if (callback) callback({ status: 500, data: e });
  }
}

function* evaluationListFlow(payload) {
  try {
    const res = yield call(trainee.evaluationList);
    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          evaluationList: res.data,
        },
      });
    }
  } catch (e) {}
}

function* evaluationExcelFlow(payload) {
  try {
    const response = yield call(trainee.evaluationExcel);
    if (response.status === 200) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "evaluation-result.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  } catch (e) {
    console.error(e);
  }
}

function* evaluationDetailFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(trainee.evaluationDetail, {
      pk: data.evaluationPk,
    });
    if (res.status === 200) {
      yield put({
        type: commonTypes.DEFAULT_ASSIGN,
        data: {
          evaluationDetail: res.data,
        },
      });
    }
  } catch (e) {}
}

function* traineeInfoFlow() {
  try {
    const res = yield call(trainee.traineeInfo);
    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          info: res.data,
        },
      });
    }
  } catch (e) {}
}

function* traineeInfoDeleteFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(trainee.traineeInfoDelete);
    if (res.status === 204) {
      yield call(traineeInfoFlow);
    }
    if (callback) callback(res);
  } catch (e) {
    if (callback) callback({ status: 500, data: e });
  }
}

function* leaderboardFlow(payload) {
  const { data, callback } = payload;

  try {
    let res = yield call(trainee.leaderboard, data);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          leaderboard: res.data,
        },
      });

      if (callback) callback(res);
    }
  } catch (e) {
    if (callback) callback(e);
  }
}

export default function* watchTrainee() {
  yield takeLatest(types.MY_INFO, traineeInfoFlow);
  yield takeLatest(types.MY_INFO_DELETE, traineeInfoDeleteFlow);
  //
  yield takeLatest(types.MY_RESERVATION_LIST, reservationListFlow);
  yield takeLatest(types.MY_RESERVATION_WITHDRAWN, reservationsWithdrawFlow);
  //
  yield takeLatest(types.MY_DEVICE_LIST, deviceListFlow);
  //
  yield takeLatest(types.MY_AGENCY_RESERVATION_LIST, agencyReservationListFlow);
  yield takeLatest(types.MY_AGENCY_RESERVATION_JOIN, reservationsJoinFlow);
  //
  yield takeLatest(types.MY_EVALUATION_LIST, evaluationListFlow);
  yield takeLatest(types.MY_EVALUATION_DETAIL, evaluationDetailFlow);
  yield takeLatest(types.MY_EVALUATION_EXCEL, evaluationExcelFlow);
  //
  yield takeLatest(types.MY_LEADERBOARD, leaderboardFlow);
}
