import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import { ContentHeader, Header } from "components/ContentHeader";
import Button from "components/Button";
import TableContainer from "components/Table";
import ViewDetail from "components/ButtonDetail";

import Certificate from "components/Certificate";

import { formatDate } from "utils/formatter";

export default function EvaluationPresenter(props) {
  const { t } = useTranslation(["common"]);
  const { row, column, ...fn } = props;
  const history = useHistory();

  const [isModal, setIsModal] = useState({
    certification: false,
  });

  const [certData, setCertData] = useState({
    pk: "",
    date: "",
    name: "",
  });

  const handleDetail = (item) => {
    history.push("/my/evaluations/" + item.pk);
  };

  const handleButton = (e) => {
    const item = e;
    if (item.is_passed) {
      return (
        <ViewDetailContainer>
          <ViewDetail
            color="27DF74"
            style={{ marginRight: "12px" }}
            onClick={() => {
              setIsModal({
                certification: true,
              });
              setCertData({
                pk: item.pk,
                date: formatDate(item.created_at),
                name: item.full_name,
              });
            }}
          >
            {t("viewCert")}
          </ViewDetail>
          <ViewDetail
            onClick={() => {
              handleDetail(item);
            }}
          >
            {t("viewDetail")}
          </ViewDetail>
        </ViewDetailContainer>
      );
    } else {
      return (
        <ViewDetailContainer>
          <EmptySpace>{t("viewCert")}</EmptySpace>
          <ViewDetail
            onClick={() => {
              handleDetail(item);
            }}
          >
            {t("viewDetail")}
          </ViewDetail>
        </ViewDetailContainer>
      );
    }
  };

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <h2 className="title">{t("resultManage")}</h2>
          </div>
          <div>
            <Button
              onClick={fn.handleDownload}
              width="167"
              height="48"
              icon="download"
              disabled={fn.disabled}
            >
              {t("downloadData")}
            </Button>
          </div>
        </Header>
      </ContentHeader>

      <TableContainer
        index
        row={row}
        column={column}
        handlePaging={fn.handlePaging}
        handleSorting={fn.handleSorting}
        paging
        totalSize={1}
        currentPage={1}
        customFn={(item) => handleButton(item)}
      />

      {isModal.certification && (
        <Modal isOpen={true} className="Modal" overlayClassName="Overlay" ariaHideApp={false}>
          <Certificate
            pk={certData.pk}
            date={certData.date}
            name={certData.name}
            handleClose={() => setIsModal({ certification: false })}
          />
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const ViewDetailContainer = styled.div``;

const EmptySpace = styled.span`
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  letter-spacing: -0.5px;
  margin-right: 12px;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 3px 7px;
`;
