import React from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Terms from "./Step1Terms";
import AgencyCode from "./Step2AgencyCode";
import UserInfo from "./Step3UserInfo";

import Success from "./Success";

export default function JoinPage() {
  const { t } = useTranslation(["join", "login", "common"]);
  const history = useHistory();
  const wrapperRef = React.useRef(null);

  const traineeSteps = [Terms, AgencyCode, UserInfo, Success];
  const [step, setStep] = React.useState(0);

  const [traineeInputs, setTraineeInputs] = React.useState({
    termsAccepted: false,
    privacyAccepted: false,

    agencyCode: "",

    username: "",
    password: "",
    password2: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: 1,
    birthYear: "",
  });

  const handlePrevStep = () => {
    if (step === 0) {
      history.push("/login");
    } else {
      setStep(step - 1);
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const renderTraineeStep = () => {
    const Step = traineeSteps[step];
    console.log("renderTraineeStep", step);
    return (
      <Step
        inputs={traineeInputs}
        setInputs={setTraineeInputs}
        handleNextStep={handleNextStep}
        step={step}
        wrapperRef={wrapperRef}
      />
    );
  };

  const { commonStore } = useSelector((state) => {
    return {
      commonStore: state.common,
    };
  });

  const renderGoSignIn = () => {
    let steps = traineeSteps;
    let content =
      step !== steps.length - 1
        ? `${t("join:alreadySignedUp")} <a href="/login">${t("login:login")}</a>`
        : "&nbsp;";
    return <GoLogin2 dangerouslySetInnerHTML={{ __html: content }}></GoLogin2>;
  };

  return (
    <Wrapper ref={wrapperRef} id="scrollHere" isMobile={commonStore.platform === "MOBILE"}>
      <GoBack onClick={handlePrevStep}>
        <img src="/images/back-button.png" />
        <span>{t("common:backToPrevPage")}</span>
      </GoBack>
      <Layout>
        {renderGoSignIn()}
        {renderTraineeStep()}
      </Layout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #e5e5e5;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 60px;
  box-sizing: border-box;
  padding-bottom: 175px;

  ${(props) => {
    if (props.isMobile) {
      return css`
        height: 100%;
        background-color: initial;
        padding: initial;
      `;
    }
  }}
`;

const GoBack = styled.div`
  display: flex;
  width: 900px;
  margin: 0 auto;
  align-items: center;
  cursor: pointer;
  margin-bottom: 11px;

  & > span {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: bold;
    color: #c9c9c9;
    padding-left: 12px;
    line-height: 20px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const GoLogin2 = styled.div`
  text-align: right;
  padding-right: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;

  & > a {
    color: #4d4dff;
    text-decoration: none;
    font-weight: bold;
    padding-left: 9px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Layout = styled.div`
  background-color: white;
  width: 900px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  padding-top: 24px;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 0px;
    border-radius: initial;
  }
`;
