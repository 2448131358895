import React, { useState } from "react";
import styled, { css } from "styled-components";

import { useSelector } from "react-redux";

import Result from "components/Result";
import ResultDetail from "components/ResultDetail";
import { useTranslation } from "react-i18next";

export default function Description() {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation(["user"]);

  const { item_json } = useSelector((state) => state.common.evaluationDetail);
  const { evaluationDetail } = useSelector((state) => state.common);

  return (
    <Wrapper>
      <SummaryContainer>
        <h2 className="font-s-title">
          {t("totalScore")}
          <span className="font-s-title">
            {" "}
            {evaluationDetail.score}
            {t("scoringPoint")}
          </span>
        </h2>
        <CompletionButton fail={!evaluationDetail.is_passed} className="px-16-b">
          {evaluationDetail.is_passed ? t("completed") : t("notCompleted")}
        </CompletionButton>
      </SummaryContainer>
      <StatusBarContainer>
        <StatusBar ratio={evaluationDetail.score + "%"}>
          <div></div>
        </StatusBar>
      </StatusBarContainer>
      <ScoreContainer>
        <div>
          <span className="font-xs-title">{t("avrDeptComp")}</span>
          <p className="font-l-title">
            {item_json.avr_compression}
            <span className="px-16-b">cm</span>
          </p>
        </div>

        <div>
          <span className="font-xs-title">{t("avrCntComp")}</span>
          <p className="font-l-title">
            {item_json.avr_bpm}
            <span className="px-16-b">{t("timesPerMin")}</span>
          </p>
        </div>

        <div>
          <span className="font-xs-title">{t("avrAmtVentilation")}</span>
          <p className="font-l-title">
            {item_json.avr_breathing}
            <span className="px-16-b">ml</span>
          </p>
        </div>

        <div>
          <span className="font-xs-title">{t("eduRecord")}</span>
          <p className="font-l-title">{item_json.record_time}</p>
        </div>
      </ScoreContainer>
      <TabsContainer>
        <TabsHeader>
          <Tab
            className="font-xs-title"
            style={{ width: "101px" }}
            onClick={() => setTabIndex(0)}
            active={tabIndex === 0}
          >
            {t("all")}
          </Tab>
          <Tab
            className="font-xs-title"
            style={{ width: "219px" }}
            onClick={() => setTabIndex(1)}
            active={tabIndex === 1}
          >
            {t("compAr")}
          </Tab>
        </TabsHeader>
        <TabBody>
          {tabIndex === 0 ? <Result /> : null}
          {tabIndex === 1 ? <ResultDetail /> : null}
        </TabBody>
      </TabsContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #4f4f4f;
  width: 100%;
  padding: 3.75rem 0;
  & > h2 {
    & > span {
      padding-left: 28px;
    }
  }
`;

const StatusBarContainer = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 3rem;
`;
const StatusBar = styled.div`
  height: 16px;
  border-radius: 100px;
  position: relative;
  background-color: rgba(0, 71, 171, 0.2);
  overflow: hidden;
  & > div {
    border-radius: 100px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0047ab;
    width: ${(props) => (props.ratio ? props.ratio : "0%")};
  }
`;

const ScoreContainer = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    text-align: center;
    background-color: rgba(70, 147, 255, 0.08);
    width: calc(50% - 1.375rem);
    height: 180px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > p {
      padding-top: 1rem;

      & > span {
        display: inline-block;
        text-align: center;
      }
    }
    &:nth-of-type(n + 1) {
      margin-top: 1rem;
    }
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 1rem;
  padding-top: 3rem;
  padding-bottom: 6rem;
`;

const TabsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Tab = styled.div`
  cursor: pointer;
  height: 53px;
  border-radius: 50px;
  background-color: rgba(70, 147, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0047ab;

  &:not(:nth-of-type(1)) {
    margin-left: 12px;
  }

  ${(props) => {
    if (props.active) {
      return css`
        background-color: #0047ab;
        color: #ffffff;
      `;
    }
  }}
`;

const TabBody = styled.div``;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CompletionButton = styled.div`
  padding: 6px 16px;
  height: 35px;
  right: 246px;
  top: 741px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  ${(props) => {
    if (props.fail) {
      return css`
        background-color: #828282;
      `;
    } else {
      return css`
        background-color: #0047ab;
      `;
    }
  }}
`;
