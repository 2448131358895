import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import Device from "components/ButtonDevice";
import { ContentHeader } from "components/ContentHeader";
import Calendar from "./Calendar";
import { useTranslation } from "react-i18next";
import { truncateString } from "utils/formatter";

import * as types from "reducers/admin";

export default function Reservation() {
  const { t } = useTranslation(["admin", "common"]);
  const dispatch = useDispatch();

  const { sessionCreate, sessionPage, deviceList } = useSelector((state) => {
    return {
      sessionCreate: state.admin.sessionPage.sessionCreate,
      sessionPage: state.admin.sessionPage,
      deviceList: state.admin.deviceList,
    };
  });

  const createSession = (sessionType) => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionCreate: {
          ...sessionCreate,
          show: true,
          sessionType: sessionType,
        },
      },
    });
  };

  const setDevice = (device) => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionPage: {
          ...sessionPage,
          device: device,
        },
      },
    });
    dispatch({
      type: types.RESERVATION_LIST,
    });
  };

  useEffect(() => {
    dispatch({
      type: types.RESERVATION_LIST,
    });
  }, []);

  return (
    <>
      <ContentHeader>
        <HeaderContainer>
          <div>
            <span>{t("createSession")}</span>
          </div>
          <div>
            <CreateButton
              onClick={() => {
                createSession("forRange");
              }}
            >
              <img src="/images/icon/ico-create-plus.svg" />
              <span>{t("createRegularSession")}</span>
            </CreateButton>
          </div>
        </HeaderContainer>
      </ContentHeader>
      <DeviceContainer>
        <h2>{t("chooseLocation")}</h2>
        <div>
          {deviceList.map((device) => {
            return (
              <Device
                key={device.pk}
                active={sessionPage.device.pk === device.pk}
                onClick={() => {
                  setDevice(device);
                }}
              >
                <div>{truncateString(device.name)}</div>
              </Device>
            );
          })}
        </div>
      </DeviceContainer>
      <Calendar />
    </>
  );
}

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      & > span {
        color: rgba(79, 79, 79, 1);
        font-weight: bold;
        letter-spacing: -2%;
        font-size: 1.5rem;
      }

      & > img {
        width: 30px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
    &:nth-of-type(2) {
    }
  }
`;

const CreateButton = styled.div`
  user-select: none;
  cursor: pointer;
  width: 200px;
  height: 48px;
  background-color: rgba(0, 71, 171, 1);
  background: #0047ab;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 24px;
    margin-right: 12px;
  }
  & > span {
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    letter-spacing: -2%;
    font-weight: bold;
    margin-right: 8px;
  }
`;

const DeviceContainer = styled.div`
  padding: 36px 45px 0 45px;
  width: 100%;

  & > h2 {
    padding-bottom: 24px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -2%;
    color: #4f4f4f;
  }
`;
