import styled from "styled-components";

export default Text = styled.div`
  width: 100%;
  height: 56px;
  background: rgba(186, 186, 186, 0.08);
  background-blend-mode: darken;
  border: 0.8px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
