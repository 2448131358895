import React, { useEffect } from "react";
import styled from "styled-components";
import StepStatus from "./StepStatus";
import Input from "components/Input";
import Button from "./Button";
import StyledError from "components/StyledError";

import { useDispatch } from "react-redux";
import * as types from "reducers/join";

import { useTranslation } from "react-i18next";

export default function AgencyCode({ inputs, setInputs, handleNextStep, step, wrapperRef }) {
  const { t } = useTranslation(["join", "login", "common"]);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [unknownError, setUnknownError] = React.useState(false);

  const [errors, setErrors] = React.useState({
    agencyCodeError: false,
    agencyCodeErrorMsg: "",
  });

  const setErrorsAndMsg = (name, err, msg) => {
    console.log("setErrorsAndMsg", name, err, msg);
    setErrors((data) => {
      return {
        ...data,
        [`${name}Error`]: err,
        [`${name}ErrorMsg`]: msg,
      };
    });
  };

  const callback = (errors) => {
    setIsLoading(false);
    if (Object.keys(errors).length === 0) {
      handleNextStep();
    } else if (errors.unknownError) {
      setUnknownError(true);
    } else {
      Object.keys(errors).forEach((key) => {
        setErrorsAndMsg(key, true, t("join:codePlaceholder"));
      });
    }
  };

  const validate = () => {
    let isValid = true;

    if (inputs.agencyCode === "") {
      setErrorsAndMsg("agencyCode", true, t("join:codePlaceholder"));
      isValid = false;
    } else {
      setErrorsAndMsg("agencyCode", false, "");
    }

    return isValid;
  };

  const traineeValidateAndSubmit = () => {
    if (validate()) {
      setIsLoading(true);
      dispatch({
        type: types.VALIDATE_TRAINEE_AGENCY_CODE,
        data: inputs,
        callback: (errors) => callback(errors),
      });
    }
  };

  useEffect(() => {
    wrapperRef.current && wrapperRef.current.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <h2>{t("login:join")}</h2>
      <StepStatueContainer>
        <StepStatus step={step} labels={[t("join:enterCode"), t("join:enterBasicInfo")]} />
      </StepStatueContainer>

      <FormContainer>
        <h2>{t("join:enterCodeNow")}</h2>
        <p>
          {t("join:serviceDescP1")}
          <br />
          {t("join:serviceDescP2")}
        </p>

        <NormalContainer>
          <div>
            <Input
              placeholder={t("join:codePlaceholder")}
              type="text"
              name="agencyCode"
              value={inputs.agencyCode}
              onChange={(e) => setInputs({ ...inputs, agencyCode: e.target.value })}
              clear={() => setInputs({ ...inputs, agencyCode: "" })}
              error={errors.agencyCodeError}
              errorMsg={errors.agencyCodeErrorMsg}
            />

            <div>
              <Button
                disabled={isLoading}
                callback={traineeValidateAndSubmit}
                error={errors.agencyCodeError}
              >
                {t("common:next")}
              </Button>
            </div>
          </div>
          <StyledError>{unknownError && t("join:unknownError")}</StyledError>
          <ErrorMsg>{isLoading && <img src="/images/icon/loading.svg" alt="Loading" />}</ErrorMsg>
        </NormalContainer>
      </FormContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 124px;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);

    & > h2 {
      display: none;
    }
  }
`;

const StepStatueContainer = styled.div`
  padding-top: 74px;
  padding-bottom: 100px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-top: 55px;
  padding-left: 180px;
  box-sizing: border-box;

  & > h2 {
    color: rgba(79, 79, 79, 1);
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  & > p {
    color: rgba(79, 79, 79, 1);
    font-size: 1rem;
    line-height: 23px;
    font-weight: 500;
  }

  @media (max-width: 900px) {
    border: none;
    padding: initial;

    & > h2 {
      color: rgba(79, 79, 79, 1);
      font-size: calc(24 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      font-weight: 700;
      padding-bottom: 20px;
      letter-spacing: -2%;
    }

    & > p {
      color: rgba(79, 79, 79, 1);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
      font-size: calc(12 / 375 * 100vw);
      line-height: calc(16 / 375 * 100vw);
      font-weight: bolder;
    }
  }
`;

const NormalContainer = styled.div`
  & > div {
    display: flex;
    align-items: ${(props) => (props.error ? "center" : "end")};
    padding-top: 40px;

    & > div {
      &:nth-of-type(1) {
        width: 420px;
        margin-right: 31px;
      }
      &:nth-of-type(2) {
        height: 56px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
    & > div {
      display: flex;
      align-items: center;
      padding-top: 40px;
      width: 100%;

      & > div {
        &:nth-of-type(1) {
          width: 100%;
          margin-right: initial;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`;
