import { call, all, put, takeEvery, takeLatest, select } from "@redux-saga/core/effects";

import { agency } from "services";

import * as types from "reducers/admin";
import * as commonTypes from "reducers/common";

const log = console.log.bind(console);

function* agencyInfoFlow(payload) {
  try {
    let res = yield call(agency.agencyInfo);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          info: res.data,
        },
      });
    }
  } catch (e) {}
}

function* traineeListActive(payload) {
  try {
    const res = yield call(agency.traineeListActive);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: { traineeListActive: res.data },
      });
    }
  } catch (e) {}
}

function* traineeListInactive(payload) {
  try {
    const res = yield call(agency.traineeListInactive);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: { traineeListInactive: res.data },
      });
    }
  } catch (e) {}
}

function* traineeUpdateFlow(payload) {
  const { data, callback } = payload;
  try {
    yield all(data.pkList.map((pk) => agency.traineeUpdate({ pk: pk, is_active: data.status })));

    if (callback) callback();
  } catch (e) {
    if (callback) callback();
  }
}

function* reservationCreateFlow(payload) {
  //log("====> SAGA sessionCreateFlow : ", payload);

  const inputsToData = (inp) => {
    /*{
      "start_date": "2023-01-02",
      "end_date": "2023-01-02",
      "weekdays": [0, 1, 2, 3, 4, 5, 6],
      "device": "cd5ce4d8-ff61-482f-a98d-d09bfd0c2e0c",
      "date": "2023-01-02",
      "start_time": "10:00",
      "end_time": "11:00",
  }*/
    let weekdays = [];
    if (inp.sessionType === "forRange") {
      Object.keys(inp.weekdays).forEach((key, index) => {
        if (inp.weekdays[key]) weekdays.push(index);
      });
    }

    return {
      start_date: inp.startDate,
      end_date: inp.sessionType === "forDate" ? inp.startDate : inp.endDate,
      weekdays: !weekdays.length ? undefined : weekdays,
      device: inp.devicePk,
      start_time: inp.startTime,
      end_time: inp.endTime,
    };
  };
  try {
    const data = inputsToData(payload.inputs);

    const res = yield call(agency.reservationCreate, data);

    if (res.status === 201) {
      yield call(reservationListFlow, { data: { pk: data.device } });
    }
    yield put({ type: types.RESET_SESSION_CREATE });
  } catch (e) {}
}

function* reservationListFlow(payload) {
  try {
    const sessionPage = yield select((state) => state.admin.sessionPage);
    const { device, year, month } = payload.data || {};

    let data = {
      pk: device ? device : sessionPage.device.pk,
      month: parseInt(month ? month : sessionPage.month),
      year: parseInt(year ? year : sessionPage.year),
    };

    if (!sessionPage.device) {
      yield call(deviceListFlow);
      let device = yield select((state) => state.admin.sessionPage.device);
      data.pk = device.pk;
    }

    let res = yield call(agency.reservationList, data);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          sessionList: res.data,
        },
      });
    }
  } catch (e) {}
}

function* reservationDetailFlow(payload) {
  try {
    let res = yield call(agency.reservationDetail, payload.data);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          reservationDetail: res.data,
        },
      });
    }
  } catch (e) {}
}

function* reservationAddTraineeFlow(payload) {
  const { data, callback } = payload;
  try {
    let res = yield call(agency.reservationAddTrainee, {
      pk: data.pk,
      trainee: data.trainee,
    });
    if (callback) callback(res);
  } catch (e) {
    if (callback) callback(e);
  }
}

function* reservationRemoveTraineeFlow(payload) {
  const { data, callback } = payload;
  try {
    let res = yield call(agency.reservationRemoveTrainee, {
      pk: data.pk,
      trainee: null,
    });

    yield call(reservationDetailFlow, { data: { pk: data.pk } });
    if (callback) callback(res);
  } catch (e) {
    if (callback) callback(e);
  }
}

function* reservationDeleteFlow(payload) {
  const { data, callback } = payload;
  try {
    let res = yield call(agency.reservationDelete, { pk: data.pk });

    if (callback) callback(res);
  } catch (e) {
    if (callback) callback(e);
  }
}

function* deviceListFlow(payload) {
  try {
    const sessionPage = yield select((state) => state.admin.sessionPage);
    const res = yield call(agency.deviceList);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          deviceList: res.data,
        },
      });
      if (!sessionPage.device) {
        yield put({
          type: types.DEFAULT_ASSIGN,
          data: {
            sessionPage: {
              ...sessionPage,
              device: res.data[0],
            },
          },
        });
      }
    }
  } catch (e) {
    //log("====> SAGA ERR: ", e);
  }
}

function* evaluationListFlow(payload) {
  //log("@@@@ enter evaluationListFlow");
  //log("@@@@ payload: ", payload);

  const { data, callback } = payload;

  try {
    let res = yield call(agency.evaluationList, data);

    //log("@@@@ res: ", res);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          evaluationList: res.data,
        },
      });
    }

    if (callback) callback(res);
  } catch (e) {
    //log("====> SAGA ERR: ", e);
    if (callback) callback(e);
  }
}

function* evaluationExcelFlow(payload) {
  try {
    const response = yield call(agency.evaluationExcel);
    if (response.status === 200) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "evaluation-result.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  } catch (e) {
    console.error(e);
  }
}

function* evaluationDetailFlow(payload) {
  //log("@@@@ enter evaluationDetailFlow", payload);

  const { data, callback } = payload;

  try {
    let res = yield call(agency.evaluationDetail, { pk: data.evaluationPk });

    //log("@@@@ res: ", res);

    if (res.status === 200) {
      yield put({
        type: commonTypes.DEFAULT_ASSIGN,
        data: {
          evaluationDetail: res.data,
        },
      });
    }

    if (callback) callback(res);
  } catch (e) {
    //log("====> SAGA ERR: ", e);
    if (callback) callback(e);
  }
}

function* search(action) {
  const idx = action.data.idx; // 값이 변하지 않는 고유 리스트명
  const target = action.data.target.listName; // 필터링 된 데이터를 반환 시켜줄 state 변수명
  const fieldName = action.data.target.itemName; // 필터링 할 필드명 리스트
  const admin = yield select((state) => state.admin[idx]);
  const searchValue = action.data.value;
  let filterList = [];

  //log("search list type ", typeof admin);

  Object.keys(admin).forEach((key) => {
    let is = false;
    fieldName.forEach((item) => {
      if (admin[key][item] !== null) {
        // if (admin[key][item].indexOf(searchValue) === 0) {
        if (admin[key][item].indexOf(searchValue) > -1) {
          is = true;
        }
      }
    });

    if (is) {
      is = false;
      return filterList.push(admin[key]);
    }
  });

  yield put({
    type: types.DEFAULT_ASSIGN,
    data: { [target]: filterList },
  });
}

function* leaderboardFlow(payload) {
  const { data, callback } = payload;

  try {
    let res = yield call(agency.leaderboard, data);

    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          leaderboard: res.data,
        },
      });

      if (callback) callback(res);
    }
  } catch (e) {
    if (callback) callback(e);
  }
}

export default function* watchAgency() {
  yield takeEvery(types.AGENCY_INFO, agencyInfoFlow);

  yield takeEvery(types.DEVICE_LIST, deviceListFlow);

  yield takeEvery(types.TRAINEE_UPDATE, traineeUpdateFlow);
  yield takeLatest(types.TRAINEE_LIST_ACTIVE, traineeListActive);
  yield takeLatest(types.TRAINEE_LIST_INACTIVE, traineeListInactive);

  yield takeEvery(types.RESERVATION_LIST, reservationListFlow);
  yield takeEvery(types.RESERVATION_DETAIL, reservationDetailFlow);
  yield takeEvery(types.RESERVATION_ADD_TRAINEE, reservationAddTraineeFlow);
  yield takeEvery(types.RESERVATION_REMOVE_TRAINEE, reservationRemoveTraineeFlow);
  yield takeEvery(types.RESERVATION_DELETE, reservationDeleteFlow);
  yield takeEvery(types.RESERVATION_CREATE, reservationCreateFlow);

  yield takeLatest(types.EVALUATION_LIST, evaluationListFlow);
  yield takeLatest(types.EVALUATION_DETAIL, evaluationDetailFlow);
  yield takeLatest(types.EVALUATION_EXCEL, evaluationExcelFlow);

  yield takeEvery(types.LEADERBOARD, leaderboardFlow);

  yield takeEvery(types.SEARCH, search);
}
