import { client, fileDownload, GET, POST, PATCH, DELETE } from "utils/client";

export const agency = {
  login: (args) => client(POST, "/adm/agencies/login/", args),
  agencyInfo: () => client(GET, "/adm/agencies/info/"),
  agencyInfoUpdate: (args) => client(PATCH, "/adm/agencies/info/", args),
  //
  deviceList: () => client(GET, "/adm/devices/"),
  //
  reservationList: (args) => client(GET, `/adm/devices/${args.pk}/reservations/`, args),
  reservationDetail: (args) => client(GET, `/adm/reservations/${args.pk}/`),
  reservationRemoveTrainee: (args) =>
    client(PATCH, `/adm/reservations/${args.pk}/update_delete/`, args),
  reservationDelete: (args) => client(DELETE, `/adm/reservations/${args.pk}/update_delete/`),
  // need to implement:
  reservationCreate: (args) => client(POST, "/adm/reservations/create/", args),
  reservationAddTrainee: (args) =>
    client(PATCH, `/adm/reservations/${args.pk}/update_delete/`, args),
  //
  evaluationList: (args) => client(GET, "/adm/evaluations/", args),
  evaluationDetail: (args) => client(GET, `/adm/evaluations/${args.pk}/`),
  evaluationExcel: () => fileDownload("/adm/evaluations-excel/"),
  //
  traineeListActive: () => client(GET, "/adm/trainees/active/"),
  traineeListInactive: () => client(GET, "/adm/trainees/inactive/"),
  traineeUpdate: (args) => client(PATCH, `/adm/trainees/${args.pk}/`, args),
  //
  leaderboard: (args) => client(GET, "/adm/leaderboard/", args),
};
