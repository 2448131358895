import { client, GET, POST, PATCH, DELETE, fileDownload } from "utils/client";

export const trainee = {
  traineeCreate: (args) => client(POST, "/my/create/", args),
  //
  login: (args) => client(POST, "/my/login/", args),
  traineeInfo: () => client(GET, "/my/info/"),
  traineeInfoDelete: () => client(DELETE, "/my/info/delete/"),
  //
  reservationList: () => client(GET, "/my/reservations/"),
  reservationsJoinWithdraw: (args) => client(PATCH, `/my/reservations/${args.pk}/`),
  //
  deviceList: () => client(GET, "/my/devices/"),
  //
  agencyReservationList: (args) => client(GET, `/my/devices/${args.pk}/reservations/`, args),
  //
  evaluationList: () => client(GET, "/my/evaluations/"),
  evaluationDetail: (args) => client(GET, `/my/evaluations/${args.pk}/`),
  evaluationExcel: () => fileDownload("/my/evaluations-excel/"),
  //
  leaderboard: (args) => client(GET, "/my/leaderboard/", args),
};
