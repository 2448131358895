import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { ContentHeader, Header } from "components/ContentHeader";
import { StyledText, Summary } from "components/StyledText";
import Modal from "react-modal";
import Confirm from "components/Confirm";
import Alert from "components/Alert";

import { formatDateTime } from "utils/formatter";
import * as types from "reducers/trainee";

export default function MyPage() {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.trainee);
  const { t } = useTranslation(["user", "admin", "join", "common"]);

  const history = useHistory();

  const [confirm, setConfirm] = useState({
    show: false,
    withdrawal: false,
    modify: false,
  });
  const [alert, setAlert] = useState({
    show: false,
    modify: false,
    withdrawal: false,
  });

  const handleWithdrawal = () => {
    dispatch({
      type: types.MY_INFO_DELETE,
    });
  };

  useEffect(() => {
    dispatch({
      type: types.MY_INFO,
    });
  }, [dispatch]);

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <h2>{t("common:myPage")}</h2>
        </Header>
      </ContentHeader>
      <ContentContainer>
        <ContentBodyContainer>
          <Summary>
            <div>
              <StyledText>
                <label className="px-16-b">{t("admin:agencyName")}</label>
                <div className="px-16-r">{info.agency_name}</div>
              </StyledText>
              <StyledText>
                <label className="px-16-b">{t("userRegDate")}</label>
                <div className="px-16-r">{formatDateTime(info.created_at)}</div>
              </StyledText>
            </div>
            <div>
              <StyledText>
                <label className="px-16-b">{t("myId")}</label>
                <div className="px-16-r">{info.username}</div>
              </StyledText>
              <StyledText>
                <label className="px-16-b">{t("common:email")}</label>
                <div className="px-16-r">{info.email}</div>
              </StyledText>
            </div>
            <div>
              <StyledText>
                <label className="px-16-b">{t("userLastName")}</label>
                <div className="px-16-r">{info.last_name}</div>
              </StyledText>
              <StyledText>
                <label className="px-16-b">{t("userFirstName")}</label>
                <div className="px-16-r">{info.first_name}</div>
              </StyledText>
            </div>
          </Summary>
        </ContentBodyContainer>
      </ContentContainer>

      <ContentContainer>
        <ContentHeaderContainer style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <div>
            <span className="font-xs-title">{t("DelAccount")}</span>
            <span style={{ color: "#BDBDBD", paddingLeft: "12px" }} className="px-14-r">
              {t("DelNoti")}
            </span>
          </div>
        </ContentHeaderContainer>
        <ContentBodyContainer style={{ marginTop: "40px" }}>
          <InputDiv>
            <WithdrawalButton
              className="px-16-b"
              onClick={() => {
                setConfirm({
                  ...confirm,
                  show: true,
                  withdrawal: true,
                });
              }}
            >
              {t("DelAccount")}
            </WithdrawalButton>
          </InputDiv>
        </ContentBodyContainer>
      </ContentContainer>

      <Modal
        isOpen={confirm.show || alert.show}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        {confirm.show && confirm.modify ? (
          <Confirm
            content={t("common:wannaUpd")}
            actions={[
              {
                label: t("common:noUpd"),
                callback: () => {
                  setConfirm({
                    ...confirm,
                    show: false,
                    modify: false,
                  });
                },
              },
              {
                label: t("common:yesUpd"),
                callback: () => {
                  setConfirm({
                    ...confirm,
                    show: false,
                    modify: false,
                  });

                  setAlert({
                    ...alert,
                    show: true,
                    modify: true,
                  });
                },
              },
            ]}
          />
        ) : null}
        {confirm.show && confirm.withdrawal ? (
          <Confirm
            content={t("wannaDeleteAccount")}
            actions={[
              {
                label: t("yesDelete"),
                callback: () => {
                  setConfirm({
                    ...confirm,
                    show: false,
                    withdrawal: false,
                  });

                  setAlert({
                    ...alert,
                    show: true,
                    withdrawal: true,
                  });

                  handleWithdrawal();
                },
              },
              {
                label: t("noDelete"),
                callback: () => {
                  setConfirm({
                    ...confirm,
                    show: false,
                    withdrawal: false,
                  });
                },
              },
            ]}
          />
        ) : null}
        {alert.withdrawal ? (
          <Alert
            content={[t("deleteSuccess"), <br key={0}></br>, t("moveLogin")]}
            callback={() => {
              setAlert({
                ...alert,
                show: false,
                withdrawal: false,
              });
              history.push("/login");
            }}
          />
        ) : null}
        {alert.modify ? (
          <Alert
            content={t("common:applyChange")}
            callback={() => {
              setAlert({
                ...alert,
                show: false,
                modify: false,
              });
            }}
          />
        ) : null}
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 80px;
`;

const ContentContainer = styled.div``;
const ContentHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2.75rem;

  & > div {
    &:nth-of-type(1) {
      color: rgba(79, 79, 79, 1);
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
    }
  }
`;
const ContentBodyContainer = styled.div`
  padding: 3rem 2.75rem;
`;

const InputDiv = styled.div`
  &.flex-end {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      margin-top: initial !important;
      width: 250px;
    }
  }

  &:not(:nth-of-type(1)) {
    margin-top: 30px;
  }

  & > p {
    font-size: 1rem;
    color: #232323;
    line-height: 22px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    padding-bottom: 12px;
  }
`;

const WithdrawalButton = styled.div`
  width: 140px;
  height: 56px;
  background: #e54658;
  border: 0.8px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
`;
