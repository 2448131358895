import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ name, label, inputs, setInputs, disabled = false }) => {
  const { t } = useTranslation(["common"]);

  const [active, setActive] = useState(false);
  const hour = parseInt(inputs[name].split(":")[0]);

  const setTime = (hour) => {
    setInputs({
      ...inputs,
      [name]: `${hour}:00`,
    });
  };

  const amPm = (hour) => {
    return hour >= 12 ? t("common:pm") : t("common:am");
  };

  const handleChange = (sign, hour) => {
    const _name = name.toLowerCase();
    const _hour = sign === "+" ? hour + 1 : hour - 1;

    const earliestHour = 9;
    const latestHour = 19;

    const startTime = parseInt(
      _name.includes("lunch")
        ? inputs["lunchStartTime"].split(":")[0]
        : inputs["startTime"].split(":")[0]
    );
    const endTime = parseInt(
      _name.includes("lunch")
        ? inputs["lunchEndTime"].split(":")[0]
        : inputs["endTime"].split(":")[0]
    );

    if (sign === "+") {
      if (_hour > latestHour) return;
      if (_name.includes("start")) {
        if (_hour >= endTime) return;
      }

      setTime(_hour);
    } else {
      if (_hour < earliestHour) return;
      if (_name.includes("end")) {
        if (_hour <= startTime) return;
      }
      setTime(_hour);
    }
  };

  useEffect(() => {
    if (disabled) {
      setActive(false);
    }
  }, [disabled]);

  return (
    <Wrapper>
      <label className="px-16-b">{label}</label>
      {active === false ? (
        <Container
          disabled={disabled}
          onClick={() => {
            if (!disabled) setActive(true);
          }}
        >
          <div className="px-16-r">
            {amPm(hour)} {hour}:00
          </div>
          <div>
            <img src="/images/icon/ico-clock.svg" alt="" />
          </div>
        </Container>
      ) : (
        <ExpandContainer>
          <ExpandHeader onClick={() => setActive(false)}>
            <div className="px-16-r">
              {amPm(hour)} {hour}:00
            </div>
            <div>
              <img src="/images/icon/ico-clock.svg" alt="" />
            </div>
          </ExpandHeader>
          <ExpandBody>
            <AmPmSelector>
              <div style={{ height: "32px" }} />
              <div className="px-16-b">{amPm(hour)}</div>
              <div style={{ height: "32px" }} />
            </AmPmSelector>

            <TimeSelector>
              <div onClick={() => handleChange("+", hour)}>
                <img src="/images/icon/up.png" alt="" />
              </div>
              <div className="px-16-b">{hour}:00</div>
              <div onClick={() => handleChange("-", hour)}>
                <img src="/images/icon/down.png" alt="" />
              </div>
            </TimeSelector>
          </ExpandBody>
        </ExpandContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > label {
    display: block;
    padding-bottom: 12px;
  }
`;

const Container = styled.div`
  cursor: pointer;
  user-select: none;
  height: 56px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  ${({ disabled }) => {
    if (disabled === true) {
      return `
        background: #f3f3f7;
        cursor: not-allowed;
      `;
    }
  }}

  & > div {
    &:nth-of-type(1) {
    }
    color: rgba(51, 51, 51, 1);
  }
`;

const ExpandContainer = styled.div`
  border: 2px solid #0047ab;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
`;

const ExpandHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding-left: 20px;
  padding-right: 23px;
  color: rgba(0, 71, 171, 1);
  cursor: pointer;
`;
const ExpandBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AmPmSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > div {
    &:nth-of-type(1) {
      cursor: pointer;
    }
    &:nth-of-type(2) {
      width: 76px;
      height: 56px;
      background: #f3f3f7;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 71, 171, 1);
    }
    &:nth-of-type(3) {
      cursor: pointer;
    }
  }
`;
const TimeSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    &:nth-of-type(1) {
      cursor: pointer;
    }
    &:nth-of-type(2) {
      width: 148px;
      height: 56px;
      background: #f3f3f7;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 71, 171, 1);
    }
    &:nth-of-type(3) {
      cursor: pointer;
    }
  }
`;
