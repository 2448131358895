import { call, takeEvery, put, select, takeLatest } from "redux-saga/effects";

import * as types from "reducers/join";

import { trainee } from "services";

const log = console.log;

export function* validateTraineeAgencyCode(payload) {
  log("traineeCreateFlow", payload);

  const { data, callback } = payload;

  const dataToErrors = (resData) => {
    const errors = {};

    if (resData.agency_code) {
      errors.agencyCode = resData.agency_code;
    }

    return errors;
  };

  try {
    const res = yield call(trainee.traineeCreate, { agency_code: data.agencyCode });
    if (res.status === 400) {
      callback(dataToErrors(res.data));
    } else {
      throw res;
    }
  } catch (e) {
    callback({ unknownError: e });
  }
}

export function* createTrainee(payload) {
  log("createTrainee", payload);

  const { data, callback } = payload;

  const inputToData = {
    termsAccepted: "terms_accepted",
    privacyAccepted: "privacy_accepted",
    agencyCode: "agency_code",
    username: "username",
    password: "password",
    email: "email",
    firstName: "first_name",
    lastName: "last_name",
    phoneNumber: "phone_number",
    gender: "gender",
    birthYear: "birth_year",
  };

  const dataToInput = {
    terms_accepted: "termsAccepted",
    privacy_accepted: "privacyAccepted",
    agency_code: "agencyCode",
    username: "username",
    password: "password",
    email: "email",
    first_name: "firstName",
    last_name: "lastName",
    phone_number: "phoneNumber",
    gender: "gender",
    birth_year: "birthYear",
  };

  const dataToErrors = (resData) => {
    const errors = {};

    Object.keys(dataToInput).forEach((key) => {
      if (resData[key]) {
        errors[dataToInput[key]] = resData[key];
      }
    });
    console.log("dataToErrors", errors);
    return errors;
  };

  try {
    let _data = {};

    Object.keys(data).forEach((key) => {
      _data[inputToData[key]] = data[key];
    });

    const res = yield call(trainee.traineeCreate, _data);
    if (res.status === 400) {
      callback(dataToErrors(res.data));
    } else if (res.status === 201) {
      callback({});
    } else {
      throw res;
    }
  } catch (e) {
    callback({ unknownError: e });
  }
}

export default function* watch() {
  yield takeEvery(types.VALIDATE_TRAINEE_AGENCY_CODE, validateTraineeAgencyCode);
  yield takeEvery(types.CREATE_TRAINEE, createTrainee);
}
