import React from "react";
import styled, { css } from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ResultDetail() {
  const { numSetList } = useSelector((state) => ({
    numSetList: state.common.evaluationDetail.descriptions || [],
  }));

  const { t } = useTranslation(["user"]);

  const [numSet, setNumSet] = React.useState(0);
  const [left, setLeft] = React.useState(5);

  const safeArray = (arr) => (Array.isArray(arr) ? arr : [0]);

  const safeMin = (arr) => (arr.length ? Math.min(...arr) : 0);
  const safeMax = (arr) => (arr.length ? Math.max(...arr) : 0);
  const safeAvg = (arr, precision = 0) =>
    arr.length
      ? precision > 0
        ? (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(precision)
        : Math.floor(arr.reduce((a, b) => a + b, 0) / arr.length)
      : 0;
  const safeFloor = (num) => Math.floor(num);
  const safeCeil = (num) => Math.ceil(num);

  const currEvalDet = numSetList[numSet] || {};

  const val_depth = safeArray(currEvalDet.val_depth);
  const val_bpm = safeArray(currEvalDet.val_bpm);
  const val_breath_amt = safeArray(currEvalDet.val_breath_amt);

  const val_depth_min = safeMin(val_depth);
  const val_depth_max = safeMax(val_depth);
  const val_depth_avg = safeAvg(val_depth, 1);

  const val_bpm_min = safeMin(val_bpm);
  const val_bpm_max = safeMax(val_bpm);
  const val_bpm_avg = safeAvg(val_bpm);

  const val_breath_amt_min = safeMin(val_breath_amt);
  const val_breath_amt_max = safeMax(val_breath_amt);
  const val_breath_amt_avg = safeAvg(val_breath_amt);

  const isActive = (num) => num === numSet;

  const handleNumSet = (num) => {
    setNumSet(num);
    switch (num) {
      case 0:
        setLeft(5);
        break;
      case 1:
        setLeft(27.5);
        break;
      case 2:
        setLeft(50);
        break;
      case 3:
        setLeft(72.5);
        break;
      case 4:
        setLeft(95);
        break;
      default:
        break;
    }
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        min: val_depth_min - 0.5,
        max: val_depth_max + 0.5,
        ticks: {
          callback: function (value) {
            return value + "cm";
          },
        },
      },
    },
  };

  const data1 = {
    labels: [...Array(30)].map((_, index) => {
      return index + 1 < 10 ? "0" + (index + 1) : "" + (index + 1);
    }),
    datasets: [
      {
        label: t("compDepth"),
        data: val_depth,
        borderColor: "#0047AB",
        backgroundColor: "#0047AB",
        pointRadius: 5,
        pointBackgroundColor: ["#0047AB", "white"],
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        min: safeFloor(val_bpm_min / 10) * 10,
        max: safeCeil(val_bpm_max / 10) * 10,
        ticks: {
          callback: function (value) {
            return value + t("timesPerMin");
          },
        },
      },
    },
  };

  const data2 = {
    labels: [...Array(30)].map((_, index) => {
      return index + 1 < 10 ? "0" + (index + 1) : "" + (index + 1);
    }),
    datasets: [
      {
        label: t("compRate"),
        data: val_bpm,
        borderColor: "#0047AB",
        backgroundColor: "#0047AB",
        pointRadius: 5,
        pointBackgroundColor: ["#0047AB", "white"],
      },
    ],
  };

  const options3 = {
    scales: {
      yAxis: {
        min: safeFloor(val_breath_amt_min / 100) * 100,
        max: safeCeil(val_breath_amt_max / 100) * 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data3 = {
    labels: [t("1st"), t("2nd")],
    datasets: [
      {
        label: t("tidalVol"),
        data: val_breath_amt,
        backgroundColor: ({ chart: { ctx } }) => {
          const bg = ctx.createLinearGradient(0, 0, 0, 300);
          bg.addColorStop(0, "rgba(151, 71, 255, 0.8)");
          bg.addColorStop(1, "rgba(70, 147, 255, 0.8)");
          return bg;
        },
        borderRadius: 12,
        maxBarThickness: 72,
      },
    ],
  };

  return (
    <Wrapper>
      <FiveTabs left={left}>
        <div>
          <Tab className={isActive(0) ? "active" : ""} onClick={() => handleNumSet(0)}>
            {t("1st")}
          </Tab>
          <Tab className={isActive(1) ? "active" : ""} onClick={() => handleNumSet(1)}>
            {t("2nd")}
          </Tab>
          <Tab className={isActive(2) ? "active" : ""} onClick={() => handleNumSet(2)}>
            {t("3rd")}
          </Tab>
          <Tab className={isActive(3) ? "active" : ""} onClick={() => handleNumSet(3)}>
            {t("4th")}
          </Tab>
          <Tab className={isActive(4) ? "active" : ""} onClick={() => handleNumSet(4)}>
            {t("5th")}
          </Tab>
        </div>
        <div>
          <Summary>
            <div>
              <TopSummary>
                <div className="px-16-b">{t("avgCompDepth")}</div>
                <div className="px-12-b">5-6cm</div>
              </TopSummary>
              <BottomSummary>
                <img src="/images/icon/summary-2.svg" alt="Summary icon" />
                <div>
                  <span className="font-s-title">{val_depth_avg}</span>
                  <span className="px-16-b"> cm</span>
                </div>
              </BottomSummary>
            </div>
            <div style={{ paddingLeft: "40px" }}>
              <TopSummary>
                <div className="px-16-b">{t("avgCompRate")}</div>
                <div className="px-12-b">
                  100-120
                  {t("timesPerMin")}
                </div>
              </TopSummary>
              <BottomSummary>
                <img src="/images/icon/summary-1.svg" alt="Summary icon" />
                <div>
                  <span className="font-s-title">
                    {val_bpm_avg} {t("times")}
                  </span>
                  <span className="px-16-b">{t("timesPerMin")}</span>
                </div>
              </BottomSummary>
            </div>
            <SummaryDivision />
            <div style={{ paddingLeft: "40px" }}>
              <TopSummary>
                <div className="px-16-b">{t("avgTidalVolume")}</div>
                <div className="px-12-b">500-600ml</div>
              </TopSummary>
              <BottomSummary>
                <img src="/images/icon/summary-3.svg" alt="Summary icon" />
                <div>
                  <span className="font-s-title">{val_breath_amt_avg}</span>
                  <span className="px-16-b">ml</span>
                </div>
              </BottomSummary>
            </div>
          </Summary>
        </div>
      </FiveTabs>
      <Divided height={60} />
      <ChartContainer>
        <h2 className="font-xs-title">{t("compDepth")}</h2>
        <Line options={options1} data={data1} />
      </ChartContainer>

      <ChartContainer>
        <h2 className="font-xs-title">{t("compRate")}</h2>
        <Line options={options2} data={data2} />
      </ChartContainer>

      <ChartContainer>
        <h2 className="font-xs-title">{t("tidalVol")}</h2>
        <Bar options={options3} data={data3} />
      </ChartContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 3rem 2.75rem;

  & > h2 {
    color: #0047ab;
    padding-left: 8px;
    padding-right: 8px;

    & > span {
      &:nth-of-type(1) {
        margin-right: 20px;
      }
    }
  }

  & > p {
    padding-left: 8px;
    padding-right: 8px;
    color: var(--mono-2);
    margin-top: 20px;
    & .point {
      color: #0047ab;
    }
    &:nth-of-type(1) {
    }
    &:nth-of-type(2) {
    }
  }
`;

const Divided = styled.div`
  border-top: 1px solid rgba(0, 71, 171, 0.5);
  margin-top: ${(props) => (props.height ? `${props.height}px` : "32px")};
`;

const ChartContainer = styled.div`
  padding-top: 40px;

  & > h2 {
    text-align: center;
    padding-bottom: 48px;
  }
`;

const FiveTabs = styled.div`
  & > div {
    &:nth-of-type(1) {
      padding-top: 30px;
      padding-bottom: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        width: 70px;
        height: 40px;
        background: rgba(70, 147, 255, 0.12);
        border-radius: 50px;
        display: flex;
        font-family: Noto Sans KR;
        color: #4f4f4f;
        letter-spacing: -0.4px;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          background: #0047ab;
          color: #ffffff;
        }
      }
    }
    &:nth-of-type(2) {
      width: 100%;
      padding-left: 90px;
      padding-right: 90px;
      background: rgb(70, 147, 255, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      position: relative;
      text-align: center;
      border-radius: 10px;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: rgb(70, 147, 255, 0.08) transparent;
        border-width: 0 10px 15px;
        top: -15px;
        margin-left: -10px;
        ${(props) => {
          if (props.left) {
            return css`
              left: ${props.left}%;
            `;
          }
        }}
      }

      &::before {
        content: "";
        position: absolute;
        width: 0;
        z-index: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.08) transparent;
        border-width: 0 11px 16px;
        top: -16.5px;
        ${(props) => {
          if (props.left) {
            return css`
              left: ${props.left}%;
            `;
          }
        }}
        margin-left: -11px;
        display: block;
      }

      .bubble {
        position: relative;
        background: #2e1515;
        color: #ffffff;
        font-family: Arial;
        font-size: 20px;
        line-height: 120px;
        text-align: center;
        width: 250px;
        height: 120px;
        border-radius: 10px;
        padding: 0px;
        border: #ff0000 solid 1px;
      }
      .bubble:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #2e1515 transparent;
        border-width: 0 10px 15px;
        top: -15px;
        ${(props) => {
          if (props.left) {
            return css`
              left: ${props.left}%;
            `;
          }
        }}

        margin-left: -10px;
      }
      .bubble:before {
        content: "";
        position: absolute;
        width: 0;
        z-index: 0;
        border-style: solid;
        border-color: #ff0000 transparent;
        border-width: 0 11px 16px;
        top: -16.5px;
        ${(props) => {
          if (props.left) {
            return css`
              left: ${props.left}%;
            `;
          }
        }}
        margin-left: -11px;
        display: block;
      }
    }
  }
`;

const Tab = styled.div``;

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > div {
    height: 100%;
  }
`;
const TopSummary = styled.div`
  padding-top: 50px;
  & > div {
    &:nth-of-type(1) {
      padding-bottom: 4px;
    }
    &:nth-of-type(2) {
    }
  }

  padding-bottom: 25px;
`;
const BottomSummary = styled.div`
  padding-bottom: 57px;
  display: flex;
  align-items: center;

  & > div {
    padding-left: 9px;
    & > span {
      color: #0047ab;
    }
  }
`;
const SummaryDivision = styled.div`
  min-height: 140px !important;
  box-sizing: border-box;

  padding-left: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;
