import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Header from "./components/Header";
import ProtectedRoute from "./components/ProtectedRoute";

import HomePage from "./containers/HomePage";
import LoginPage from "./containers/LoginPage";
import JoinPage from "./containers/JoinPage";
import UserPage from "./containers/UserPage";
import AdminPage from "./containers/AdminPage";
import FindPage from "./containers/FindPage";
import NotFound from "containers/NotFound";

import { useDispatch } from "react-redux";
import * as types from "reducers/common";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 900) {
      dispatch({
        type: types.DEFAULT_ASSIGN,
        data: {
          platform: "MOBILE",
        },
      });
    } else {
      dispatch({
        type: types.DEFAULT_ASSIGN,
        data: {
          platform: "PC",
        },
      });
    }

    window.addEventListener("resize", (e) => {
      if (window.innerWidth <= 900) {
        dispatch({
          type: types.DEFAULT_ASSIGN,
          data: {
            platform: "MOBILE",
          },
        });
      } else {
        dispatch({
          type: types.DEFAULT_ASSIGN,
          data: {
            platform: "PC",
          },
        });
      }
    });

    dispatch({
      type: types.GET_IS_AGENCY,
    });
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <ProtectedRoute exact path={"/"} component={HomePage} />
          <Route path={"/login"} component={LoginPage} />
          <Route path={"/join"} component={JoinPage} />
          <Route path={"/find"} component={FindPage} />
          <ProtectedRoute path={"/my"} component={UserPage} />
          <ProtectedRoute path={"/admin"} component={AdminPage} />
          <Route component={NotFound} status={404} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
