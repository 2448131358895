import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled, { css } from "styled-components";
import Input from "components/Input";
import Dropdown from "./Dropdown";
import TimeDropdown from "./TimeDropdown";
import DateDropdown from "./DateDropdown";

import * as types from "reducers/admin";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/formatter";

// eslint-disable-next-line import/no-anonymous-default-export
export default function Create() {
  const { t } = useTranslation(["admin", "common"]);
  const dispatch = useDispatch();
  const { deviceList, sessionPage, sessionCreateState } = useSelector((state) => {
    return {
      deviceList: state.admin.deviceList,
      sessionPage: state.admin.sessionPage,
      sessionCreateState: state.admin.sessionCreate,
    };
  });
  const [inputs, setInputs] = useState({
    sessionType: sessionCreateState.sessionType,
    startDate: sessionCreateState.chosenDate || new Date().toISOString().split("T")[0],
    startDateChosen: false,
    endDate: new Date().toISOString().split("T")[0],
    endDateChosen: false,
    weekdays: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    deviceName: sessionPage.device ? sessionPage.device.name : "",
    devicePk: sessionPage.device ? sessionPage.device.pk : "",
    startTime: "10:00",
    endTime: "11:00",
  });

  const [errors, setErrors] = useState({
    weekdaysError: false,
    deviceError: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const changeDays = (key) => {
    setInputs({
      ...inputs,
      weekdays: {
        ...inputs.weekdays,
        [key]: !inputs.weekdays[key],
      },
    });
    setErrors({
      ...errors,
      weekdaysError: false,
      weekdaysErrorMsg: "",
    });
  };

  const deviceSelectionOptions = () => {
    return deviceList.map((device) => {
      return {
        name: device.name,
        key: device.pk,
      };
    });
  };

  const selectDevice = (item) => {
    setInputs({
      ...inputs,
      deviceName: item.name,
      devicePk: item.key,
    });
    setErrors({
      ...errors,
      deviceError: false,
      deviceErrorMsg: "",
    });
  };

  const validateAndSubmit = () => {
    let err = 0;

    if (inputs.deviceName === "") {
      setErrors({
        ...errors,
        deviceError: true,
      });
      err++;
    }

    if (inputs.sessionType === "forRange") {
      // at least one day of week should be selected
      if (Object.values(inputs.weekdays).every((day) => day === false)) {
        setErrors({
          ...errors,
          weekdaysError: true,
        });
        err++;
      }
    }

    if (err === 0 && !isLoading) {
      handleSessionCreateModal();
    }
  };

  const handleSessionCreateModal = () => {
    setIsLoading(true);
    dispatch({
      type: types.RESERVATION_CREATE,
      inputs: inputs,
    });
  };

  useEffect(() => {
    dispatch({
      type: types.DEVICE_LIST,
    });
  }, [dispatch]);

  // 일일, 정기 분기되어야함, 쿼리로 처리
  return (
    <ContentContainer data-id="not-parent-2">
      <Card>
        <CardHeader className="font-s-title">
          {sessionCreateState.sessionType === "forDate"
            ? t("createDailySession")
            : t("createRegularSession")}
          <ModalClose onClick={() => dispatch({ type: types.RESET_SESSION_CREATE })}> </ModalClose>
        </CardHeader>

        <CardBody>
          {sessionCreateState.sessionType === "forDate" ? null : (
            <>
              <InputDivGroup>
                <InputDiv>
                  <DateDropdown
                    name="startDate"
                    label={t("selectStartDate")}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                </InputDiv>
                <InputDiv>
                  <DateDropdown
                    name="endDate"
                    label={t("selectEndDate")}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                </InputDiv>
              </InputDivGroup>
            </>
          )}

          <InputDiv>
            <Dropdown
              labelNoPadding="true"
              name="device"
              placeholder={t("selectDevicePlaceholder")}
              label={t("common:device")}
              type="text"
              error={errors.deviceError}
              chosen={inputs.deviceName}
              value={{ key: inputs.devicePk, name: inputs.deviceName }}
              options={deviceSelectionOptions()}
              zIndex={10}
              onChange={(e) => selectDevice(e)}
            />
          </InputDiv>

          {sessionCreateState.sessionType === "forDate" ? (
            <>
              <InputDiv>
                <Input
                  name="date"
                  placeholder=""
                  label={t("common:date")}
                  type="text"
                  value={formatDate(sessionCreateState.chosenDate)}
                />
              </InputDiv>
            </>
          ) : null}

          <InputDivGroup>
            <InputDiv>
              <TimeDropdown
                name="startTime"
                label={t("eduStartTime")}
                inputs={inputs}
                setInputs={setInputs}
              />
            </InputDiv>
            <InputDiv>
              <TimeDropdown
                name="endTime"
                label={t("eduEndTime")}
                inputs={inputs}
                setInputs={setInputs}
              />
            </InputDiv>
          </InputDivGroup>

          {sessionCreateState.sessionType === "forRange" ? (
            <>
              <InputDiv>
                <h2 style={{ paddingBottom: "12px" }} className="px-16-b">
                  {t("selectDayOfWeek")}
                </h2>
                <DayContainer>
                  {Object.keys(inputs.weekdays).map((day) => {
                    return (
                      <Day
                        error={errors.weekdaysError}
                        active={inputs.weekdays[day]}
                        onClick={(e) => {
                          changeDays(e.target.innerText.toLowerCase());
                        }}
                      >
                        {day.toUpperCase()}
                      </Day>
                    );
                  })}
                </DayContainer>
              </InputDiv>
            </>
          ) : null}

          <SubmitDiv
            className="px-16-b"
            disabled={isLoading}
            onClick={() => {
              validateAndSubmit();
            }}
          >
            {t("createReservation")}
          </SubmitDiv>
        </CardBody>
      </Card>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  background: rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow-y: auto;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 900px) {
    max-width: 100vw;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: white;
  margin: 0 auto;
  border-radius: 12px;
`;

const CardHeader = styled.div`
  height: 124px;
  display: flex;
  align-items: center;
  padding: 0 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(79, 79, 79, 1);
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 540px;
  align-items: center;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 90px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 48px;
    padding-right: 48px;
  }
`;

const InputDivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    &:nth-of-type(2) {
      margin-left: 12px;
    }
  }
`;
const InputDiv = styled.div`
  padding-top: 32px;
  width: 100%;
`;

const DayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Day = styled.div`
  cursor: pointer;
  user-select: none;
  width: 100px;
  height: 56px;
  background: rgba(186, 186, 186, 0.08);
  background-blend-mode: darken;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;

  font-size: 1rem;
  letter-spacing: -2%;
  font-weight: 400;
  font-style: normal;
  color: rgba(130, 130, 130, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.active) {
      return css`
        background: rgba(0, 71, 171, 1);
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: white;
      `;
    } else if (props.error) {
      return css`
        background: rgba(229, 70, 88, 0.08);
        background-blend-mode: darken;
        border: 0.8px solid rgba(0, 0, 0, 0.12);
        color: #e54658;
      `;
    }
  }}
`;

const SubmitDiv = styled.div`
  width: 100%;
  height: 60px;
  background: ${(props) => (props.disabled ? "rgba(0, 71, 171, 0.2)" : "rgba(0, 71, 171, 1)")};
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  color: white;
  cursor: pointer;
  user-select: none;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const ModalClose = styled.span`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 26px;
  height: 26px;
  background: url("/images/icon/ico-circle-delete.png") no-repeat;

  cursor: pointer;
`;
