import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Input from "components/Input";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "utils/formatter";

let tempRowIdx = [];
let tempRowCheckList = {};

export default function TableContainer(props) {
  const {
    height,
    index,
    selection,
    row,
    column: propColumn,
    paging,
    totalSize,
    currentPage,
    children,
    handlePaging,
    handleSorting,
    selectionRow,
    size,
    customFn,
    isRadio,
  } = props;

  const { t } = useTranslation(["common"]);
  const [column, setColumn] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [rowCheckList, setRowCheckList] = useState({});

  const columnSize = row.map((el) => el.size);
  const columnList = row.map((el) => el.prop);

  const pagingList = totalSize ? Array.from({ length: totalSize }, (_, i) => i + 1) : [];

  const handleSelect = (param, index) => {
    if (param !== "all") {
      const {
        target: { id, checked },
      } = param;
      const el = document.getElementById("row" + id);

      if (checked) el.classList.add("checked");
      else el.classList.remove("checked");

      setRowList(index);
    } else {
      handleSelectAll();
    }
  };

  const handleSelectAll = () => {
    const target = document.querySelectorAll(".table__row");
    if (checkAll) {
      target.forEach((el) => {
        if (el.querySelector("input")) {
          el.querySelector("input").checked = false;
          el.classList.remove("checked");
        }
      });
      resetSelection();
      setCheckAll(false);
    } else {
      target.forEach((el) => {
        if (el.querySelector("input")) {
          el.querySelector("input").checked = true;
          el.classList.add("checked");
        }
      });
      updateSelection();
      setCheckAll(true);
    }
    setRowCheckList(tempRowCheckList);
  };

  const resetSelection = () => {
    tempRowIdx = [];
    tempRowCheckList = {};
  };

  const updateSelection = () => {
    resetSelection();
    column.forEach((_, i) => {
      tempRowIdx.push(i + 1);
      tempRowCheckList[i + 1] = column[i];
    });
    selectionRow(tempRowCheckList);
  };

  const setRowList = (rowIdx) => {
    if (isRadio) {
      document.querySelectorAll(".table__row").forEach((el) => {
        if (el.querySelector("input")) {
          el.querySelector("input").checked = false;
          el.classList.remove("checked");
        }
      });
      resetSelection();
      tempRowIdx.push(rowIdx);
      tempRowCheckList[rowIdx] = column[rowIdx - 1];
      document.getElementById("row" + rowIdx).classList.add("checked");
      document.getElementById(rowIdx).checked = true;
    } else {
      if (tempRowIdx.includes(rowIdx)) {
        tempRowIdx = tempRowIdx.filter((item) => item !== rowIdx);
        delete tempRowCheckList[rowIdx];
      } else {
        tempRowIdx.push(rowIdx);
        tempRowCheckList[rowIdx] = column[rowIdx - 1];
      }
    }

    setRowCheckList(tempRowCheckList);
    selectionRow(tempRowCheckList);
  };

  const handlePage = (e, type) => {
    const {
      target: { innerText: page },
    } = e;
    handlePaging(type || page);
  };

  const setSorting = (e, key) => {
    const {
      target: { classList },
    } = e;
    const isDesc = classList.contains("desc");

    const sortedArr = [...column].sort((a, b) => {
      return isDesc ? (a[key] > b[key] ? 1 : -1) : b[key] > a[key] ? 1 : -1;
    });

    classList.toggle("desc", !isDesc);
    classList.toggle("asc", isDesc);

    setColumn(sortedArr);
    handleSorting(key);
  };

  const clearCheck = () => {
    document.querySelectorAll(".table__row input").forEach((input) => {
      input.checked = false;
      input.parentElement.classList.remove("checked");
    });
  };

  useEffect(() => {
    setColumn(propColumn);
  }, [propColumn]);

  useEffect(() => {
    clearCheck();
  }, []);

  return (
    <Wrapper height={height} size={size}>
      {row && (
        <TableHeader>
          {selection && !isRadio && (
            <TableHeaderItem size="0" align="left">
              <Input
                type="checkbox"
                label={t("number")}
                labelPosition="left"
                checkStyle="purple"
                id="checkAll"
                checked={checkAll}
                onChange={() => handleSelect("all")}
              />
            </TableHeaderItem>
          )}
          {!selection && index && (
            <TableHeaderItem className="px-16-r" size="0" align="left">
              {t("number")}
            </TableHeaderItem>
          )}
          {selection && isRadio && (
            <TableHeaderItem className="px-16-r" size="0" align="left">
              {t("number")}
            </TableHeaderItem>
          )}

          {row.map((item, index) => (
            <TableHeaderItem
              key={index}
              size={item.size}
              sorting={item.sorting}
              className={item.sorting ? "asc" : "desc"}
              onClick={(e) => item.sorting && setSorting(e, item.prop)}
            >
              {item.name}
            </TableHeaderItem>
          ))}
          {children && <TableHeaderItem size="1"></TableHeaderItem>}
          {customFn && <TableHeaderItem size="2"></TableHeaderItem>}
        </TableHeader>
      )}

      {column.length > 0 ? (
        column.map((item, i) => (
          <TableBody key={i} className="table__row" id={"row" + (i + 1)} rowBg={item.rowColor}>
            {selection && (
              <TableBodyItem size="0" align="left">
                <Input
                  type="checkbox"
                  label={i + 1}
                  id={i + 1}
                  labelPosition="left"
                  checkStyle="purple"
                  onChange={(e) => handleSelect(e, i + 1)}
                />
              </TableBodyItem>
            )}
            {!selection && index && (
              <TableBodyItem size="0" align="left">
                {i + 1}
              </TableBodyItem>
            )}
            {columnList.map((el, j) =>
              el !== "button" ? (
                <TableBodyItem key={j} size={columnSize[j]} align="left">
                  {(() => {
                    if (row[j]?.type === "player_id") {
                      return `${item.last_name}, ${item.first_name}`;
                    } else if (row[j]?.type === "date") {
                      return formatDate(item[el]);
                    } else if (row[j]?.type === "datetime") {
                      return formatDateTime(item[el]);
                    } else {
                      return item[el];
                    }
                  })()}
                </TableBodyItem>
              ) : (
                <TableBodyItem size={columnSize[j]} align="left" />
              )
            )}
            {children && <TableBodyItem size="1">{children}</TableBodyItem>}
            {customFn && (
              <TableBodyItem align="center" size="2">
                {customFn(item)}
              </TableBodyItem>
            )}
          </TableBody>
        ))
      ) : (
        <TableBody noData>{t("tableNoData")}</TableBody>
      )}

      {paging && (
        <PagingWrapper>
          <PagingButton
            className={currentPage === 1 ? "disabled prev" : "prev"}
            onClick={(e) => handlePage(e, "prev")}
          >
            {t("prevPage")}
          </PagingButton>
          <PagingList onClick={handlePage}>
            {pagingList.map((el) => (
              <PagingItem key={el} className={currentPage === el ? "active" : ""}>
                {el}
              </PagingItem>
            ))}
          </PagingList>
          <PagingButton
            className={currentPage === totalSize ? "disabled next" : "next"}
            onClick={(e) => handlePage(e, "next")}
          >
            {t("nextPage")}
          </PagingButton>
        </PagingWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-width: ${(props) => (props.size === "small" ? "900px" : "calc(100vw - 250px)")};
  height: ${(props) => props.height || "100%"};
  overflow: auto;

  @media (max-width: 900px) {
    min-width: 796px;
  }
`;

const TableHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  background: rgba(186, 186, 186, 0.08);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
`;

const TableHeaderItem = styled.span`
  align-items: center;
  display: inline-flex;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  color: #4f4f4f;
  padding: 1rem 1.25rem;
  box-sizing: border-box;
  min-width: 6.26rem;

  &:first-child {
    padding-left: 2.75rem;
  }

  &:last-child {
    padding-right: 2.75rem;
  }

  flex: ${(props) => props.size};
  justify-content: ${(props) =>
    props.align === "center" ? "center" : props.align === "right" ? "flex-end" : "flex-start"};
  cursor: ${(props) => (props.sorting ? "pointer" : "default")};

  &.asc::after,
  &.desc::after {
    display: inline-block;
    content: "";
    width: 10px;
    height: 6px;
    margin-left: 12px;
    vertical-align: middle;
    background-image: url(${(props) =>
      props.sorting ? "/images/icon/dropdown-down.svg" : "/images/icon/dropdown-up.svg"});
  }
`;

const TableBody = styled.div`
  display: flex;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
  background: ${(props) => props.rowBg || "#fff"};

  &.checked {
    background: rgba(0, 71, 171, 0.08);
  }

  &:hover {
    background: rgba(186, 186, 186, 0.08);
  }

  ${(props) =>
    props.noData &&
    css`
      color: var(--mono-3);
      padding: 20px 40px;
      justify-content: center;
    `}
`;

const TableBodyItem = styled.span`
  display: inline-flex;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  color: #4f4f4f;
  padding: 1rem 1.25rem;
  word-break: break-all;
  box-sizing: border-box;
  min-width: 6.26rem;

  &:first-child {
    padding-left: 2.75rem;
  }

  &:last-child {
    padding-right: 2.75rem;
  }

  flex: ${(props) => props.size};
  justify-content: ${(props) =>
    props.align === "center" ? "center" : props.align === "right" ? "flex-end" : "flex-start"};
`;

const PagingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 1rem 3rem;
`;

const PagingList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PagingItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 40px;
  font-weight: 500;
  font-size: 1rem;
  color: #828282;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 9px;
  box-sizing: border-box;
  cursor: pointer;
  opacity: ${(props) => (props.className === "active" ? "1" : "0.3")};
`;

const PagingButton = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #828282;
  cursor: pointer;
  opacity: ${(props) => (props.className.includes("disabled") ? "0.3" : "1")};
  padding-right: ${(props) => (props.className.includes("next") ? "22px" : "0")};
  padding-left: ${(props) => (props.className.includes("prev") ? "22px" : "0")};
  background: url(${(props) =>
      props.className.includes("next")
        ? "/images/icon/right-arrow.png"
        : "/images/icon/left-arrow.png"})
    no-repeat center / 8px;
`;
