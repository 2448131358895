import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media (min-width: 900px) {
    font-size: 0.875rem;
  }
`;

export const LeftSide = styled.div`
  width: 100%;
  max-width: 250px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease;

  @media (min-width: 900px) {
    transform: translateX(0);
    position: static;
  }
`;

export const RightSide = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: calc(100% - 250px);

  @media (max-width: 900px) {
    max-width: 100%;
    min-width: 796px;
  }
`;

export const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 20px;
  left: 5px;
  z-index: 1000;
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 3px 1px;

  @media (max-width: 900px) {
    display: block;
  }

  & > div {
    width: 20px;
    height: 20px;
    border-left: 3px solid #0047ab;
    border-bottom: 2px solid #0047ab;

    transition: 0.3s;
    transform: rotate(225deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border: 0.5px solid rgba(0, 0, 0, 0.12);
      & > div {
        width: 30px;
        height: 3px;
        background-color: #0047ab;
        margin: 6px 0;
        transform: rotate(45deg);
      }
      & > div:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 5px);
      }
      & > div:nth-child(2) {
        opacity: 0;
      }
      & > div:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -5px);
      }
    `}
`;
