export const setAuth = (key, value, isKeepAccount = false) => {
  if (isKeepAccount) {
    document.cookie = `${key}=${value}; path=/; max-age=3600;`;
  } else {
    document.cookie = `${key}=${value}; path=/; max-age=86400`;
  }
};

export const getAuth = (key) => {
  const cookie = document.cookie.split(";");
  let cookieList = [];
  let auth = "";

  cookie.forEach((el) => {
    cookieList.push(el.split("="));
  });

  cookieList.forEach((el) => {
    if (el[0].trim() === key) {
      auth = el[1];
    }
  });

  return auth;
};

export const hasAuth = () => {};

export const removeAuth = () => {
  setAuth("authToken", "");
};

export const logOut = () => {
  removeAuth();
  window.location.reload();
};
