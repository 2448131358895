import React from "react";
import UserLayout from "containers/UserLayout";

export default function UserPage(props) {
  return (
    <React.Fragment>
      <UserLayout {...props} />
    </React.Fragment>
  );
}
