import styled from "styled-components";
import Input from "components/Input";
import Button from "components/Button";
import Switch from "./Switch";
import { useTranslation } from "react-i18next";

export default function LoginPage(props) {
  const { t } = useTranslation(["login", "common"]);
  const { errors, inputs, isKeepAccount, setIsKeepAccount, isAgency, fn } = props;
  return (
    <Wrapper>
      <LoginWrapper>
        <LoginContainer>
          <LoginTitle>{t("login:login")}</LoginTitle>
          <Switch onChange={fn.handleIsAgency} isAgency={isAgency} />
        </LoginContainer>
        <LoginForm>
          <Input
            name="username"
            type="text"
            placeholder={t("login:idPlaceholder")}
            value={inputs.username}
            icon="human"
            margin="bottom"
            marginSize={15}
            onChange={(e) => fn.onChange(e)}
            clear={() => fn.clear("username")}
            errorMsg={errors.usernameErrorMsg}
            error={errors.usernameError}
          />
          <Input
            name="password"
            type="password"
            placeholder={t("login:pwdPlaceholder")}
            value={inputs.password}
            icon="lock"
            onChange={(e) => fn.onChange(e)}
            clear={(e) => fn.clear("password")}
            errorMsg={errors.pwdErrorMsg}
            error={errors.pwdError}
          />

          <LoginKeepWrapper>
            <Input
              type="checkbox"
              label={t("login:checkbox")}
              labelPosition="left"
              checkStyle="purple"
              id="loginKeep"
              value={isKeepAccount}
              onChange={(e) => setIsKeepAccount(e.target.value)}
            />

            <LoginFindAccount>
              <i className="icon question" />
              <a onClick={(e) => fn.goFindId(e)} href="/find?step=1">
                {t("common:findId")}
              </a>
              /
              <a onClick={(e) => fn.goResetPassword(e)} href="/find?step=3">
                {t("login:resetPassword")}
              </a>
            </LoginFindAccount>
          </LoginKeepWrapper>

          <Button
            line
            onClick={fn.onSubmit}
            margin="bottom"
            color={isAgency ? "333333" : null}
            borderColor={isAgency ? "333333" : null}
          >
            {t("login:login")}
          </Button>
          <Button onClick={fn.goJoin} color={isAgency ? "333333" : null}>
            {t("login:join")}
          </Button>
        </LoginForm>
      </LoginWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 80px);
  background: url("/images/bg/bg-login.png") no-repeat center / cover #f5f5f5;

  overflow: auto;

  @media screen and (max-width: 900px) {
    align-items: start;
    background: #fff;
  }
`;

const LoginWrapper = styled.div`
  flex: 0 0 550px;
  height: 600px;
  background: #ffffff;
  box-shadow: 8px 20px 25px rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  padding: 60px 65px;
  box-sizing: border-box;

  @media screen and (max-width: 900px) {
    flex: 1;
    padding: 60px 20px;

    box-shadow: none;
  }
`;

const LoginTitle = styled.h3`
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  color: #4f4f4f;

  /* margin-bottom: 68px; */

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    line-height: 34px;

    margin-bottom: 88px;
  }
`;

const LoginForm = styled.form``;

const LoginKeepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 50px 0;
`;

const LoginFindAccount = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: end;

  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #232323;
  text-decoration: none;

  @media screen and (max-width: 900px) {
    min-width: 131px;
    font-size: 12px;
  }

  & > a {
    font-family: Noto Sans KR;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    color: #232323;
    a:link {
      color: #232323;
      text-decoration: none;
    }
    a:visited {
      color: #232323;
      text-decoration: none;
    }
    a:hover {
      color: #232323;
      text-decoration: none;
    }
  }

  & > i {
    flex: 0 0 21px;
    height: 21px;
    background: url("/images/icon/ico-question.svg") no-repeat center / cover;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 68px;

  @media screen and (max-width: 900px) {
    margin-bottom: 0px;
  }
`;
