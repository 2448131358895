import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TableContainer from "components/Table";

import { ContentHeader, Header } from "components/ContentHeader";

import * as traineeTypes from "reducers/trainee";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function MyPage() {
  const dispatch = useDispatch();
  const { filter } = useParams();

  const { t } = useTranslation(["common"]);

  const column = useSelector((state) => state.trainee.leaderboard);

  const title = {
    all: "leaderboardAll",
    today: "leaderboardToday",
    week: "leaderboardWeek",
  }[filter];

  const row = [
    {
      name: t("fullName"),
      size: "3",
      prop: "full_name",
    },
    {
      name: t("score"),
      size: "3",
      prop: "score",
    },
    {
      name: t("dateTime"),
      size: "3",
      prop: "created_at",
      type: "datetime",
    },
  ];

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  useEffect(() => {
    dispatch({
      type: traineeTypes.MY_LEADERBOARD,
      data: {
        filter,
      },
    });
  }, [dispatch, filter]);

  return (
    <Wrapper>
      <ContentHeader>
        <Header>
          <div>
            <h2 className="title">{t(title)}</h2>
          </div>
        </Header>
      </ContentHeader>

      <TableContainer
        index={true}
        row={row}
        column={column}
        paging
        handlePaging={(page) => handlePaging(page)}
        handleSorting={(param) => handleSorting(param)}
        totalSize={1}
        currentPage={1}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;
