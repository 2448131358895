import React, { useEffect, useState } from "react";
import ActivePresenter from "./Presenter";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import * as types from "reducers/admin";

export default function ActiveContainer() {
  const { t } = useTranslation(["common", "admin"]);
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState({
    detail: false,
    iniquity: false,
    group: false,
  });

  const [selectionList, setSelectionList] = useState([]);

  const row = [
    {
      name: t("fullName"),
      size: "3",
      sorting: true,
      prop: "full_name",
    },
    {
      name: t("email"),
      size: "3",
      sorting: true,
      prop: "email",
    },
    {
      name: t("phoneNumber"),
      size: "3",
      sorting: true,
      prop: "phone_number",
    },
    {
      name: t("dateTime"),
      size: "3",
      sorting: true,
      prop: "date_joined",
      type: "datetime",
    },
  ];

  const column = useSelector((state) => state.admin.traineeListActive);

  const getList = () => {
    dispatch({
      type: types.TRAINEE_LIST_ACTIVE,
    });
  };

  const setIniquity = () => {
    handleModal("iniquity", true);
  };

  const setPending = () => {
    dispatch({
      type: types.TRAINEE_UPDATE,
      data: { pkList: selectionList[0], status: false },
      callback: () => {
        // FIXME
        // this due to the selection list not being cleared
        // when the modal is closed
        window.location.reload();
      },
    });
  };

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  const handleModal = (target, state) => {
    setIsModal({ [target]: state });
  };

  const handleSelectList = (row) => {
    let options = [];

    options.push(
      Object.keys(row).map((index) => {
        return row[index].pk;
      })
    );

    setSelectionList(options);
  };

  const handleClose = () => {
    setIsModal({
      detail: false,
      iniquity: false,
      group: false,
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <ActivePresenter
      row={row}
      column={column}
      selectionList={selectionList}
      setIniquity={setIniquity}
      setPending={setPending}
      isModal={isModal}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
      handleSelectList={handleSelectList}
      handleClose={handleClose}
    />
  );
}
