import { useEffect, useState } from "react";
import EvaluationPresenter from "./Presenter";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import * as types from "reducers/admin";

export default function EvaluationContainer() {
  const { t } = useTranslation(["common", "admin"]);
  const dispatch = useDispatch();

  const { column } = useSelector((state) => {
    return {
      column: state.admin.evaluationList,
    };
  });
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const handleDownload = () => {
    dispatch({
      type: types.EVALUATION_EXCEL,
    });
    handleClick();
  };

  const row = [
    {
      name: t("fullName"),
      size: "1",
      sorting: true,
      prop: "full_name",
    },
    {
      name: t("email"),
      size: "1",
      sorting: true,
      prop: "email",
    },
    {
      name: t("phoneNumber"),
      size: "1",
      sorting: true,
      prop: "phone_number",
    },
    {
      name: t("dateTime"),
      size: "1",
      sorting: true,
      prop: "created_at",
      type: "datetime",
    },
    {
      name: t("score"),
      size: "1",
      sorting: true,
      prop: "score",
    },
  ];

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  useEffect(() => {
    dispatch({
      type: types.EVALUATION_LIST,
    });
  }, []);
  return (
    <EvaluationPresenter
      row={row}
      column={column}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
      handleDownload={handleDownload}
      disabled={disabled}
    />
  );
}
