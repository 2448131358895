import React from "react";
import AdminLayout from "containers/AdminLayout";

export default function AdminPage(props) {
  return (
    <React.Fragment>
      <AdminLayout {...props} />
    </React.Fragment>
  );
}
