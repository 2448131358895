import styled from "styled-components";

const Layout = styled.div`
  background-color: white;
  width: 900px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  padding-top: 24px;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 0px;
    border-radius: initial;
  }
`;
export default Layout;
