import { call, takeLatest } from "@redux-saga/core/effects";
import * as types from "reducers/login";
import { agency, trainee } from "services";

function* loginFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(trainee.login, data);

    callback(res.data, res.status);
  } catch (e) {
    callback(e, 400);
  }
}

function* agencyLoginFlow(payload) {
  const { data, callback } = payload;
  try {
    const res = yield call(agency.login, data);

    callback(res.data, res.status);
  } catch (e) {
    callback(e, 400);
  }
}

export default function* watchLogin() {
  yield takeLatest(types.TRAINEE_LOGIN, loginFlow);
  yield takeLatest(types.AGENCY_LOGIN, agencyLoginFlow);
}
