import { put, takeLatest } from "@redux-saga/core/effects";
import * as types from "reducers/common";
import { setAuth, getAuth } from "utils/auth";

function* setAuthToken(action) {
  try {
    setAuth("authToken", action.data.token, action.data.isKeepAccount);
  } catch (e) {}
}

function* setIsAgency(action) {
  try {
    setAuth("isAgency", action.data);
    yield put({
      type: types.DEFAULT_ASSIGN,
      data: {
        isAgency: action.data,
      },
    });
  } catch (e) {}
}

function* getIsAgency(action) {
  try {
    if (getAuth("isAgency") !== "") {
      let isAgency = JSON.parse(getAuth("isAgency"));
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          isAgency: isAgency,
        },
      });
    }
  } catch (e) {}
}

export default function* watchLogin() {
  yield takeLatest(types.SET_AUTH_TOKEN, setAuthToken);
  yield takeLatest(types.SET_IS_AGENCY, setIsAgency);
  yield takeLatest(types.GET_IS_AGENCY, getIsAgency);
}
