import React, { useState } from "react";
import styled, { css } from "styled-components";

// active 처리는 여기에서
// options 만 받자
// div ul 조합
export default function Dropdown({
  labelNoPadding,
  size,
  placeholder = "",
  label,
  inputLabel,
  options = [],
  onChange,
  value = "",
  zIndex,
  disabled,
  error,
  noBorder,
}) {
  const [show, setShow] = useState(false);

  const hideDropdown = () => {
    setShow(false);
    document.removeEventListener("click", hideDropdown);
  };

  return (
    <Wrapper zIndex={zIndex} labelNoPadding={labelNoPadding} inputLabel={inputLabel}>
      {label && <label>{label}</label>}
      <Select
        noBorder={noBorder}
        error={error}
        show={show}
        size={size}
        onClick={(e) => {
          if (!show) {
            document.addEventListener("click", hideDropdown);
          }

          if (!disabled) {
            setShow(!show);
          }

          e.preventDefault();

          if (e.stopPropagation()) {
            e.stopPropagation();
          } else {
            e.cancelBubble = true;
          }
        }}
        disabled={disabled}
      >
        {value === "" ? (
          <Placeholder error={error}>{placeholder}</Placeholder>
        ) : (
          <span>{value}</span>
        )}
        <img
          src={show ? "/images/icon/dropdown-up.svg" : "/images/icon/dropdown-down.svg"}
          alt="dropdown"
        />
      </Select>
      {show ? (
        <OptionContainer>
          <ul>
            {options.map((item, index) => (
              <Option
                size={size}
                key={index}
                active={value === item}
                onClick={() => {
                  if (onChange) onChange(item);
                }}
              >
                {item}
              </Option>
            ))}
          </ul>
        </OptionContainer>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: "Noto Sans KR";
  position: relative;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 9)};

  & > label {
    display: block;
    padding-left: ${(props) => (props.labelNoPadding ? "0px" : "10px")};

    ${(props) => {
      if (props.inputLabel) {
        return `
            font-weight: bold;
            font-size: 1rem;
            line-height: 23px;
            color: #232323;
            margin-bottom: 8px;
            padding-left: 0px;
            padding-bottom: 0px;
          
            &.error {
              color: #E54658;
            }
          `;
      } else {
        return `
          padding-bottom: 12px;
          font-weight: 500;
          font-size: 15px;
          letter-spacing: -2%;
          `;
      }
    }}
  }
`;

const Select = styled.div`
  background-color: white;
  min-width: 96px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 17px 20px;
  padding-right: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
  & > img {
    user-select: none;
  }
  & > span {
    font-size: 1rem;
    font-weight: 500;
  }

  }

  ${(props) => {
    if (props.show) {
      return css`
        border: 2px solid #0047ab;
        padding: 17px 19px;
        padding-right: 25px;
      `;
    }
  }}
  ${(props) => {
    if (props.size === "sm") {
      return css`
        height: 44px;
      `;
    } else {
      return css`
        height: 56px;
      `;
    }
  }}
  ${(props) => {
    if (props.disabled) {
      return css`
        background: #f3f3f7;
        border: 1px solid rgba(0, 0, 0, 0.03);
      `;
    }
  }}

  ${(props) => {
    if (props.error) {
      return css`
        border: 0.8px solid rgba(229, 70, 88, 0.12);
        background-color: rgba(229, 70, 88, 0.08);
      `;
    }
  }}
  ${(props) => {
    if (props.noBorder) {
      return css`
        border: none;
      `;
    }
  }}

`;

const OptionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  margin-top: 4px;
  overflow: auto;
  ${(props) => {
    if (props.noBorder) {
      return css`
        border: none;
      `;
    }
  }}
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  color: rgba(130, 130, 130, 1);
  font-weight: bolder;

  ${(props) => {
    if (props.active) {
      return css`
        background-color: rgba(0, 71, 171, 0.12);
        color: rgba(0, 71, 171, 1);
      `;
    }
  }}
  ${(props) => {
    if (props.size === "sm") {
      return css`
        height: 44px;
      `;
    } else {
      return css`
        height: 60px;
      `;
    }
  }}
`;

const Placeholder = styled.span`
  font-weight: bolder;
  font-size: 15px;
  user-select: none;

  ${(props) => {
    if (props.error) {
      return css`
        color: #e54658;
      `;
    } else {
      return css`
        color: #828282;
      `;
    }
  }}
`;
