import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledText, Summary } from "components/StyledText";
import { ContentHeader, Header } from "components/ContentHeader";

import { formatDateTime } from "utils/formatter";

import * as adminTypes from "reducers/admin";

export default function MyPage() {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.admin);
  const { t } = useTranslation(["admin", "common"]);

  useEffect(() => {
    dispatch({
      type: adminTypes.AGENCY_INFO,
    });
  }, [dispatch]);

  return (
    <>
      <ContentHeader>
        <Header>
          <h2>{t("common:myPage")}</h2>
        </Header>
      </ContentHeader>
      <ContentContainer>
        <Summary>
          <div>
            <StyledText>
              <label className="px-16-b">{t("agencyName")}</label>
              <div className="px-16-r">{info.agency_name}</div>
            </StyledText>
            <StyledText>
              <label className="px-16-b">{t("agencyCode")}</label>
              <div className="px-16-r">{info.code}</div>
            </StyledText>
          </div>
          <div>
            <StyledText>
              <label className="px-16-b">{t("adminID")}</label>
              <div className="px-16-r">{info.admin_username}</div>
            </StyledText>
            <StyledText>
              <label className="px-16-b">{t("common:fullName")}</label>
              <div className="px-16-r">{info.full_name}</div>
            </StyledText>
          </div>
          <div>
            <StyledText>
              <label className="px-16-b">{t("common:email")}</label>
              <div className="px-16-r">{info.email}</div>
            </StyledText>
            <StyledText>
              <label className="px-16-b">{t("common:phoneNumber")}</label>
              <div className="px-16-r">{info.phone_number}</div>
            </StyledText>
          </div>
          <div>
            <StyledText>
              <label className="px-16-b">{t("createdDtAgencyInfo")}</label>
              <div className="px-16-r">{formatDateTime(info.created_at)}</div>
            </StyledText>
          </div>
        </Summary>
      </ContentContainer>
    </>
  );
}

const ContentContainer = styled.div`
  padding 3rem 2.75rem;
`;
