import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import EvaluationPresenter from "./Presenter";

import * as types from "reducers/trainee";

export default function EvaluationContainer() {
  const { t } = useTranslation(["common", "user"]);
  const dispatch = useDispatch();
  const { evaluationList } = useSelector((state) => state.trainee);

  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const handleDownload = () => {
    dispatch({
      type: types.MY_EVALUATION_EXCEL,
    });
    handleClick();
  };

  const row = [
    {
      name: t("device"),
      size: "1",
      sorting: true,
      prop: "device_name",
    },
    {
      name: t("dateTime"),
      size: "1",
      sorting: true,
      prop: "created_at",
      type: "datetime",
    },
    {
      name: t("score"),
      size: "1",
      sorting: true,
      prop: "score",
    },
  ];

  useEffect(() => {
    dispatch({
      type: types.MY_EVALUATION_LIST,
    });
  }, []);

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  return (
    <EvaluationPresenter
      row={row}
      column={evaluationList}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
      handleDownload={handleDownload}
      disabled={disabled}
    />
  );
}
