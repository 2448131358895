import React from "react";
import styled from "styled-components";

export default function Button({ children, disabled, callback, error }) {
  return (
    <Wrapper
      disabled={disabled}
      onClick={() => {
        if (disabled) return false;
        if (callback) callback();
      }}
      error={error}
    >
      <div>
        <span>{children}</span>
        <img src="/images/icon/ico-button-arrow.png" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  border-radius: 50px;
  background-color: ${(props) =>
    props.disabled ? "rgba(224, 224, 224, 1)" : "rgba(0, 71, 171, 1)"};
  height: 45px;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  ${(props) => (props.error ? "margin-bottom: 3.3rem;" : "")}

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      font-weight: bold;
      margin-right: 10px;
      word-break: keep-all;
    }

    & > img {
    }
  }
`;
