import styled from "styled-components";

export default function Confirm(props) {
  return (
    <Wrapper>
      {props.icon && (
        <img
          style={{ paddingBottom: "16px" }}
          width={32}
          src="/images/icon/alert-triangle.svg"
          alt="alert"
        />
      )}
      <Content className="font-xs-title">{props.content}</Content>
      <ButtonConfiner>
        {props.actions.map((item, index) => {
          return (
            <div key={index}>
              <Button
                filled={index === 1}
                className="px-16-b"
                onClick={() => {
                  if (item.callback) item.callback();
                }}
              >
                {item.label}
              </Button>
            </div>
          );
        })}
      </ButtonConfiner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 90px;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  left: 20%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12), 0px 12px 50px 12px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  color: var(--mono-1);
`;

const Content = styled.div`
  text-align: center;
`;

const ButtonConfiner = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;

    &:not(:nth-of-type(1)) {
      margin-top: 12px;
    }
  }
`;

const Button = styled.button`
  padding-left: 15px;
  padding-right: 15px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.filled ? "rgba(0, 71, 171, 0.12)" : "white")};
  border-radius: 5px;
  color: ${(props) => (props.filled ? "var(--a-purple-3)" : "var(--mono-4)")};
  cursor: pointer;
  margin-top: 40px;
  outline: none;
  border: none;
`;
