import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import Moment from "react-moment";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { monthYearFormat, formatDate } from "utils/formatter";

import * as types from "reducers/trainee";

export default function Calendar() {
  const { i18n, t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const { sessionJoin, sessionList } = useSelector((state) => {
    return {
      sessionJoin: state.trainee.sessionJoin,
      sessionList: state.trainee.sessionList,
    };
  });

  const [now, setNow] = useState(new Date().setDate(1));
  const [today] = useState(new Date());

  const [focusDay, setFocusDay] = useState(null);
  const [focusSchedule, setFocusSchedule] = useState(-1);

  let _now = new Date(now);
  const [d, setD] = useState({
    firstIndex: new Date(now).getDay(),
    lastIndex: new Date(_now.getFullYear(), _now.getMonth() + 1, 0).getDate(),
  });

  const closeModal = () => {
    dispatch({
      type: types.DEFAULT_ASSIGN,
      data: {
        sessionJoin: {
          ...sessionJoin,
          type: "",
          show: false,
          point: {
            x: 0,
            y: 0,
          },
        },
      },
    });
  };

  const setMonth = (month) => {
    dispatch({
      type: types.SET_SESSION_MONTH,
      data: month,
    });
  };

  const setYear = (year) => {
    dispatch({
      type: types.SET_SESSION_YEAR,
      data: year,
    });
  };

  const prevMonth = () => {
    let _now = new Date(now);
    _now.setDate(1);
    _now.setMonth(_now.getMonth() - 1);

    setNow(_now);

    setD({
      firstIndex: new Date(_now).getDay(),
      lastIndex: new Date(_now.getFullYear(), _now.getMonth() + 1, 0).getDate(),
    });

    setFocusDay(null);
    closeModal();

    setMonth(_now.getMonth() + 1);
    setYear(_now.getFullYear());

    dispatch({
      type: types.MY_AGENCY_RESERVATION_LIST,
    });
  };

  const nextMonth = () => {
    let _now = new Date(now);
    _now.setDate(1);
    _now.setMonth(_now.getMonth() + 1);
    setNow(_now);

    setD({
      firstIndex: new Date(_now).getDay(),
      lastIndex: new Date(_now.getFullYear(), _now.getMonth() + 1, 0).getDate(),
    });

    setMonth(_now.getMonth() + 1);
    setYear(_now.getFullYear());

    setFocusDay(null);
    closeModal();

    dispatch({
      type: types.MY_AGENCY_RESERVATION_LIST,
    });
  };

  return (
    <Wrapper>
      <MenuContainer onClick={closeModal}>
        <div>
          <div onClick={prevMonth}>
            <img src="/images/icon/left-arrow.png" alt="" />
          </div>
          <div>
            <Moment format={monthYearFormat(i18n.language)}>{now}</Moment>
          </div>
          <div onClick={nextMonth}>
            <img src="/images/icon/right-arrow.png" alt="" />
          </div>
        </div>
      </MenuContainer>
      <CalendarContainer>
        <MonthContainer>
          <MonthHeader>
            {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day) => (
              <div key={day}>{t(day)}</div>
            ))}
          </MonthHeader>
          <MonthBody>
            {[...Array(d.firstIndex + d.lastIndex > 35 ? 42 : 35)].map((item, index) => {
              // 여기서 계산하고 나중에 아이템 넣어도 될듯
              let isAnotherMonth = d.firstIndex > index || d.lastIndex + d.firstIndex <= index;
              let _d = new Date(now);
              let _today = new Date(
                _d.getFullYear(),
                _d.getMonth() + 1,
                index - d.firstIndex - (d.lastIndex - 1)
              );
              return (
                <DayOfMonth
                  anotherMonth={isAnotherMonth}
                  focus={(() => {
                    if (focusDay === null) {
                      return 0;
                    } else {
                      if (focusDay > -1 && focusDay === index) {
                        return 1;
                      } else {
                        return -1;
                      }
                    }
                  })()}
                  key={index}
                  isToday={formatDate(_today) === formatDate(today)}
                  onClick={() => {
                    if (sessionJoin.show) return false;
                    if (isAnotherMonth) return false;

                    if (focusDay === null) {
                      setFocusDay(index);
                    } else {
                      if (focusDay > -1) {
                        setFocusDay(null);
                      }
                    }
                  }}
                >
                  <div>
                    {new Date(
                      _d.getFullYear(),
                      _d.getMonth() + 1,
                      index - d.firstIndex - (d.lastIndex - 1)
                    ).getDate()}
                  </div>
                  {(() => {
                    let schedules = sessionList[moment(_today).format("YYYY-MM-DD")];
                    return (
                      Array.isArray(schedules) &&
                      schedules.map((item, _index) => {
                        return (
                          <Schedule
                            isAvailable={item.is_available}
                            focus={focusDay === index && focusSchedule === _index}
                            onClick={(e) => {
                              if (!item.is_available) {
                                dispatch({
                                  type: types.DEFAULT_ASSIGN,
                                  data: {
                                    sessionJoin: {
                                      ...sessionJoin.modal,
                                      type: "impossible",
                                      show: true,
                                      point: {
                                        x: e.currentTarget.offsetLeft,
                                        y: e.currentTarget.offsetTop,
                                        w: e.currentTarget.offsetWidth,
                                      },
                                      info: {
                                        ...item,
                                      },
                                    },
                                  },
                                });

                                return;
                              }
                              setFocusSchedule(_index);

                              setFocusDay(index);

                              dispatch({
                                type: types.DEFAULT_ASSIGN,

                                data: {
                                  sessionJoin: {
                                    ...sessionJoin.modal,
                                    type: "reservation",
                                    show: true,

                                    point: {
                                      x: e.currentTarget.offsetLeft,
                                      y: e.currentTarget.offsetTop,
                                      w: e.currentTarget.offsetWidth,
                                    },
                                    info: {
                                      ...item,
                                    },
                                  },
                                },
                              });
                            }}
                            key={_index}
                          >
                            {/* 9:00 - 10:00 */}
                            {`${item.start_time} - ${item.end_time}`}
                          </Schedule>
                        );
                      })
                    );
                  })()}
                </DayOfMonth>
              );
            })}
          </MonthBody>
        </MonthContainer>
      </CalendarContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const MenuContainer = styled.div`
  height: 144px;
  position: relative;

  & > div {
    &:nth-of-type(1) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      & > div {
        &:nth-of-type(1) {
          padding: 20px;
          cursor: pointer;
        }
        &:nth-of-type(2) {
          padding-left: 24px;
          padding-right: 24px;
          & > time,
          span {
            font-weight: bold;
            letter-spacing: -2%;
            color: #4f4f4f;
            font-size: 32px;
          }
        }
        &:nth-of-type(3) {
          padding: 20px;
          cursor: pointer;
        }
      }
    }
    &:nth-of-type(2) {
      position: absolute;
      right: 40px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;

      & > div {
        width: 132px;
      }
    }
  }
`;
const CalendarContainer = styled.div`
  width: 100%;
`;

const MonthContainer = styled.div`
  width: 100%;
`;
const MonthHeader = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  & > div {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--mono-3);
  }
`;
const MonthBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const DayOfMonth = styled.div`
  cursor: pointer;
  user-select: none;
  width: calc(100% / 7);
  min-height: 164px;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;
  background-color: ${(props) => (props.anotherMonth ? "rgba(186, 186, 186, 0.08)" : "#FFFFFF")};

  ${(props) => {
    if (props.focus) {
      switch (props.focus) {
        case -1:
          return css`
            opacity: 0.3;
          `;
        case 0:
          return css``;
        case 1:
          return css`
            box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
          `;
        default:
          break;
      }
    }
  }}

  &:nth-of-type(7n + 1) {
    border-left: initial;
  }
  &:nth-of-type(7n + 7) {
    border-right: initial;
  }

  & > div {
    &:nth-of-type(1) {
      width: 28px;
      height: 28px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      font-size: 12px;
      letter-spacing: -0.02em;
      font-weight: bolder;
      margin-top: 7px;
      margin-bottom: 12px;
      color: ${(props) => (props.anotherMonth ? "#E0E0E0" : "#4f4f4f")};

      ${(props) => {
        if (props.isToday) {
          return css`
            background-color: #0047ab;
            color: white;
          `;
        }
      }}
    }
  }
`;

const Schedule = styled.div`
  cursor: pointer;
  width: 100%;
  background-color: #6fcf97;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: -0.02em;
  font-weight: bolder;
  height: 24px;

  ${(props) => {
    if (!props.isAvailable) {
      return css`
        background-color: #828282;
      `;
    }
  }}
  &:nth-of-type(n + 3) {
    margin-top: 4px;
  }
`;
