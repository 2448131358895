import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getAuth } from "utils/auth";

const WithProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (getAuth("authToken")) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default WithProtectedRoute;
