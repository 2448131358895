import React from "react";
import styled from "styled-components";
import { Switch } from "react-router-dom";

import ProtectedRoute from "components/ProtectedRoute";
import AsideContainer from "./AsideContainer";

import Reservation from "containers/UserPage/Reservation";
import AgencyReservation from "containers/UserPage/AgencyReservation";
import Evaluation from "containers/UserPage/Evaluation";
import EvaluationDetail from "containers/UserPage/Evaluation/Detail";
import MyPage from "containers/UserPage/MyPage";
import NotFound from "containers/NotFound";
import Leader from "containers/UserPage/Leader";

export default function UserAside(props) {
  return (
    <Wrapper>
      <AsideContainer {...props} />
      {/* user routes */}
      <ContentContainer>
        <Switch>
          <ProtectedRoute exact path={"/my/reservations"} component={Reservation} />
          <ProtectedRoute exact path={"/my/agency-reservations"} component={AgencyReservation} />
          <ProtectedRoute exact path={"/my/evaluations"} component={Evaluation} />
          <ProtectedRoute exact path={"/my/evaluations/:pk"} component={EvaluationDetail} />
          <ProtectedRoute exact path={"/my/page"} component={MyPage} />
          <ProtectedRoute exact path={"/my/leaderboard/:filter"} component={Leader} />
          <ProtectedRoute exact path={"*"} component={NotFound} status={404} />
        </Switch>
      </ContentContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
