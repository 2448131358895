import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import ReservationPresenter from "./Presenter";
import * as types from "reducers/trainee";

export default function ReservationContainer() {
  const { t } = useTranslation(["user"]);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [cntUser, setCntUser] = useState("10");
  const [isModal, setIsModal] = useState({
    info: false,
    success: false,
  });

  const [modalData, setModalData] = useState("");
  const modal = {
    isModal: isModal,
    setIsModal: setIsModal,
    modalData: modalData,
  };
  const row = [
    {
      name: t("common:device"),
      size: "3",
      sorting: true,
      prop: "device_name",
    },
    {
      name: t("common:eduDate"),
      size: "3",
      sorting: true,
      prop: "start_datetime",
      type: "datetime",
    },
    {
      name: "",
      size: "0",
      prop: "button",
    },
  ];

  const { column } = useSelector((state) => {
    return {
      column: state.trainee.reservationList,
    };
  });

  const onChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handlePaging = (page) => {
    console.log("paging", page);
  };

  const handleSorting = (param) => {
    console.log("sorting", param);
  };

  const handleButton = (item) => {
    setIsModal({
      info: true,
      susses: false,
    });
    setModalData(item);
  };

  const handleSuccess = () => {
    setIsModal({
      success: true,
    });
  };

  const closeModal = () => {
    setIsModal({
      info: false,
      success: false,
    });
  };

  const handleWithdrawn = (param) => {
    dispatch({
      type: types.MY_RESERVATION_WITHDRAWN,
      data: { reservationPk: param.pk },
      callback: () => handleSuccess(),
    });
  };

  useEffect(() => {
    dispatch({
      type: types.MY_RESERVATION_LIST,
    });
  }, [dispatch]);

  return (
    <ReservationPresenter
      row={row}
      column={column}
      searchData={search}
      cntUserData={cntUser}
      modal={modal}
      onChange={onChange}
      handlePaging={handlePaging}
      handleSorting={handleSorting}
      handleButton={handleButton}
      closeModal={closeModal}
      handleWithdrawn={handleWithdrawn}
    />
  );
}
