import React from "react";
import styled from "styled-components";

export default function StepStatus({ step, labels = [] }) {
  const isActive = (index) => {
    if (index + 1 === step) {
      return true;
    }
  };

  return (
    <Wrapper>
      {labels.map((item, index) => (
        <React.Fragment key={index}>
          <Step active={isActive(index)}>
            <div>{index + 1}</div>
            <div>{item}</div>
          </Step>
          {index === labels.length - 1 ? null : <Line />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    &:nth-of-type(1) {
      width: 35px;
      height: 35px;
      background-color: ${(props) => (props.active ? "rgba(0, 71, 171, 1)" : "#F2F2F2;")};
      border: ${(props) => (props.active ? "0.5px solid rgba(0, 0, 0, 0.08)" : "")};
      box-shadow: ${(props) => (props.active ? "0px 0px 12px 2px rgba(0, 71, 171, 0.25)" : "")};
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
    }
    &:nth-of-type(2) {
      color: rgba(79, 79, 79, 1);
      font-size: 14px;
      font-weight: 500;
      padding-top: 19px;
    }
  }
`;

const Line = styled.div`
  width: 95px;
  height: 1px;
  border: 1px solid #828282;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 17px;
`;
