import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function Alert(props) {
  const { t } = useTranslation(["common"]);

  return (
    <Wrapper>
      <Content className="font-xs-title">{props.content}</Content>
      <Button
        className="px-16-b"
        onClick={() => {
          if (props.callback) props.callback();
        }}
      >
        {t("ok")}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 90px;
  justify-content: space-between;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12), 0px 12px 50px 12px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  color: var(--mono-1);
`;

const Content = styled.div`
  text-align: center;
`;

const Button = styled.div`
  width: 60px;
  height: 36px;
  background-color: var(--a-purple-3);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 71, 171, 0.12);
  border-radius: 5px;
  color: var(--a-purple-3);
  cursor: pointer;
  margin-top: 40px;
`;
