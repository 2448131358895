import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Layout from "./Layout";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Reset from "./Reset";

import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function FindPage() {
  const { t } = useTranslation(["join"]);
  const parsed = queryString.parse(useLocation().search);
  return (
    <Wrapper>
      <GoLogin
        onClick={() => {
          window.location.href = "/login";
        }}
      >
        <img src="/images/back-button.png" />
        <span>{t("join:backToLogin")}</span>
      </GoLogin>
      <Layout>
        {parsed.step === "1" ? <Step1 /> : null}
        {parsed.step === "2" ? <Step2 /> : null}
        {parsed.step === "3" ? <Step3 /> : null}
        {parsed.step === "4" ? <Step4 /> : null}
        {parsed.step === "reset" ? <Reset /> : null}
      </Layout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #e5e5e5;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 60px;
  box-sizing: border-box;
  padding-bottom: 175px;
`;

const GoLogin = styled.div`
  display: flex;
  width: 900px;
  margin: 0 auto;
  align-items: center;
  cursor: pointer;
  margin-bottom: 11px;

  & > span {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: bold;
    color: #c9c9c9;
    padding-left: 12px;
    line-height: 20px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
