import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { logOut } from "utils/auth";
import { useTranslation } from "react-i18next";
import { LeftSide, ToggleButton } from "components/Wrapper";

export default function AsideContainer(props) {
  const { t } = useTranslation(["admin", "common"]);
  const url = props.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const asideRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const evalUrl = "/admin/evaluations";
  const reseUrl = "/admin/reservations";
  const deviUrl = "/admin/devices";
  const userActiveUrl = "/admin/active";
  const userPendingUrl = "/admin/pending";
  const leaderboardTop = "/admin/leaderboard/all";
  const leaderboardToday = "/admin/leaderboard/today";
  const leaderboardWeek = "/admin/leaderboard/week";
  const myPageUrl = "/admin/mypage";

  return (
    <>
      <ToggleButton onClick={toggleSidebar} isOpen={isOpen} ref={asideRef}>
        <div />
        <div />
        <div />
      </ToggleButton>
      <LeftSide isOpen={isOpen}>
        <AsideLogo>
          <div>
            <Link to="/">
              <img src="/images/logo.png" alt="Company Logo" />
            </Link>
          </div>
        </AsideLogo>

        <MainLinkWrapper>
          <MainLinkContainer>
            <h2 className="px-16-b">{t("common:evaluations")}</h2>
            <AsideLink>
              <StyledLink to={evalUrl} className={url.startsWith(evalUrl) ? "active" : ""}>
                <img
                  src={
                    url.startsWith(evalUrl)
                      ? "/images/icon/ico-heart-pulse-active.svg"
                      : "/images/icon/ico-heart-pulse.svg"
                  }
                  alt={t("common:trainingAndExamResult")}
                />
                <span className="depts01 px-16-r">{t("common:trainingAndExamResult")}</span>
              </StyledLink>
            </AsideLink>
          </MainLinkContainer>

          <MainLinkContainer>
            <h2 className="px-16-b">{t("eduManagement")}</h2>
            <AsideLink>
              <StyledLink to={reseUrl} className={url.startsWith(reseUrl) ? "active" : ""}>
                <img
                  src={
                    url.startsWith(reseUrl)
                      ? "/images/icon/ico-calendar-active.svg"
                      : "/images/icon/ico-calendar.svg"
                  }
                  alt={t("admin:createSession")}
                />
                <span className="depts01 px-16-r">{t("admin:createSession")}</span>
              </StyledLink>
              <StyledLink to={deviUrl} className={url.startsWith(deviUrl) ? "active" : ""}>
                <img
                  src={
                    url.startsWith(deviUrl)
                      ? "/images/icon/ico-device-active.svg"
                      : "/images/icon/ico-device.svg"
                  }
                  alt={t("deviceManage")}
                />
                <span className="depts01 px-16-r">{t("deviceManage")}</span>
              </StyledLink>
            </AsideLink>
          </MainLinkContainer>

          <MainLinkContainer>
            <h2 className="px-16-b">{t("memberManagement")}</h2>
            <AsideLink>
              <StyledLink
                to={userActiveUrl}
                className={url.startsWith(userActiveUrl) ? "active" : ""}
              >
                <img
                  src={
                    url.startsWith(userActiveUrl)
                      ? "/images/icon/ico-user-check-active.svg"
                      : "/images/icon/ico-user-check.svg"
                  }
                  alt={t("activeMember")}
                />
                <span className="depts01 px-16-r">{t("activeMember")}</span>
              </StyledLink>
              <StyledLink
                to={userPendingUrl}
                className={url.startsWith(userPendingUrl) ? "active" : ""}
              >
                <img
                  src={
                    url.startsWith(userPendingUrl)
                      ? "/images/icon/ico-user-minus-active.svg"
                      : "/images/icon/ico-user-minus.svg"
                  }
                  alt={t("pendingMember")}
                />
                <span className="depts01 px-16-r">{t("pendingMember")}</span>
              </StyledLink>
            </AsideLink>
          </MainLinkContainer>

          <MainLinkContainer>
            <h2 className="px-16-b">{t("common:leaderboard")}</h2>
            <AsideLink>
              <StyledLink
                to={leaderboardTop}
                className={url.startsWith(leaderboardTop) ? "active" : ""}
              >
                <img
                  src={
                    url.startsWith(leaderboardTop)
                      ? "/images/icon/ico-trophy-active.svg"
                      : "/images/icon/ico-trophy.svg"
                  }
                  alt={t("common:top100")}
                />
                <span className="depts01 px-16-r">{t("common:top100")}</span>
              </StyledLink>

              <StyledLink
                to={leaderboardToday}
                className={url.startsWith(leaderboardToday) ? "active" : ""}
              >
                <img
                  src={
                    url.startsWith(leaderboardToday)
                      ? "/images/icon/ico-trophy-active.svg"
                      : "/images/icon/ico-trophy.svg"
                  }
                  alt={t("common:topToday")}
                />
                <span className="depts01 px-16-r">{t("common:topToday")}</span>
              </StyledLink>

              <StyledLink
                to={leaderboardWeek}
                className={url.startsWith(leaderboardWeek) ? "active" : ""}
              >
                <img
                  src={
                    url.startsWith(leaderboardWeek)
                      ? "/images/icon/ico-trophy-active.svg"
                      : "/images/icon/ico-trophy.svg"
                  }
                  alt={t("common:topWeek")}
                />
                <span className="depts01 px-16-r">{t("common:topWeek")}</span>
              </StyledLink>
            </AsideLink>
          </MainLinkContainer>
        </MainLinkWrapper>

        <LogoutContainer>
          <MainLinkContainer style={{ borderBottom: "none" }}>
            <AsideLink style={{ paddingTop: "8px", paddingBottom: "8px" }}>
              <StyledLink to={myPageUrl} className={url.startsWith(myPageUrl) ? "active" : ""}>
                <img
                  src={
                    url.startsWith(myPageUrl) ? "/images/icon/cbs-fill.svg" : "/images/icon/cbs.svg"
                  }
                  alt={t("common:myPage")}
                />
                <span className="depts01 px-16-b">{t("common:myPage")}</span>
              </StyledLink>
            </AsideLink>
            <AsideLink style={{ paddingTop: "8px", paddingBottom: "8px" }}>
              <div onClick={() => logOut()}>
                <img src="/images/icon/logout.svg" alt={t("common:logout")} />
                <span className="depts01 px-16-b">{t("common:logout")}</span>
              </div>
            </AsideLink>
          </MainLinkContainer>
        </LogoutContainer>
      </LeftSide>
    </>
  );
}

const AsideLogo = styled.div`
  border-radius: 9px;
  padding: 28px 36px;
  width: 100%;

  & > div {
    width: 100%;
    height: 72px;
    border-radius: 9px;
    color: #bdbdbd;
    font-size: 1rem;
    letter-spacing: -2%;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;

    & > a {
      text-decoration: none;
      color: inherit;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const MainLinkWrapper = styled.div`
  height: calc(100% - 150px);
  overflow: auto;
`;

const MainLinkContainer = styled.div`
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  & > h2 {
    padding-bottom: 25px;
  }
`;

const StyledLink = styled(Link)``;

const AsideLink = styled.div`
  & > ${StyledLink} {
    &:not(&:nth-of-type(1)) {
      margin-top: 13px;
    }
  }
  & span.depts01 {
    font-size: 1rem;
    letter-spacing: -2%;
    color: inherit;
  }

  & img {
    margin-right: 12px;
  }

  & > a,
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #4f4f4f;
    text-decoration: none;

    &.active {
      color: #0047ab;
    }
  }
`;

const LogoutContainer = styled.div`
  padding-bottom: 63px;
`;
