import React, { useEffect } from "react";
import styled from "styled-components";

import { USERTYPE_1_TERMS, USERTYPE_2_TERMS } from "../../constants";
import Checkbox from "components/Checkbox";
import Button from "./Button";
import { useTranslation } from "react-i18next";

export default function Terms({ inputs, setInputs, handleNextStep, wrapperRef }) {
  const { t } = useTranslation(["join", "common"]);

  useEffect(() => {
    wrapperRef.current && wrapperRef.current.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <h2>
        {t("join:gen")}&nbsp;{t("join:termsAndConditions")}
      </h2>
      <p>
        {t("join:agreeToP1")}
        <br />
        {t("join:agreeToP2")}
      </p>
      <DivideLine />
      <TermsContainer>
        <h2>{t("join:terms1")}</h2>
        <TermsDiv>
          <textarea readOnly value={USERTYPE_1_TERMS}></textarea>
        </TermsDiv>
        <CheckboxContainer>
          <Checkbox
            checked={inputs.termsAccepted}
            callback={() => {
              setInputs({ ...inputs, termsAccepted: !inputs.termsAccepted });
            }}
          >
            {t("join:agreeToAbove")}
          </Checkbox>
        </CheckboxContainer>
        <>
          <h2>{t("join:consentToCollect")}</h2>
          <TermsDiv>
            <textarea readOnly value={USERTYPE_2_TERMS}></textarea>
          </TermsDiv>
          <CheckboxContainer>
            <Checkbox
              checked={inputs.privacyAccepted}
              callback={() => {
                setInputs({ ...inputs, privacyAccepted: !inputs.privacyAccepted });
              }}
            >
              {t("join:agreeToAbove")}
            </Checkbox>
          </CheckboxContainer>
        </>

        <ButtonContainer>
          <Button
            disabled={!inputs.termsAccepted || !inputs.privacyAccepted}
            callback={handleNextStep}
          >
            {t("common:next")}
          </Button>
        </ButtonContainer>
      </TermsContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 124px;
  width: 100%;

  & > h2 {
    font-weight: 700;
    font-size: 32px;
    color: rgba(79, 79, 79, 1);
  }

  & > p {
    font-weight: 500;
    color: rgba(130, 130, 130, 1);
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 89px;
    text-align: center;
    line-height: 23px;
  }

  @media (max-width: 900px) {
    padding-bottom: initial;
    width: 100%;
    padding-top: calc(64 / 375 * 100vw);
    /* padding-left: calc(28 / 375 * 100vw);
    padding-right: calc(28 / 375 * 100vw); */

    & > h2 {
      font-size: calc(24 / 375 * 100vw);
      width: 100%;
      line-height: calc(34 / 375 * 100vw);
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }

    & > p {
      width: 100%;
      font-weight: 500;
      color: rgba(130, 130, 130, 1);
      font-size: calc(12 / 375 * 100vw);
      padding-top: calc(8 / 375 * 100vw);
      padding-bottom: calc(44 / 375 * 100vw);
      text-align: center;
      line-height: calc(17 / 375 * 100vw);
      text-align: left;
      padding-left: calc(36 / 375 * 100vw);
      padding-right: calc(36 / 375 * 100vw);
    }
  }
`;

const TermsContainer = styled.div`
  width: 568px;
  padding-bottom: 24px;
  box-sizing: border-box;
  padding-top: 55px;

  & > h2 {
    color: rgba(79, 79, 79, 1);
    font-size: 1rem;
    letter-spacing: -2%;
    font-weight: 700;
    margin: 0 auto;
    padding-bottom: 24px;

    &:nth-of-type(2) {
      margin-top: 80px;
      @media (max-width: 900px) {
        margin-top: calc(61 / 375 * 100vw);
      }
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    border: none;
    padding-left: calc(20 / 375 * 100vw);
    padding-right: calc(20 / 375 * 100vw);
    padding-top: initial;

    & > h2 {
      font-size: calc(14 / 375 * 100vw);
      padding-bottom: calc(12 / 375 * 100vw);
      padding-left: calc(16 / 375 * 100vw);
    }
  }
`;

const TermsDiv = styled.div`
  width: 568px;
  text-align: center;
  margin: 0 auto;
  height: 320px;
  & > textarea {
    background: rgba(186, 186, 186, 0.08);
    background-blend-mode: darken;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    padding: 36px;
    box-sizing: border-box;
    outline: none;
    color: rgba(130, 130, 130, 1);
    line-height: 17px;
    letter-spacing: -2%;
    font-size: 12px;
    /* scrollbar-width: none; */
    &::-webkit-scrollbar {
      /* display: none; */
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    height: calc(216 / 375 * 100vw);
  }
`;

const CheckboxContainer = styled.div`
  width: 568px;
  text-align: center;
  margin: 0 auto;
  padding-top: 14px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: calc(21 / 375 * 100vw);
  }
`;

const ButtonContainer = styled.div`
  width: 568px;
  text-align: center;
  margin: 0 auto;
  padding-top: 67px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const DivideLine = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;
