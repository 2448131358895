import styled from "styled-components";

const Device = styled.div`
  display: inline-block;
  cursor: pointer;

  & > div {
    display: flex;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.active ? "#0047AB" : "#F2F2F2")};
    color: ${(props) => (props.active ? "#FFFFFF" : "#828282")};
    font-size: 1rem;
    letter-spacing: -2%;
    font-weight: bold;
    border-radius: 3px;
  }

  margin-right: 8px;
  margin-bottom: 8px;
`;

export default Device;
