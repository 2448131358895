import React from "react";
import styled from "styled-components";
import { formatDate, isAfterOrEqual } from "utils/formatter";

export default ({ name, label, inputs, setInputs }) => {
  const date = inputs ? formatDate(inputs[name]) : "Select date";

  return (
    <Wrapper>
      <label className="px-16-b">
        {label}
        <Container>
          <div className="px-16-r">{date}</div>
          <img src="/images/icon/calendar-book.png" alt="" />
          <input
            name={name}
            placeholder="Select date"
            type="date"
            onFocus={(e) => {
              try {
                e.target.showPicker();
              } catch (e) {
                console.log(e);
              }
            }}
            onChange={(e) => {
              const name = e.target.name;
              const date = e.target.value;
              const start = inputs.startDate;
              const end = inputs.endDate;

              if (name === "startDate") {
                if (isAfterOrEqual(date, end)) {
                  setInputs({
                    ...inputs,
                    startDate: date,
                    endDate: date,
                  });
                } else {
                  setInputs({
                    ...inputs,
                    startDate: date,
                  });
                }
              }

              if (name === "endDate") {
                if (isAfterOrEqual(start, date)) {
                  setInputs({
                    ...inputs,
                    startDate: date,
                    endDate: date,
                  });
                } else {
                  setInputs({
                    ...inputs,
                    endDate: date,
                  });
                }
              }
            }}
          />
        </Container>
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  & > label {
    display: block;
  }
`;

const Container = styled.div`
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  height: 56px;
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  background: #ffffff;

  & > div {
    color: #4f4f4f;
    background: #ffffff;
  }

  & > input {
    position: absolute;
    width: 0px;
    height: 0px;
    color: transparent;
    background: transparent;
    border: none;
  }
`;
