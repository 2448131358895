import { call, put, takeLatest } from "@redux-saga/core/effects";
import * as types from "reducers/find";

import { accounts } from "services";

const log = console.log.bind(console);

export function* findUsername(payload) {
  log("====> SAGA findId : ", payload);
  const { data, callback } = payload;

  try {
    const res = yield call(accounts.findUsername, { email: data.email });

    log("====> SAGA findId : ", res);
    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          success: true,
          username: res.data.username,
        },
      });
    }
    if (callback) callback(res);
  } catch (e) {
    log("====> SAGA findId : ", e);
    if (callback) callback(e);
  }
}

export function* sendEmail(payload) {
  log("====> SAGA sendEmail : ", payload);
  const { data, callback } = payload;

  try {
    const res = yield call(accounts.sendEmail, data);

    log("====> SAGA sendEmail : ", res);
    if (res.status === 200) {
      yield put({
        type: types.DEFAULT_ASSIGN,
        data: {
          success: true,
          email: res.data.email,
        },
      });
    }
    if (callback) callback(res);
  } catch (e) {
    log("====> SAGA sendEmail : ", e);
    if (callback) callback(e);
  }
}

export function* changePassword(payload) {
  log("====> SAGA changePassword : ", payload);
  const { data, callback } = payload;

  try {
    const res = yield call(accounts.changePassword, data);

    log("====> SAGA changePassword : ", res);

    if (callback) callback(res.status === 200);
  } catch (e) {
    log("====> SAGA changePassword : ", e);
    if (callback) callback(false);
  }
}

export default function* watch() {
  yield takeLatest(types.FIND_USERNAME, findUsername);
  yield takeLatest(types.SEND_EMAIL, sendEmail);
  yield takeLatest(types.CHANGE_PASSWORD, changePassword);
}
