import moment from "moment";

const myInfoInitialState = {
  pk: "",
  agency_name: "",
  created_at: "",
  username: "",
  email: "",
  last_name: "",
  first_name: "",
  phone_number: "",
};

const initialState = {
  reservationList: [],
  deviceList: [],
  sessionList: {},
  evaluationList: [],
  sessionPage: {
    device: false,
    month: parseInt(moment().format("M")),
    year: parseInt(moment().format("YYYY")),
  },
  sessionJoin: {
    type: "", // reservation, alert
    show: false,
    point: {
      x: 0,
      y: 0,
    },
    info: {
      pk: "",
      device_name: "",
      date: "",
      start_time: "",
      end_time: "",
      is_available: false,
    },
  },
  leaderboard: [],
  info: myInfoInitialState,
};

export const DEFAULT_ASSIGN = "trainee/DEFAULT_ASSIGN";

export const MY_RESERVATION_LIST = "trainee/MY_RESERVATION_LIST";
export const MY_RESERVATION_WITHDRAWN = "trainee/MY_RESERVATION_WITHDRAWN";

export const MY_DEVICE_LIST = "trainee/MY_DEVICE_LIST";

export const MY_AGENCY_RESERVATION_LIST = "trainee/MY_AGENCY_RESERVATION_LIST";
export const MY_AGENCY_RESERVATION_JOIN = "trainee/MY_AGENCY_RESERVATION_JOIN";

export const MY_EVALUATION_LIST = "trainee/MY_EVALUATION_LIST";
export const MY_EVALUATION_DETAIL = "trainee/MY_EVALUATION_DETAIL";
export const MY_EVALUATION_EXCEL = "trainee/MY_EVALUATION_EXCEL";

export const MY_INFO = "trainee/MY_INFO";
export const MY_INFO_DELETE = "trainee/MY_INFO_DELETE";

export const MY_LEADERBOARD = "trainee/MY_LEADERBOARD";

// None saga
export const SET_SESSION_MONTH = "user/SET_SESSION_MONTH";
export const SET_SESSION_YEAR = "user/SET_SESSION_YEAR";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ASSIGN:
      return {
        ...state,
        ...action.data,
      };
    case SET_SESSION_MONTH:
      return {
        ...state,
        sessionPage: {
          ...state.sessionPage,
          month: action.data,
        },
      };
    case SET_SESSION_YEAR:
      return {
        ...state,
        sessionPage: {
          ...state.sessionPage,
          year: action.data,
        },
      };
    default:
      return state;
  }
}
