import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HomePage() {
  const isAgency = useSelector((state) => state.common.isAgency);
  const history = useHistory();

  useEffect(() => {
    const redirectUrl = isAgency ? "/admin/evaluations" : "/my/evaluations";
    history.push(redirectUrl);
  }, [history]);

  return <div>Loading...</div>;
}

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  width: 650px;
  height: 100%;
  margin: 0 auto;

  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 250px;
    font-size: 40px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.12);

    &:hover {
      opacity: 0.5;
    }
  }
`;
