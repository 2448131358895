import { combineReducers } from "redux";

import join from "./join";
import common from "./common";
import login from "./login";
import admin from "./admin";
import find from "./find";
import trainee from "./trainee";

const rootReducer = combineReducers({
  join,
  login,
  common,
  admin,
  find,
  trainee,
});

export default rootReducer;
