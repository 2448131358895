import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function Switch({ onChange, isAgency }) {
  const { t } = useTranslation(["login"]);
  return (
    <Wrapper content={t("login:user")} checkedContent={t("login:admin")} isAgency={isAgency}>
      <input id="tet-switch" type="checkbox" onChange={onChange} />
      <label htmlFor="tet-switch"></label>
      <div>
        <span>{t("login:user")}</span>
        <span>{t("login:admin")}</span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 84px;
  height: 28px;
  position: relative;
  background-color: ${(props) => (props.isAgency ? "var(--mono-2)" : "var(--a-purple-3)")};
  border-radius: 50px;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 100%;
    font-family: Noto Sans KR;
    font-size: 9px;
    letter-spacing: -2%;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;

    & > span {
      display: inline-block;
      width: 32px;
      text-align: center;
    }
  }
  & > input {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }
  & > label {
    z-index: 2;
    width: 84px;
    height: 28px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    &::after {
      content: "${(props) => props.content}";
      position: absolute;
      top: 2px;
      left: 2px;
      background: #ffffff;
      width: 44px;
      height: 24px;
      border-radius: 50px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      color: ${(props) => (props.isAgency ? "var(--mono-2)" : "var(--a-purple-3)")};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
      font-family: Noto Sans KR;
    }
  }

  input:checked + label {
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    content: "${(props) => props.checkedContent}";
  }
`;
